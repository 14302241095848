import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import {
  makeStyles,
  MenuItem,
  TextField,
  CircularProgress,
  Slide,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  TablePagination,
  Chip,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import RateReview from "@material-ui/icons/RateReview";
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
  currentTracking,
} from "views/LDocs/Functions/Functions";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import Pending from "assets/img/statuses/Pending.png";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
import NoStatus from "assets/img/statuses/NoStatus.png";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import jwt from "jsonwebtoken";
import Iframe from "react-iframe";
import {
  validateInvoice,
  formatDateTime,
} from "views/LDocs/Functions/Functions";
import FileAdvanceView from "../Invoices/AdvanceView/FileAdvanceView";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { sendNotification, getNotification } from "actions";
import Validator from "../../Components/Timeline";
import { useSelector, useDispatch } from "react-redux";
import { CallReceived, DoneAll, EditOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { setIsTokenExpired } from "actions/index.js";
import { _IsAr } from "../Functions/Functions";
import CreateInvoiceOption from "../Invoices/CreateInvoice/CreateInvoice";
import { MenuProvider } from "react-contexify";
import InfoIcon from "@material-ui/icons/Info";
import { useLocation, useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import { Autocomplete } from "@material-ui/lab";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ApprovalRequested() {
  const location = useLocation();
  const history = useHistory();

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const isAr = useSelector((state) => state.userReducer.isAr);
  const userDetails = jwt.decode(Token);
  const classes = useStyles();
  const [isApprovingFile, setIsApprovingFile] = React.useState(false);
  const [pdfModalData, setPdfModalData] = React.useState(false);
  const [reviewModal, setApproverModal] = React.useState(false);
  const [animateTable, setAnimateTable] = React.useState(true);
  const [animateAdvanceView, setAnimateAdvanceView] = React.useState(true);
  const [animatePdf, setAnimatePdf] = React.useState(false);
  const [isAdvanceView, setIsAdvanceView] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [pdfUrl, setPdfUrl] = React.useState(false);
  const [data, setData] = React.useState();
  const [FileData, setFileData] = React.useState();
  const [InvoiceData, setInvoiceData] = React.useState();
  const [validation, setValidation] = React.useState({});
  const [validateModal, setValidateModal] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [editInvoice, setEditInvoiceModel] = React.useState(false);
  const [required, setRequired] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showPage, setShowPage] = React.useState(0);
  const [showRowsPerPage, setShowRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [filters, setFilters] = React.useState({
    vendor: "",
    requester: "",
    po: "",
    invoiceNo: "",
    sortBy: "descending",
    external: false,
  });
  const [openTrackingModal, setOpenTrackingModal] = React.useState(false);
  const [trackingLoading, setTrackingLoading] = React.useState(false);
  const [initialReview, setInitialReview] = React.useState("");
  const [review, setReview] = React.useState("");
  const [approve, setApprove] = React.useState("");
  const [approveandexported, setApproveAndExported] = React.useState("");
  const [canceled, setCanceled] = React.useState("");
  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);
  const [exportLoading, setExportLoading] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    // Function to parse query parameters
    const getQueryParams = (search) => {
      return new URLSearchParams(search);
    };
    // Get the query parameters
    const queryParams = getQueryParams(location.search);
    // Get the invId parameter
    const invId = queryParams.get("invId");
    if (invId) {
      // Remove the invId parameter
      queryParams.delete("invId");
      // Create a new search string without invId
      const newSearch = queryParams.toString();
      const newLocation = {
        ...location,
        search: newSearch ? `?${newSearch}` : "",
      };
      // Update the URL
      history.replace(newLocation);
      // You can now use invId for your purposes
      setFilters((filters) => ({
        ...filters,
        invoiceNo: invId.toLowerCase(),
        external: true,
      }));
    } else {
      getRequests();
    }
  }, [show, isAr, page, rowsPerPage, showPage, showRowsPerPage, filters]);

  React.useEffect(() => {
    _getSuppliers();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowChangePage = (event, newPage) => {
    setShowPage(newPage);
  };

  const handleShowChangeRowsPerPage = (event) => {
    setShowRowsPerPage(parseInt(event.target.value, 10));
    setShowPage(0);
  };

  const getInvoiceDetails = async (row, i) => {
    await axios({
      method: "post", //you can set what request you want to be
      url: _IsAr()
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ar`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getSingleInvoiceByVersion/ap`,
      data: {
        invoiceId: row.invoiceId,
        version: row.version,
        vendorId: _IsAr() ? null : row.vendorId,
        clientId: _IsAr() ? row.clientId : null,
      },
      headers: {
        cooljwt: Token,
      },
    }).then((response) => {
      if (response.data !== null || undefined) {
        const invoice = response.data;
        setIsAdvanceView(false);
        setInvoiceData(invoice);
        setAnimateTable(false);
        if (i == 0) {
          setIsAdvanceView(true);
          setAnimateAdvanceView(true);
        } else {
          setEditInvoiceModel(true);
          setAnimateAdvanceView(true);
        }
      }
    });
  };

  const viewFile = (row) => {
    setIsViewing(false);
    setPdfModalData(row);
    setPdfUrl(
      `${process.env.REACT_APP_LDOCS_API_URL}/${row.invoicePath}/${row.invoiceId}.pdf`
    );
    setAnimateTable(false);
    setIsViewing(true);
    setAnimatePdf(true);
  };

  const reviewFile = (row) => {
    setFileData(row);
    // validateInvoice(row, Token, _IsAr()).then((res) => {
    // setValidation(res);
    setApproverModal(true);
    //  });
  };

  const ValidateFile = async (row) => {
    setInvoiceData(row);
    validateInvoice(row, Token, _IsAr()).then((res) => {
      setAnimateTable(false);
      setValidation(res);
      setValidateModal(true);
    });
  };

  const _trackingStatus = (trackingStatus) => {
    let userDetail = jwt.decode(localStorage.getItem("cooljwt"));
    let isVendor = userDetail?.isVendor;
    if (isVendor) {
      return;
    }
    if (trackingStatus) {
      setOpenTrackingModal(true);
      // setTrackingLoading(true)
      setInitialReview(trackingStatus?.initialReview);
      setReview(trackingStatus?.underReview);
      setApprove(trackingStatus?.underApprove);
      setApproveAndExported(trackingStatus?.paymentInProcess);
      setCanceled(trackingStatus?.paid);
    } else {
      setOpenTrackingModal(false);
      setTrackingLoading(false);
      msgAlert("Tracking Status not Found");
    }
  };

  const getRequests = (isReviewCallBack) => {
    if (isReviewCallBack) {
      setFilters((filters) => ({
        ...filters,
        external: false,
        invoiceNo: "",
      }));
    }
    setIsLoading(true);
    debugger
    const params = {
      page: show ? showPage : page,
      items: show ? showRowsPerPage : rowsPerPage,
      sortBy: filters.sortBy,
      vendor: filters.vendor?.level1?.Supplier || "",
      requester: filters.requester,
      po: filters.po,
      invoiceNo: filters.invoiceNo,
    };

    const queryString = new URLSearchParams(params).toString();
    axios({
      method: "get",
      url: show
        ? `${process.env.REACT_APP_LDOCS_API_URL}/invoiceApprove/approveMyPending/ap?${queryString}`
        : `${process.env.REACT_APP_LDOCS_API_URL}/invoiceApprove/myApproves/ap?${queryString}`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        // for open action modal
        if (!isReviewCallBack && filters.external) {
          let filteredAction = response?.data?.results.find(
            (x) =>
              x.originalInvoiceId?.toLowerCase() ===
              filters.invoiceNo?.toLowerCase()
          );
          if (filteredAction) {
            reviewFile(filteredAction);
          }
        }
        setCount(response.data.totalNumOfItems || 0);
        setData(
          response.data.results.map((prop, key) => {
            var currentStatus = currentTracking(prop?.trackingStatus);
            let isCorrectionRequiredInWorkflow =
              prop?.workFlowStatus == "correctionRequired";
            return {
              id: prop._id,
              fileName: prop.originalInvoiceId,
              requestedBy: prop.requestedBy,
              requestedBy: prop.requestedBy,
              vendor: prop?.vendorName,
              po: prop?.po,
              status: (
                <MenuProvider
                  style={{ paddingTop: 40, paddingBottom: 40 }}
                  data={prop}
                  id="menu_id"
                >
                  {/* if Inoice is Draft */}

                  {prop?.trackingStatus?.received?.status == "inProgress" ? (
                    <Tooltip title="INCOMPLETE">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="INCOMPLETE"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "orange 1px solid",
                          color: "orange",
                        }}
                      />
                    </Tooltip>
                  ) : // if Inoice is Not Draft
                  prop?.trackingStatus?.paid?.status == "partial" ? (
                    <Tooltip title="PARTIALLY PAID">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="PARTIALLY PAID"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "lightgreen 1px solid",
                          color: "lightgreen",
                        }}
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.paid?.status == "completed" ? (
                    <Tooltip title="FULLY PAID">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="FULLY PAID"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "green 1px solid",
                          color: "green",
                        }}
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.paid?.status == "Canceled" ? (
                    <Tooltip title="CANCELED">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="CANCELED"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="secondary"
                      />
                    </Tooltip>
                  ) : currentStatus.status == "readyToPay" ? (
                    <Tooltip title="READY TO PAY">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="READY TO PAY"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "orange 1px solid",
                          color: "orange",
                        }}
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.paymentInProcess?.status ==
                    "completed" ? (
                    <Tooltip title="APPROVED AND EXPORTED">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="APPROVED AND EXPORTED"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "green 1px solid",
                          color: "green",
                        }}
                      />
                    </Tooltip>
                  ) : prop?.workFlowStatus == "correctionRequired" &&
                    prop?.markedAs !== "rejected" ? (
                    <Tooltip title="Correction Required">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="CORRECTION REQUIRED"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "orange 1px solid",
                          color: "orange",
                        }}
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.underApprove?.status ==
                    "inProgress" ? (
                    <Tooltip title="PENDING">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="PENDING"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="primary"
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.underApprove?.status ==
                      "completed" &&
                    prop?.trackingStatus?.paymentInProcess?.status !==
                      "completed" ? (
                    <Tooltip title="APPROVED">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="APPROVED"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        style={{
                          border: "green 1px solid",
                          color: "green",
                        }}
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.underReview?.status ==
                    "inProgress" ? (
                    <Tooltip title="PENDING">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="PENDING"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="primary"
                      />
                    </Tooltip>
                  ) : prop?.trackingStatus?.initialReview?.status ==
                    "inProgress" ? (
                    <Tooltip title="PENDING">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="PENDING"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="primary"
                      />
                    </Tooltip>
                  ) : currentStatus?.status == "rejected" ? (
                    <Tooltip title="REJECTED">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="REJECTED"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="secondary"
                      />
                    </Tooltip>
                  ) : currentStatus?.status == "Canceled" ? (
                    <Tooltip title="Canceled">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="Canceled"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="secondary"
                      />
                    </Tooltip>
                  ) : (currentStatus?.status == "correctionRequired" &&
                      currentStatus?.val == 1) ||
                    isCorrectionRequiredInWorkflow ? (
                    <Tooltip title="SENT FOR CORRECTION">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="SENT FOR CORRECTION"
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        clickable
                        style={{
                          border: "orange 1px solid",
                          color: "orange",
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="PENDING">
                      <Chip
                        variant="outlined"
                        size="small"
                        // avatar={<Avatar>M</Avatar>}
                        label="PENDING"
                        clickable
                        onClick={() => _trackingStatus(prop?.trackingStatus)}
                        color="primary"
                      />
                    </Tooltip>
                  )
                  /* ) : prop?.markedAs == "unread" ? (
                        <Chip
                          style={{ background: "#deb725", color: "#fff" }}
                          label="Pending"
                        />
                      ) : prop?.markedAs == "read" ? (
                        <Chip label="Received" color="primary" />
                      ) : prop?.markedAs == "rejected" ? (
                        <Chip color="secondary" label="Rejected" />
                      ) : (
                        ""
                      )} */
                  }
                </MenuProvider>
              ),
              requestTime: formatDateTime(prop.requestedTime),
              actions: (
                <div className="actions-right">
                  <Tooltip title="360&#176; View" aria-label="viewfile">
                    <Button
                      justIcon
                      round
                      simple
                      icon={ViewModuleIcon}
                      onClick={() => getInvoiceDetails(prop, 0)}
                      className="View"
                      color="info"
                    >
                      <ViewModuleIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Validate File" aria-label="validatefile">
                    <Button
                      justIcon
                      round
                      simple
                      icon={VerifiedUserIcon}
                      onClick={() => ValidateFile(prop)}
                      color="info"
                    >
                      <VerifiedUserIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="View File" aria-label="viewfile">
                    <Button
                      justIcon
                      round
                      simple
                      icon={VisibilityIcon}
                      onClick={() => viewFile(prop)}
                      color="warning"
                      className="View"
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Edit Invoice" aria-label="editInvoice">
                    <Button
                      justIcon
                      round
                      simple
                      color="info"
                      icon={EditOutlined}
                      onClick={() => getInvoiceDetails(prop, 1)}
                      className="View"
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                  {show ? (
                    <Tooltip title="Review File" aria-label="reviewfile">
                      <Button
                        justIcon
                        round
                        simple
                        icon={RateReview}
                        onClick={() => reviewFile(prop)}
                        color="info"
                      >
                        <RateReview />
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              ),
            };
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setIsLoading(false);
      });
  };
  const goBack = () => {
    setPdfUrl();
    setIsViewing(false);
    setValidateModal(false);
    setIsAdvanceView(false);
    setEditInvoiceModel(false);
    getRequests();
    setAnimateTable(true);
    setAnimateAdvanceView(false);
    setAnimatePdf(false);
    setPdfModalData("");
    setInvoiceData("");
  };
  const [formState, setFormState] = React.useState({
    supplierInputValue: "",
    vendors: [],
    selectedVendor: "",
    values: {
      status: "",
      approveComments: "",
    },
    errors: {
      status: "",
      approveComments: "",
    },
  });

  const handleChange = (event) => {
    event.persist();
    if (event.target.name == "status") {
      if (
        event.target.value == "correctionRequired" ||
        event.target.value == "rejected"
      ) {
        setRequired(true);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value,
          },
        }));
      } else {
        setRequired(false);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value,
          },
        }));
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  const handleFilters = (event) => {
    event.persist();
    setFilters((filters) => ({
      ...filters,
      external: false,
      [event.target.name]: event.target.value,
    }));
  };

  const _getSuppliers = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setFormState((formState) => ({
          ...formState,
          vendors: res?.data?.result,
        }));
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };

  const approveFileNow = () => {
    setIsApprovingFile(true);
    let status;
    let approveComments;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;
    if (!Check(formState.values.status)) {
      status = "success";
    } else {
      status = "error";
      error = true;
    }
    if (
      required
        ? !Check(formState.values.approveComments)
        : (approveComments = "success")
    ) {
      approveComments = "success";
    } else {
      approveComments = "error";
      error = true;
    }
    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        status: status,
        approveComments: approveComments,
      },
    }));
    if (error) {
      setIsApprovingFile(false);
      return false;
    } else {
      let data = {
        ...FileData,
        approveComments: formState.values.approveComments,
        status: formState.values.status,
        updateTime: new Date()
          .toLocaleString()
          .replace(/t/, " ")
          .replace(/\..+/, ""),
      };
      axios({
        method: "post",
        url: isAr
          ? `${process.env.REACT_APP_LDOCS_API_URL}/invoiceApprove/approveUpdateAR`
          : `${process.env.REACT_APP_LDOCS_API_URL}/invoiceApprove/approveUpdate`,
        data: data,
        headers: {
          cooljwt: Token,
        },
      })
        .then((response) => {
          setCount(response.data.totalNumOfItems || 0);
          setApproverModal(false);
          if (formState.values.status == "approved") {
            successAlert("Invoice Approved Successfully!");
          }
          if (formState.values.status == "correctionRequired") {
            successAlert("Invoice sent for correction required");
          }
          if (formState.values.status == "rejected") {
            successAlert("Invoice Rejected");
          }
          dispatch(getNotification());
          getRequests(true);
          setIsApprovingFile(false);
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              status: "",
              approveComments: "",
            },
          }));
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          console.log(
            typeof error.response != "undefined"
              ? error.response.data
              : error.message
          );
          let err = error?.response?.data;
          errorAlert(err || "There is some issue ..");
          setIsApprovingFile(false);
        });
    }
  };

  const approveFileClose = () => {
    setApproverModal(false);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        status: "",
        approveComments: "",
      },
      errors: {
        ...formState.errors,
        status: "",
        approveComments: "",
      },
    }));
  };

  const getSteps = (step) => {
    step = {
      ...step,
      forward: step?.forward
        ?.map((step) => ({ ...step, showReason: true }))
        .concat(
          step?.delegate?.map((step) => ({ ...step, showReason: false }))
        ),
    };
    let filteredStep = step?.forward.filter((x) => x !== undefined);
    return {
      ...step,
      forward: filteredStep?.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    };
  };

  const showFwdNotes = (step) => {
    if (step === "initialReview") {
      let step = getSteps(initialReview);
      setShowFwdData(step);
    } else if (step === "reviewStep") {
      let step = getSteps(review);
      setShowFwdData(step);
    } else {
      let step = getSteps(approve);
      setShowFwdData(step);
    }
    setShowFwdDetails(true);
  };

  const closeTrackingModal = () => {
    setOpenTrackingModal(false);
    setInitialReview("");
    setReview("");
    setApprove("");
    setApproveAndExported("");
    setCanceled("");
  };

  const exportExcel = async () => {
    try {
      setExportLoading(true);
      const params = {
        sortBy: filters.sortBy,
        vendor: filters.vendor,
        requester: filters.requester,
        po: filters.po,
        invoiceNo: filters.invoiceNo,
      };

      const queryString = new URLSearchParams(params).toString();
      const res = await axios({
        method: "get",
        url: show
          ? `${process.env.REACT_APP_LDOCS_API_URL}/report/exportInvoices/approveMyPending/ap?${queryString}`
          : `${process.env.REACT_APP_LDOCS_API_URL}/report/exportInvoices/myApproves/ap?${queryString}`,
        headers: {
          cooljwt: Token,
        },
      });
      setExportLoading(false);
      saveAs(process.env.REACT_APP_LDOCS_API_URL + "/" + res?.data?.path);
    } catch (error) {
      setExportLoading(false);
      errorAlert(error);
    }
  };

  return (
    <div>
      {reviewModal ? (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"sm"}
              open={reviewModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setApproverModal(false)}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="info" icon>
                        <CardIcon color="info">
                          <h4 className={classes.cardTitle}>
                            Approve Invoice:&nbsp;
                            {FileData.originalInvoiceId}
                          </h4>
                        </CardIcon>
                      </CardHeader>
                      <CardBody>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <TextField
                            className={classes.textField}
                            error={formState.errors.status === "error"}
                            fullWidth={true}
                            helperText={
                              formState.errors.status === "error"
                                ? "Status is required"
                                : null
                            }
                            label="Status"
                            name="status"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            select
                            value={formState.values.status || ""}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              Choose Status
                            </MenuItem>
                            <MenuItem value="approved">
                              MARK AS APPROVED&nbsp;&nbsp;
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img src={Success} alt={"MARK AS REVIEWED"} />
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem value="correctionRequired">
                              CORRECTION REQUIRED&nbsp;&nbsp;
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img src={Pending} alt={"MARK AS REJECT"} />
                                </div>
                              </div>
                            </MenuItem>
                            <MenuItem value="rejected">
                              MARK AS REJECTED&nbsp;&nbsp;
                              <div className="fileinput text-center">
                                <div className="thumbnail img-circle3">
                                  <img src={Rejected} alt={"MARK AS REJECT"} />
                                </div>
                              </div>
                            </MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {required ? (
                            <TextField
                              className={classes.textField}
                              error={
                                formState.errors.approveComments === "error"
                              }
                              fullWidth={true}
                              helperText={
                                formState.errors.approveComments === "error"
                                  ? "Comments is required"
                                  : null
                              }
                              label="Approval Comments *"
                              name="approveComments"
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              value={formState.values.approveComments || ""}
                            ></TextField>
                          ) : (
                            <TextField
                              className={classes.textField}
                              // error={formState.errors.approveComments === "error"}
                              fullWidth={true}
                              // helperText={
                              //   formState.errors.approveComments === "error"
                              //     ? "Comments is required"
                              //     : null
                              // }
                              label="Approval Comments"
                              name="approveComments"
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              value={formState.values.approveComments || ""}
                            ></TextField>
                          )}
                        </GridItem>
                        {/* {validation ? (
                          validation.Validate.isSame == false ? (
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <Alert severity="warning">
                                Invoice has been Modified — check it out!
                              </Alert>
                            </GridItem>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )} */}
                        <span style={{ float: "right" }}>
                          <Button
                            color="info"
                            className={classes.registerButton}
                            round
                            type="button"
                            onClick={approveFileNow}
                          >
                            Approve
                          </Button>
                          {isApprovingFile ? (
                            <CircularProgress disableShrink />
                          ) : (
                            ""
                          )}
                          <Button
                            color="danger"
                            className={classes.registerButton}
                            onClick={() => approveFileClose()}
                            round
                          >
                            Close
                          </Button>
                        </span>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ""
      )}
      {editInvoice ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateAdvanceView}
        >
          <CreateInvoiceOption
            edit={editInvoice}
            loadFiles={() => {}}
            closeModal={() => goBack()}
            editHandler={1}
            fileData={InvoiceData}
          />
        </Animated>
      ) : (
        ""
      )}
      {isViewing ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animatePdf}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Invoice: {pdfModalData.originalInvoiceId}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <Iframe
                    url={pdfUrl}
                    width="100%"
                    id="myId"
                    allow="print 'none'; download 'none'"
                    className="myClassname"
                    height={window.screen.height}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
      {validateModal ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={validateModal}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      Invoice : {InvoiceData.originalInvoiceId}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <Validator validation={validation} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
      {isAdvanceView ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateAdvanceView}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      360&#176; View: {InvoiceData.originalInvoiceId}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => goBack()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <FileAdvanceView fileData={InvoiceData} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}

      {openTrackingModal ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"lg"}
          open={openTrackingModal}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenTrackingModal(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>Tracking Status</h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableID}>
                            Review / Approve
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned To
                          </TableCell>
                          <TableCell className={classes.TableID}>
                            Assigned Date
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Status
                          </TableCell>
                          <TableCell className={classes.TableCell}>
                            Action Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Initial Review
                            <br />
                            (Requester)
                            {initialReview?.isForward || review?.isDelegate ? (
                              <IconButton
                                onClick={() => showFwdNotes("initialReview")}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {initialReview?.reviewedBy || "-"}
                            <br />
                            {initialReview?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {initialReview?.assignDate
                              ? formatDateTime(initialReview?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {initialReview?.status == "completed"
                              ? initialReview?.status.toUpperCase()
                              : initialReview?.status == "correctionRequired"
                              ? initialReview?.status.toUpperCase()
                              : initialReview?.status == "rejected"
                              ? initialReview?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {initialReview?.status !== "pending" &&
                            initialReview?.date
                              ? formatDateTime(initialReview?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Review
                            <br />
                            (Accounting Specialist)
                            {review?.isForward || review?.isDelegate ? (
                              <IconButton
                                onClick={() => showFwdNotes("reviewStep")}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {review?.reviewedBy || "-"}
                            <br />
                            {review?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {review?.assignDate
                              ? formatDateTime(review?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {review?.status == "completed"
                              ? review?.status.toUpperCase()
                              : review?.status == "correctionRequired"
                              ? review?.status.toUpperCase()
                              : review?.status == "rejected"
                              ? review?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {review?.status !== "inProgress" && review?.date
                              ? formatDateTime(review?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve
                            <br />
                            (Accounting Manager)
                            {approve?.isForward || approve?.isDelegate ? (
                              <IconButton
                                onClick={() => showFwdNotes("approveStep")}
                              >
                                <InfoIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {approve?.approvedBy || "-"}
                            <br />
                            {approve?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {approve?.assignDate
                              ? formatDateTime(approve?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {approve?.status == "completed"
                              ? approve?.status.toUpperCase()
                              : approve?.status == "correctionRequired"
                              ? approve?.status.toUpperCase()
                              : approve?.status == "rejected"
                              ? approve?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {approve?.status !== "pending" && approve?.date
                              ? formatDateTime(approve?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Approve and Export
                            <br />
                            (Requester)
                          </TableCell>
                          <TableCell>
                            {approveandexported?.exportedBy || "-"}
                            <br />
                            {approveandexported?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.assignDate
                              ? formatDateTime(approveandexported?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.status == "completed"
                              ? approveandexported?.status.toUpperCase()
                              : "PENDING" || "PENDING"}
                          </TableCell>
                          <TableCell>
                            {approveandexported?.status !== "pending" &&
                            approveandexported?.date
                              ? formatDateTime(approveandexported?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>

                        <TableRow className={classes.TableRow}>
                          <TableCell className={classes.TableCell}>
                            Invoice Status
                            {/* <br />
                            (Requester) */}
                          </TableCell>
                          <TableCell>
                            {canceled?.paidBy || "-"}
                            <br />
                            {canceled?.assignTo || "-"}
                          </TableCell>
                          <TableCell>
                            {canceled?.assignDate
                              ? formatDateTime(canceled?.assignDate)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {canceled?.status == "inProgress"
                              ? "PENDING"
                              : canceled?.status == "completed"
                              ? "COMPLETED"
                              : canceled?.status == "Canceled"
                              ? "CANCELED"
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {canceled?.status !== "pending" && canceled?.date
                              ? formatDateTime(canceled?.date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => closeTrackingModal()}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      {showFwdDetails ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={showFwdDetails}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => setShowFwdDetails(false)}
          aria-labelledby="tag-modal-slide-title"
          aria-describedby="tag-modal-slide-description"
        >
          <DialogContent
            id="tag-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitle}>
                      Forward/Deligate Details
                    </h4>
                  </CardIcon>
                </CardHeader>
                <CardBody>
                  {trackingLoading ? (
                    <CircularProgress />
                  ) : (
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Foward/Deligate User</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ paddingBottom: 5 }}>
                        {showFwdData &&
                          showFwdData?.forward?.map((fwd, index) => (
                            <>
                              <TableRow
                                className={classes.TableRow}
                                key={index}
                              >
                                <TableCell className={classes.TableCell}>
                                  {formatDateTime(fwd?.date)}
                                </TableCell>
                                <TableCell>{fwd?.noteMsg}</TableCell>
                              </TableRow>
                              {fwd?.showReason && (
                                <TableRow
                                  className={classes.TableRow}
                                  key={index}
                                >
                                  <TableCell colSpan={3}>
                                    <b>Reason:</b> {fwd?.reason}
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ))}
                      </TableBody>
                    </Table>
                  )}
                </CardBody>
              </Card>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={() => setShowFwdDetails(false)}
                round
                style={{ float: "right", marginLeft: "auto" }}
              >
                CLOSE
              </Button>
            </GridContainer>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}

      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={animateTable}
      >
        {animateTable ? (
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      {show
                        ? "Invoices Requested For Approval"
                        : "Invoices Approved"}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={exportExcel}
                    disabled={exportLoading}
                  >
                    {!exportLoading ? "Export" : "Loading..."}
                  </Button>
                  {show ? (
                    <Tooltip title="Show Approve Done">
                      <Button
                        color="danger"
                        round
                        size="sm"
                        style={{ float: "right" }}
                        className={classes.marginRight}
                        onClick={() => setShow(!show)}
                      >
                        <DoneAll />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show Requested">
                      <Button
                        color="danger"
                        round
                        size="sm"
                        style={{ float: "right" }}
                        className={classes.marginRight}
                        onClick={() => setShow(!show)}
                      >
                        <CallReceived />
                      </Button>
                    </Tooltip>
                  )}
                  <TextField
                    className={classes.textField}
                    label="Sort Date"
                    name="sortBy"
                    style={{ float: "right", marginRight: "20px" }}
                    onChange={(event) => {
                      handleFilters(event);
                    }}
                    select
                    value={filters.sortBy || ""}
                  >
                    <MenuItem value="ascending">Ascending</MenuItem>
                    <MenuItem value="descending">Descending</MenuItem>
                  </TextField>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem sx={12} md={6} lg={6}>
                      {/* <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label="Search Vendor"
                        name="vendor"
                        onChange={(event) => {
                          handleFilters(event);
                        }}
                        value={filters.vendor || ""}
                      /> */}
                      <Autocomplete
                        id="filter-demo"
                        options={formState.vendors || []}
                        inputValue={formState.supplierInputValue || ""}
                        onInputChange={(event, newInputValue) => {
                          setFormState((formState) => ({
                            ...formState,
                            supplierInputValue: newInputValue,
                          }));
                        }}
                        value={filters.vendor || ""}
                        getOptionLabel={(option) =>
                          option?.level1?.Supplier || ""
                        }
                        onChange={(event, newValue) => {
                          if (newValue?.level1?.SupplierId) {
                            setFilters((filters) => ({
                              ...filters,
                              external: false,
                              vendor: newValue,
                            }));
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Select Supplier"}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </GridItem>
                    <GridItem sx={12} md={6} lg={6}>
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label="Search Requester"
                        name="requester"
                        onChange={(event) => {
                          handleFilters(event);
                        }}
                        value={filters.requester || ""}
                      />
                    </GridItem>
                    <GridItem sx={12} md={6} lg={6}>
                      <br />
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label="Search PO"
                        name="po"
                        onChange={(event) => {
                          handleFilters(event);
                        }}
                        value={filters.po || ""}
                      />
                    </GridItem>
                    <GridItem sx={12} md={6} lg={6}>
                      <br />
                      <TextField
                        className={classes.textField}
                        fullWidth={true}
                        label="Search Invoice#"
                        name="invoiceNo"
                        onChange={(event) => {
                          handleFilters(event);
                        }}
                        value={filters.invoiceNo || ""}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  {isLoading ? (
                    <CircularProgress disableShrink />
                  ) : (
                    <>
                      <ReactTable
                        data={data}
                        sortable={false}
                        columns={[
                          {
                            Header: "Invoice Number",
                            accessor: "fileName",
                          },
                          {
                            Header: "Request Time",
                            accessor: "requestTime",
                          },
                          {
                            Header: "Requested By",
                            accessor: "requestedBy",
                          },
                          {
                            Header: "Vendor",
                            accessor: "vendor",
                          },
                          {
                            Header: "PO#",
                            accessor: "po",
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                          },
                          {
                            Header: "Actions",
                            accessor: "actions",
                          },
                        ]}
                        defaultPageSize={rowsPerPage}
                        showPagination={false}
                        className="-striped -highlight"
                      />
                      {show ? (
                        <TablePagination
                          component="div"
                          count={count}
                          page={showPage}
                          onPageChange={handleShowChangePage}
                          rowsPerPage={showRowsPerPage}
                          onRowsPerPageChange={handleShowChangeRowsPerPage}
                        />
                      ) : (
                        <TablePagination
                          component="div"
                          count={count}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
      </Animated>
    </div>
  );
}
