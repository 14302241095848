import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TablePagination,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";

// @material-ui/core components
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  MenuItem,
  IconButton,
  Modal,
  withStyles,
  Typography,
  Fade,
  Box,
  Backdrop,
  Chip,
} from "@material-ui/core";
import moment from "moment";
import { Route, useParams, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import Pending from "assets/img/statuses/Pending.png";
import Success from "assets/img/statuses/Success.png";
import Rejected from "assets/img/statuses/Rejected.png";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import RateReview from "@material-ui/icons/RateReview";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import ClearIcon from "@material-ui/icons/Clear";
import {
  addZeroes,
  conversionRate,
  successAlert,
  errorAlert,
  msgAlert,
  formatDateTime,
} from "views/LDocs/Functions/Functions";
import {
  ClearAllOutlined,
  Edit,
  EditOutlined,
  PhotoSizeSelectLargeTwoTone,
} from "@material-ui/icons";
import PurchaseOrderList from "actions/PurchaseOrderListAction";
import SyncPo from "views/Components/PurchaseOrderView/Childs/SyncPo";
import { Autocomplete } from "@mui/material";
import PurchaseOrderView from "views/Components/PurchaseOrderView/PurchaseOrderView";
import Filters from "./Filters";
import { htmlAlert, successHtmlAlert } from "../Functions/Functions";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { saveAs } from "file-saver";
import GetAppIcon from "@material-ui/icons/GetApp";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {},
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
  table: {
    minWidth: "100%",
    border: 1,
  },
  TableCell: {
    minWidth: "10%",
  },
  TableRow: {
    cursor: "pointer",
    background: "white",
    border: 1,
    width: "100%",
  },
  TableID: {
    maxWidth: "3%",
  },
  asterisk: {
    color: "red",
  },
};
const StyledTableRow = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    width: "7%",
  },
  body: {
    fontSize: 14,
    border: "1px solid lightgrey",
    width: "7%",
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    width: "7%",
  },
  body: {
    fontSize: 14,
    border: "1px solid lightgrey",
    width: "5%",
  },
}))(TableCell);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ReceiveReceipt = ({ isPendingView, receipts, forwardTask }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [totalNumOfItems, setTotalNumOfItems] = React.useState(0);
  const [PurchaseOrder, setPurchaseOrder] = React.useState([]);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [poLoading, setPoLoading] = React.useState(false);
  const [vendorLoading, setVendorLoading] = React.useState(false);
  const [decoded, setDecoded] = React.useState(null);
  const [view, setView] = React.useState("grid");
  const [page, setPage] = useState(0);
  const [RowsPerPage, setRowsPerPage] = useState(30);
  const [clearPaging, setClearPaging] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [pOId, setPOId] = useState();
  const [POHeaderId, setPOHeaderId] = useState("");
  const [receiptModal, setreceiptModal] = useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [receiptDetail, setReceiptDetail] = useState("");
  const [reviewLoading, setReviewloading] = useState(false);
  const [receiptId, setReceiptId] = useState("");
  const [fusloading, setFusloading] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [review, setReview] = React.useState("");
  const [approve, setApprove] = React.useState("");
  const [approveExported, setApproveExported] = React.useState("");
  const [showFwdDetails, setShowFwdDetails] = React.useState(false);
  const [showFwdData, setShowFwdData] = React.useState(null);
  const [filterValue, setFilterValue] = React.useState(null);
  const [groupedReceipts, setGroupedReceipts] = useState([]);
  const [expanded, setExpanded] = React.useState(null);
  const [seacrhType, setSearchType] = React.useState("PO");
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [attachments, setAttachments] = useState(false);

  const [formState, setFormState] = useState({
    selectedPurchaseOrder: "",
    poInputValue: "",
    supplierInputValue: "",
    vendors: [],
    selectedVendor: "",
    supplierSearch: false,
    values: {
      status: "",
      comments: "",
    },
    errors: {
      status: "",
      reviewComments: "",
    },
  });
  const handleExpandClick = (key) => {
    if (key === expanded) {
      setExpanded(null);
    } else {
      setExpanded(key);
    }
  };

  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  const status = getParameterByName("status");

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  let userDetail = jwt.decode(Token);

  useEffect(() => {
    if (isPendingView) {
      setData(receipts);
      _grouping(receipts);
    }
  }, [isPendingView]);

  useEffect(() => {
    if (id !== ":id" && !isPendingView) {
      _getReceipts();
    }
  }, [id]);

  useEffect(() => {
    if (!isPendingView) {
      _getSuppliers();
      _getPurchaseOrders();
    }
  }, []);

  useEffect(() => {
    if (!clearPaging && !isPendingView) {
      // Function to parse query parameters
      const getQueryParams = (search) => {
        return new URLSearchParams(search);
      };
      // Get the query parameters
      const queryParams = getQueryParams(location.search);
      // Get the email parameter
      const email = queryParams.get("email");
      if (email) {
        // Remove the email parameter
        queryParams.delete("email");
        // Create a new search string without email
        const newSearch = queryParams.toString();
        const newLocation = {
          ...location,
          search: newSearch ? `?${newSearch}` : "",
        };
        // Update the URL
        history.replace(newLocation);
        // You can now use email for your purposes
        _getReceipts("", "", false, true);
      } else {
        _getReceipts();
      }
    }
  }, [page, RowsPerPage]);

  useEffect(() => {
    if (formState.poInputValue && !isPendingView) {
      _getPurchaseOrders();
    }
  }, [formState.poInputValue]);

  const FormatDateTime = (date) => {
    let Token = localStorage.getItem("cooljwt");
    let user = jwt.decode(Token);
    var offset;
    if (user?.tenantConfigs) {
      let tenantConfig = user.tenantConfigs;
      let timeStamp = tenantConfig.timeZone;
      offset = timeStamp.offset * 60;
    } else {
      offset = moment().utcOffset();
    }
    var now = new Date(date);
    const someday = moment(now)
      .utcOffset(offset)
      // .format('YYYY-MM-DDTHH:mm');
      .format("DD-MM-YYYY hh:mm A");
    return someday;
  };

  const _getPurchaseOrders = async (supplierId) => {
    setPurchaseOrder([]);
    setPoLoading(true);
    let suppId = supplierId ? supplierId : null;
    let formData = {
      isAdmin: userDetail.role.isAdmin,
      displayName: userDetail?.displayName,
      po: supplierId ? "" : formState.poInputValue,
      action: supplierId ? "PO" : seacrhType,
      supplierId: suppId,
      forPoOnly: false,
    };
    await axios({
      method: "post", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPoForRecAll`,
      data: formData,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        setPoLoading(false);
        setPurchaseOrder(res?.data);
      })
      .catch((error) => {
        setPoLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value), 30);
  };

  const _getPurchaseOrderDetails = async (id) => {
    //id == PO header ID
    let url = `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPODetails/${id}`;
    await axios({
      method: "get",
      url: url,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if (typeof response.data == "object") {
          setSelectedPurchaseOrder(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setSelectedPurchaseOrder(null);
      });
  };

  const viewReceipt = () => {
    setreceiptModal(true);
  };

  const _grouping = (receipts) => {
    let hashMapArray = new Map();
    // New Hashed Map
    receipts.map((r) => {
      if (hashMapArray.has(r.ReceiptNumber)) {
        hashMapArray.set(r.ReceiptNumber, [
          ...hashMapArray.get(r.ReceiptNumber),
          r,
        ]);
      } else {
        hashMapArray.set(r.ReceiptNumber, [r]);
      }
    });

    let receipts_filtered = {};
    for (const [key, value] of hashMapArray.entries()) {
      receipts_filtered[key] = value;
    }
    setGroupedReceipts(receipts_filtered);
  };
  const _getReceipts = async (POId, value, vendor, filterRec) => {
    setFormState((formState) => ({
      ...formState,
      supplierSearch: vendor,
    }));
    setIsLoading(true);
    let Id = id == ":id" && POHeaderId == "" ? null : id;
    let PoHeaderId = POId || POHeaderId || Id;
    let filter_Value = status === "true" ? "PENDING" : value || filterValue;
    let supplier = vendor ? vendor : false;
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getReciptsFromPOH/${PoHeaderId}/${seacrhType}/${filter_Value}/${supplier}?page=${page}&items=${RowsPerPage}`,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setIsLoading(false);
        setData(res?.data?.Recipts);
        _grouping(res?.data?.Recipts);
        setTotalNumOfItems(res?.data?.totalNumOfItems);
        if (filterRec) {
          let filteredRecp = res?.data?.Recipts.find(
            (x) => x.POHeaderId === Number(id)
          );
          if (filteredRecp) {
            reviewFile([filteredRecp]);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        // errorAlert(error?.response?.data?.Error|| error?.response?.data || 'Error in getting Receipt')
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const _getReceiptsClear = async (value) => {
    setIsLoading(true);
    let Id = id == ":id" && POHeaderId == "" ? null : id;
    let PoHeaderId = null;
    let filter_Value = null;
    let seacrhType = "PO";
    let page = 0;
    let rowsPerPage = 30;
    await axios({
      method: "get",
      url: `${
        process.env.REACT_APP_LDOCS_API_URL
      }/invoice/getReciptsFromPOH/${PoHeaderId}/${seacrhType}/${filter_Value}/${false}?page=${page}&items=${rowsPerPage}`,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setClearPaging(false);
        setIsLoading(false);
        setData(res?.data?.Recipts);
        _grouping(res?.data?.Recipts);
        setTotalNumOfItems(res?.data?.totalNumOfItems);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        // errorAlert(error?.response?.data?.Error|| error?.response?.data || 'Error in getting Receipt')
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };
  const syncPo = async () => {
    await axios
      .get(`${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncPurchaseOrder`, {
        headers: {
          cooljwt: Token,
        },
      })
      .then((res) => {});
    msgAlert(
      "Sync is in progress. It may take some time, you can continue using system."
    );
  };
  const syncSinglePo = async (POHeaderId) => {
    setPOId(POHeaderId);
    setIsloading(true);
    await axios
      .get(
        `${process.env.REACT_APP_LDOCS_API_URL}/invoice/syncSinglePO/${POHeaderId}`,
        {
          headers: {
            cooljwt: Token,
          },
        }
      )
      .then((res) => {
        setIsloading(false);
        successAlert("PO synced successfully");
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 190,
    bgcolor: "#fff",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const reviewFile = (row) => {
    setReceiptDetail(row);
    setReviewModal(true);
  };

  const handlerChange = (event) => {
    // console.log("event",event);
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  //Review Handler
  const reviewFileNow = async () => {
    let status;
    let comment;
    const Check = require("is-null-empty-or-undefined").Check;
    let error = false;

    if (!Check(formState.values.status)) {
      status = "success";
    } else {
      status = "error";
      error = true;
    }
    if (
      formState.values.status !== "rejected"
        ? (comment = "success")
        : !Check(formState.values.comments)
    ) {
      comment = "success";
    } else {
      comment = "error";
      error = true;
    }

    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        status: status,
        reviewComments: comment,
      },
    }));
    if (error) {
      return false;
    } else {
      setFormState((formState) => ({
        ...formState,
        errors: {
          ...formState.errors,
          status: "",
          reviewComments: "",
        },
      }));
      setReviewloading(true);
      let action = getPendingStepName(receiptDetail[0]?.workFlowStatus)[0];
      let postData = {
        receipt: receiptDetail,
        action: action.toLowerCase(),
        status: formState.values.status,
        comment: formState.values.comments,
      };
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/updateRecStatus`,
        data: postData,
        headers: { cooljwt: Token },
      })
        .then((res) => {
          setReviewloading(false);
          setReviewModal(false);
          _getReceipts();
          if (formState.values.status !== "rejected") {
            successAlert(
              `${
                getPendingStepName(receiptDetail[0]?.workFlowStatus)[0]
              } Successfully`
            );
          } else {
            successAlert("Rejected");
          }
        })
        .catch((err) => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              status: "",
              comments: "",
            },
          }));
          let error = err?.response?.data;
          errorAlert(error);
          setReviewloading(false);
          setReviewModal(false);
        });
    }
  };
  const exporttofusion = async (key, row) => {
    setReceiptId(key);
    setFusloading(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/exportRecToFusion`,
      data: { rows: row },
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setFusloading(false);
        _getReceipts(null, "ALL");
        if (res?.data?.Exported && res?.data?.Exported?.length > 0) {
          let successMsg = res?.data?.Exported || [];
          let secerror = res?.data?.Error || [];

          let successString = "<h5> Successfully Exported Receipt Number </h5>";

          {
            successMsg.map((index, success) => {
              successString += `${success == 0 ? index : `, ${index}`}`;
            });
          }

          let secerrorString =
            '<h5> Error in Export Receipt Number </h5> <ul style="text-align:left">';

          {
            secerror.map((err) => {
              secerrorString += `<li>${err}</li>`;
            });
          }
          secerrorString += "</ul>";

          successHtmlAlert({ html: successString });
        }
      })
      .catch((err) => {
        if (
          err?.response?.data.Error &&
          err?.response?.data?.Error?.length > 0
        ) {
          let error = err?.response?.data?.Error || [];
          let secerror = err?.response?.data?.Success || [];

          let errorString =
            '<h5> Error in Export Receipt Number </h5> <ul style="text-align:left">';

          {
            error.map((err) => {
              errorString += `<li>${err}</li>`;
            });
          }
          errorString += "</ul>";

          let secerrorString = "<h5>Successfully Exported Receipt Number</h5>";

          {
            secerror.map((success, err) => {
              secerrorString += `${err == 0 ? success : `, ${success}`}`;
            });
          }

          htmlAlert({ html: errorString });
        } else {
          errorAlert(err?.response?.data);
        }
        setFusloading(false);
      });
  };

  const _trackingStatus = (selectedReceipt) => {
    let workFlowStatus = selectedReceipt?.workFlowStatus;
    setOpenTrackingModal(true);
    setReview(workFlowStatus?.reviewStep);
    setApprove(workFlowStatus?.approveStep);
    setApproveExported(workFlowStatus?.exportStep);
  };
  const closeTrackingModal = () => {
    setOpenTrackingModal(false);
    setApprove("");
    setApproveExported("");
  };
  const handleFilter = (event) => {
    setPage(0);
    setRowsPerPage(30);
    let value = event.target.value;
    _getReceipts(null, value, false);
    setFilterValue(value);
  };
  const handleChange = (event) => {
    setPurchaseOrder([]);
    setFormState((formState) => ({
      ...formState,
      selectedPurchaseOrder: "",
      selectedVendor: "",
    }));
    setSearchType(event.target.value);
  };

  const _getSuppliers = async () => {
    setVendorLoading(true);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/searchVendorsAll`,
      headers: { cooljwt: Token },
    })
      .then((res) => {
        setFormState((formState) => ({
          ...formState,
          vendors: res?.data?.result,
        }));
        setVendorLoading(false);
      })
      .catch((error) => {
        setVendorLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };

  const _getPurchaseOrdersList = async (SupplierId) => {
    await axios({
      method: "get", //you can set what request you want to be
      url: `${
        process.env.REACT_APP_LDOCS_API_URL
      }/invoice/getPurchaseOrdersBySupplierId/${SupplierId}/${null}/${false}`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        setPurchaseOrder(res.data || []);
      })
      .catch((error) => {
        if (error?.response) {
          error?.response?.status == 401 && dispatch(setIsTokenExpired(true));
        }
      });
  };

  const handleClear = () => {
    setFormState((formState) => ({
      ...formState,
      selectedPurchaseOrder: "",
      poInputValue: "",
      supplierInputValue: "",
      selectedVendor: "",
      supplierSearch: false,
    }));
    setPOHeaderId("");
    setFilterValue("");
    setSearchType("PO");
    setPage(0);
    setRowsPerPage(30);
    _getReceiptsClear("clear");
    setClearPaging(true);
  };

  const sumOfValues = (row) => {
    let total;
    total = row.reduce(function(prev, current) {
      return prev + current.totalAmount;
    }, 0);
    return total;
  };

  const handleAttachmentModal = (attachments) => {
    setAttachments(attachments);
    setAttachmentModal(true);
  };

  const saveFile = (file) => {
    saveAs(file);
  };

  const showFwdNotes = (data) => {
    let transformedSteps = [data];

    // // Transform review steps
    // if (Array.isArray(review)) {
    //   transformedSteps = [
    //     ...transformedSteps,
    //     ...review.map((r) => ({ ...r, stepType: 'review' })),
    //   ];
    // }

    // // Transform approve steps
    // if (Array.isArray(approve)) {
    //   transformedSteps = [
    //     ...transformedSteps,
    //     ...approve.map((a) => ({ ...a, stepType: 'approve' })),
    //   ];
    // }

    const allActions = transformedSteps.reduce((acc, step) => {
      if (Array.isArray(step.forward)) {
        acc.push(...step.forward.map((f) => ({ ...f, showReason: true })));
      }
      if (Array.isArray(step.delegate)) {
        acc.push(...step.delegate.map((d) => ({ ...d, showReason: false })));
      }
      return acc;
    }, []);

    const filteredActions = allActions.filter((x) => x !== undefined);

    setShowFwdData({
      forward: filteredActions.sort(
        (a, b) => new Date(a?.date) - new Date(b?.date)
      ),
    });

    setShowFwdDetails(true);
  };

  // get Pending Step Name
  function getPendingStepName(data) {
    let pendingSteps = [];

    // Check reviewStep for pending status
    data.reviewStep.forEach((step) => {
      if (step?.status === "PENDING") {
        pendingSteps.push("Review");
      }
    });

    // Check approveStep for pending status
    data.approveStep.forEach((step) => {
      if (step?.status === "PENDING") {
        pendingSteps.push("Approve");
      }
    });

    return pendingSteps;
  }

  return (
    <>
      {attachmentModal ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={attachmentModal}
          keepMounted
          onClose={() => setAttachmentModal(false)}
          aria-labelledby="selected-modal-slide-title"
          aria-describedby="selected-modal-slide-description"
        >
          <DialogContent id="selected-Select" className={classes.modalBody}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Attachment Details</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {attachments?.length === 0 ? (
                    <>
                      <Typography variant="body1" component="h6">
                        No Attachments
                      </Typography>
                    </>
                  ) : (
                    attachments?.map((res, index) => (
                      <>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Card>
                            <CardBody
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                cursor: "pointer",
                                height: "158px",
                              }}
                            >
                              <Typography variant="body1" component="h6">
                                {res?.attachmentTitle}
                              </Typography>
                              <sub>{res?.type}</sub>
                            </CardBody>
                            <CardFooter>
                              <IconButton
                                onClick={() =>
                                  saveFile(
                                    res?.base64 ||
                                      `${process.env.REACT_APP_LDOCS_API_URL}/${res?.eAttachmentPath}`
                                  )
                                }
                                style={{
                                  float: "right",
                                  color: "orange",
                                }}
                                fontSize="small"
                              >
                                <GetAppIcon />
                              </IconButton>
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </>
                    ))
                  )}
                  <GridItem
                    style={{
                      display: "flex",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      color="danger"
                      size="small"
                      onClick={() => setAttachmentModal(false)}
                      round
                    >
                      Close
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      <Animated
        animationIn="bounceInRight"
        animationOut="bounceOutLeft"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={view == "grid" ? true : false}
      >
        <GridContainer>
          {!isPendingView && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>Filter</h4>
                  </CardIcon>
                  <Button
                    color="info"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => handleClear()}
                  >
                    Clear Filters
                  </Button>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={12}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Autocomplete
                            id="filter-demo"
                            fullWidth={true}
                            options={formState.vendors || []}
                            variant="outlined"
                            inputValue={formState.supplierInputValue || ""}
                            onInputChange={(event, newInputValue) => {
                              setFormState((formState) => ({
                                ...formState,
                                supplierInputValue: newInputValue,
                              }));
                            }}
                            value={formState.selectedVendor || ""}
                            getOptionLabel={(option) =>
                              option?.level1?.Supplier || ""
                            }
                            onChange={(event, newValue) => {
                              setFormState((formState) => ({
                                ...formState,
                                selectedVendor: newValue,
                              }));
                              if (newValue?.level1?.SupplierId) {
                                setPOHeaderId(newValue?.level1?.SupplierId);
                                setSearchType("PO");
                                _getPurchaseOrders(
                                  newValue?.level1?.SupplierId
                                );
                                _getReceipts(
                                  newValue?.level1?.SupplierId,
                                  "ALL",
                                  true
                                );
                              }
                            }}
                            clearIcon={null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={"Select Supplier"}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {vendorLoading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          {status === "true" ? (
                            ""
                          ) : (
                            <TextField
                              className={classes.textField}
                              fullWidth={true}
                              label="Status"
                              name="Status"
                              variant="outlined"
                              onChange={(event) => {
                                handleFilter(event);
                              }}
                              select
                              value={filterValue || ""}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                              >
                                Status
                              </MenuItem>
                              <MenuItem value="ALL">All</MenuItem>
                              <MenuItem value="PENDING">Pending</MenuItem>
                              <MenuItem value="APPROVED">Approved</MenuItem>
                              {/* <MenuItem value='CORRECTION REQUIRED'>
                        Correction Required
                      </MenuItem> */}
                              <MenuItem value="EXPORTED">Exported</MenuItem>
                            </TextField>
                          )}
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={3}
                          style={{ marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            label="Select Search Type"
                            id="seacrhType"
                            name="seacrhType"
                            variant="outlined"
                            select
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={seacrhType || ""}
                          >
                            <MenuItem disabled value="">
                              Select Search Type
                            </MenuItem>
                            <MenuItem value="PO">Search PO</MenuItem>
                            <MenuItem value="RECEIPT">Search Receipt</MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={3}>
                          <Autocomplete
                            id="filter-demo"
                            fullWidth={true}
                            options={PurchaseOrder || []}
                            value={formState.selectedPurchaseOrder || ""}
                            variant="outlined"
                            // getOptionLabel={option => option?.OrderNumber || ''}
                            getOptionLabel={(option) =>
                              seacrhType === "PO" && option !== ""
                                ? option?.OrderNumber
                                : seacrhType === "RECEIPT" && option !== ""
                                ? option?.ReceiptNumber
                                : "" || ""
                            }
                            clearIcon={null}
                            onInputChange={(event, newInputValue) => {
                              if (formState.selectedVendor === "") {
                                setFormState((formState) => ({
                                  ...formState,
                                  poInputValue: newInputValue,
                                }));
                              }
                            }}
                            onChange={(event, newValue) => {
                              setPage(0);
                              setRowsPerPage(30);
                              setFormState((formState) => ({
                                ...formState,
                                selectedPurchaseOrder: newValue,
                              }));
                              if (seacrhType == "PO" && newValue?.POHeaderId) {
                                _getReceipts(
                                  newValue?.POHeaderId,
                                  "ALL",
                                  false
                                );
                                setPOHeaderId(newValue?.POHeaderId);
                                _getPurchaseOrderDetails(newValue?.POHeaderId);
                              } else {
                                _getReceipts(
                                  newValue?.ReceiptNumber,
                                  "ALL",
                                  false
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label={
                                  seacrhType === "PO"
                                    ? "Search By PO"
                                    : "Search By Receipt"
                                }
                                placeholder={"Search"}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {poLoading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}

                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={6}></GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          )}
          <GridItem xs={12}>
            <Card>
              {!isPendingView && (
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>Receipt</h4>
                  </CardIcon>
                  {/* <Button
                  color="danger"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={syncPo}
                >
                  Sync
                </Button>
                <Button
                  color="info"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => setIsFilterBox(true)}
                >
                  Filter
                </Button> */}
                  {!userDetail.role.isAdmin ? (
                    <Link to="/default/createReceipt/ap">
                      <Button
                        color="danger"
                        round
                        style={{ float: "right", marginTop: 10 }}
                      >
                        Create Receipt
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </CardHeader>
              )}
              <CardBody>
                {isLoading ? (
                  <CircularProgress disableShrink />
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        style={{ minWidth: 650 }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell style={{ fontWeight: "bolder" }}>
                              Receipt Number
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell align="left">
                              Status
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              PO Number
                            </StyledTableCell>
                            {/* <StyledTableCell align='left'>
                              Total Amount
                            </StyledTableCell> */}
                            <StyledTableCell align="left">
                              Created By
                            </StyledTableCell>
                            {/* <StyledTableCell align='left'>
                              Transaction Type
                            </StyledTableCell> */}
                            <StyledTableCell align="left">
                              Vendor Name
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Actions{" "}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(groupedReceipts)
                            .reverse()
                            .map(([key, value]) => (
                              <>
                                {fusloading && receiptId == key ? (
                                  <TableRow>
                                    <TableCell colSpan={8}>
                                      {" "}
                                      <LinearProgress
                                        style={{ width: "100%" }}
                                      />
                                    </TableCell>{" "}
                                  </TableRow>
                                ) : (
                                  ""
                                )}

                                <StyledTableRow key={key}>
                                  <StyledTableCell
                                    style={{ fontWeight: "bolder" }}
                                    colSpan={2}
                                    component="th"
                                    scope="row"
                                  >
                                    <IconButton
                                      className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                      })}
                                      // className={expanded}
                                      onClick={() => handleExpandClick(key)}
                                      aria-expanded={expanded}
                                      aria-label="show more"
                                    >
                                      <ExpandMoreIcon />
                                    </IconButton>
                                    {value[0]?.workFlowStatus?.status ===
                                    "EXPORTED"
                                      ? key
                                      : "" || ""}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    <Tooltip
                                      title={
                                        value[0]?.workFlowStatus?.status?.toUpperCase() ||
                                        ""
                                      }
                                    >
                                      <Chip
                                        variant="outlined"
                                        size="small"
                                        label={
                                          `${value[0]?.workFlowStatus?.status?.toUpperCase()}` ||
                                          ""
                                        }
                                        clickable
                                        onClick={() =>
                                          _trackingStatus(value[0])
                                        }
                                        style={{
                                          border:
                                            value[0]?.workFlowStatus?.status ===
                                            "PENDING"
                                              ? "1px solid orange"
                                              : value[0]?.workFlowStatus
                                                  ?.status === "REJECTED"
                                              ? "1px solid red"
                                              : value[0]?.workFlowStatus
                                                  ?.status ===
                                                "APPROVED AND EXPORTED"
                                              ? "1px solid green"
                                              : value[0]?.workFlowStatus
                                                  ?.status === "APPROVED"
                                              ? "1px solid green"
                                              : "1px solid green",
                                          color:
                                            value[0]?.workFlowStatus?.status ===
                                            "PENDING"
                                              ? "orange"
                                              : value[0]?.workFlowStatus
                                                  ?.status === "REJECTED"
                                              ? "red"
                                              : value[0]?.workFlowStatus
                                                  ?.status ===
                                                "APPROVED AND EXPORTED"
                                              ? "green"
                                              : value[0]?.workFlowStatus
                                                  ?.status === "APPROVED"
                                              ? "green"
                                              : "green",
                                        }}
                                      />
                                    </Tooltip>
                                    {value[0]?.workFlowStatus?.status ===
                                      "EXPORTED" &&
                                    value[0]?.workFlowStatus?.workFlowId &&
                                    value[0]?.workFlowStatus?.workFlowId !==
                                      null ? (
                                      <Tooltip
                                        title={"Exported From InvoiceMate"}
                                      >
                                        <div style={{ float: "right" }}>
                                          <PlaylistAddCheckIcon
                                            color="primary"
                                            fontSize="small"
                                          />
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {value[0]?.PONumber}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align='left'>
                                    {addZeroes(value[0]?.totalAmount)}
                                  </StyledTableCell> */}
                                  <StyledTableCell align="left">
                                    {value[0]?.CreatedBy}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align='left'>
                                    {value[0]?.TransactionTypeCode}
                                  </StyledTableCell> */}
                                  {/* <StyledTableCell align='left'>
                                    {moment(
                                      value[0]?.SysytemTransactionDate ||
                                        value[0]?.TransactionDate
                                    ).format('DD-MM-YYYY hh:mm A')}
                                  </StyledTableCell> */}
                                  <StyledTableCell align="left">
                                    {value[0]?.VendorName}
                                  </StyledTableCell>
                                  {/* <StyledTableCell align='left'>
                                    {value[0]?.CurrencyCode}
                                  </StyledTableCell> */}
                                  <StyledTableCell align="left">
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-evenly",
                                      }}
                                    >
                                      {value[0]?.workFlowStatus?.status !==
                                        "EXPORTED" &&
                                      value[0]?.workFlowStatus?.status !==
                                        "REJECTED" &&
                                      (value[0]?.workFlowStatus?.approveStep
                                        ?.approvedBy === userDetail?.email ||
                                        value[0]?.CreatedBy ===
                                          userDetail?.email) ? (
                                        <Link
                                          to={`/default/receipt_action/Edit/${
                                            value[0]?.ReceiptNumber
                                          }/${null}/ap`}
                                        >
                                          <Button
                                            color="danger"
                                            size="small"
                                            style={{ width: "120px" }}
                                          >
                                            Edit Receipt
                                          </Button>
                                        </Link>
                                      ) : (
                                        ""
                                      )}

                                      {value[0]?.workFlowStatus?.reviewStep?.some(
                                        (step) =>
                                          step.status === "PENDING" &&
                                          step.reviewedBy === userDetail?.email
                                      ) && (
                                        <Button
                                          color="danger"
                                          size="small"
                                          style={{ width: "120px" }}
                                          onClick={() => reviewFile(value)}
                                        >
                                          Review
                                        </Button>
                                      )}

                                      {value[0]?.workFlowStatus?.approveStep?.some(
                                        (step) =>
                                          step.status === "PENDING" &&
                                          step.approvedBy === userDetail?.email
                                      ) && (
                                        <Button
                                          color="danger"
                                          size="small"
                                          style={{ width: "120px" }}
                                          onClick={() => reviewFile(value)}
                                        >
                                          Approve
                                        </Button>
                                      )}
                                      {value[0]?.workFlowStatus?.approveStep?.some(
                                        (step) => step.status === "APPROVED"
                                      ) &&
                                        value[0]?.workFlowStatus?.exportStep
                                          ?.status === "PENDING" &&
                                        value[0]?.workFlowStatus?.exportStep
                                          ?.exportedBy ===
                                          userDetail?.email && (
                                          <Button
                                            color="danger"
                                            size="small"
                                            style={{ width: "120px" }}
                                            onClick={() =>
                                              exporttofusion(key, value)
                                            }
                                          >
                                            Export
                                          </Button>
                                        )}
                                      {/* Correct Return Receipts  */}
                                      {value[0]?.Quantity !== null &&
                                      value[0]?.workFlowStatus?.status ==
                                        "EXPORTED" &&
                                      !userDetail?.role?.isAdmin ? (
                                        <>
                                          {userDetail?.role?.isDefault ? (
                                            <>
                                              <Link
                                                to={`/default/receipt_action/Return/${value[0].ReceiptNumber}/${value[0]?.LineNumber}/ap`}
                                              >
                                                <Button
                                                  color="danger"
                                                  size="small"
                                                  style={{ width: "120px" }}
                                                >
                                                  Return Receipt
                                                </Button>
                                              </Link>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {value[0]?.Amount !== null &&
                                      value[0]?.workFlowStatus?.status ==
                                        "EXPORTED" &&
                                      !userDetail?.role?.isAdmin ? (
                                        <>
                                          {userDetail?.role?.isDefault ? (
                                            <Link
                                              to={`/default/receipt_action/Correction/${value[0].ReceiptNumber}/${value[0]?.LineNumber}/ap`}
                                            >
                                              <Button
                                                color="danger"
                                                size="small"
                                                style={{ width: "130px" }}
                                              >
                                                Correct Receipt
                                              </Button>
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {isPendingView && (
                                        <Button
                                          color="danger"
                                          size="small"
                                          style={{ width: "130px" }}
                                          onClick={() =>
                                            forwardTask({ _id: value[0]?._id })
                                          }
                                        >
                                          forward Task
                                        </Button>
                                      )}
                                    </div>
                                  </StyledTableCell>
                                </StyledTableRow>

                                {expanded == key ? (
                                  <React.Fragment>
                                    <StyledTableRow key={key}>
                                      <StyledTableCell
                                        style={{ padding: 0 }}
                                        colSpan="10"
                                      >
                                        <Collapse
                                          in={expanded == key}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Table
                                            className={classes.table}
                                            size="small"
                                            aria-label="a dense table"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <StyledTableCell align="left">
                                                  Line Number
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Description
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Transaction Date
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Quantity
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Currency
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Amount
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Total Amount
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  Attachments
                                                </StyledTableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {value?.map((row, index) => (
                                                <StyledTableRow
                                                  key={index}
                                                  style={{ width: "100%" }}
                                                >
                                                  {/* <StyledTableCell
                                                    align='left'
                                                    style={{
                                                      background: '#808080a1'
                                                    }}
                                                  >
                                                    {row?.ReceiptNumber}{' '}
                                                  </StyledTableCell> */}
                                                  <StyledTableCell align="left">
                                                    {" "}
                                                    {row?.LineNumber}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    {" "}
                                                    {row?.ItemDescription}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    {FormatDateTime(
                                                      row?.SysytemTransactionDate ||
                                                        row?.TransactionDate
                                                    )}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    {row?.Quantity}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    {row?.CurrencyCode}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    {addZeroes(row?.Amount)}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    {addZeroes(
                                                      row?.totalAmount
                                                    )}
                                                  </StyledTableCell>
                                                  <StyledTableCell align="left">
                                                    <Button
                                                      color="danger"
                                                      size="small"
                                                      style={{ width: "140px" }}
                                                      onClick={() =>
                                                        handleAttachmentModal(
                                                          row?.attachments
                                                        )
                                                      }
                                                    >
                                                      View Attachment
                                                    </Button>
                                                  </StyledTableCell>
                                                </StyledTableRow>
                                              ))}
                                              <StyledTableRow>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left">
                                                  <b>Total</b>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                  <b>
                                                    {addZeroes(
                                                      sumOfValues(value)
                                                    )}
                                                  </b>
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            </TableBody>
                                          </Table>
                                        </Collapse>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        count={totalNumOfItems}
                        rowsPerPageOptions={[30, 40, 50]}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={RowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      {/* {!isFilterEnable ? (
                        <TablePagination
                          component="div"
                          count={productdata?.totalNumOfItems}
                          rowsPerPageOptions={[10, 25, 40, 50]}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={RowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />) : ""}
                      {isFilterEnable ? (
                        <TablePagination
                          component="div"
                          count={productdata?.totalNumOfItems}
                          rowsPerPageOptions={[10, 20, 30]}
                          page={page}
                          onPageChange={handleFilterChangePage}
                          rowsPerPage={RowsPerPage}
                          onRowsPerPageChange={handleFilterChangeRowsPerPage}
                        />) : ""} */}
                    </TableContainer>
                  </>
                )}
              </CardBody>
            </Card>
          </GridItem>
          {receiptModal ? (
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"xl"}
              open={receiptModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setreceiptModal(false)}
              aria-labelledby="vendor-modal-slide-title"
              aria-describedby="vendor-modal-slide-description"
            >
              <DialogContent id="vendorSelect" className={classes.modalBody}>
                <PurchaseOrderView
                  PurchaseOrder={selectedPurchaseOrder}
                  onBack={() => setreceiptModal(false)}
                />
              </DialogContent>
            </Dialog>
          ) : (
            ""
          )}
          {reviewModal ? (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} className={classes.center}>
                <Dialog
                  classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                  }}
                  fullWidth={true}
                  maxWidth={"sm"}
                  open={reviewModal}
                  // TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setReviewModal(false)}
                  aria-labelledby="tag-modal-slide-title"
                  aria-describedby="tag-modal-slide-description"
                >
                  <DialogContent
                    id="tag-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="info" icon>
                            <CardIcon color="info">
                              <h4>
                                {getPendingStepName(
                                  receiptDetail[0]?.workFlowStatus
                                )}
                              </h4>
                            </CardIcon>
                          </CardHeader>
                          <CardBody>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <TextField
                                className={classes.textField}
                                error={formState.errors.status === "error"}
                                fullWidth={true}
                                select
                                helperText={
                                  formState.errors.status === "error"
                                    ? "Status is required"
                                    : null
                                }
                                label="Status"
                                name="status"
                                onChange={(event) => {
                                  handlerChange(event);
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  Choose Status
                                </MenuItem>

                                <MenuItem
                                  value={
                                    getPendingStepName(
                                      receiptDetail[0]?.workFlowStatus
                                    ).includes("Review")
                                      ? "reviewed"
                                      : "approved"
                                  }
                                >
                                  <div className="fileinput text-center">
                                    <div className="thumbnail img-circle3">
                                      <img
                                        src={Success}
                                        alt={"MARK AS ACTION"}
                                      />
                                    </div>
                                    {getPendingStepName(
                                      receiptDetail[0]?.workFlowStatus
                                    ).includes("Review")
                                      ? "MARK AS REVIEW"
                                      : "MARK AS APPROVE"}
                                  </div>
                                </MenuItem>
                                {/* <MenuItem value='correctionRequired'>
                                  <div className='fileinput text-center'>
                                    <div className='thumbnail img-circle3'>
                                      <img
                                        src={Pending}
                                        alt={'correction Required'}
                                      />
                                    </div>
                                    CORRECTION REQUIRED
                                  </div>
                                </MenuItem> */}
                                <MenuItem value="rejected">
                                  <div className="fileinput text-center">
                                    <div className="thumbnail img-circle3">
                                      <img
                                        src={Rejected}
                                        alt={"MARK AS REJECTED"}
                                      />
                                    </div>
                                    MARK AS REJECTED
                                  </div>
                                </MenuItem>
                              </TextField>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <TextField
                                className={classes.textField}
                                error={
                                  formState.errors.reviewComments === "error"
                                }
                                fullWidth={true}
                                required={
                                  formState.values.status == "rejected"
                                    ? true
                                    : false
                                }
                                InputLabelProps={{
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                }}
                                helperText={
                                  formState.errors.reviewComments === "error"
                                    ? "Comments is required"
                                    : null
                                }
                                label="Comments"
                                name="comments"
                                onChange={(event) => {
                                  handlerChange(event);
                                }}
                                // value={formState.values.reviewComments || ""}
                              ></TextField>
                            </GridItem>
                            <span style={{ float: "right" }}>
                              <Button
                                color="info"
                                className={classes.registerButton}
                                round
                                type="button"
                                onClick={() => reviewFileNow()}
                              >
                                {reviewLoading ? (
                                  <CircularProgress style={{ color: "#fff" }} />
                                ) : (
                                  getPendingStepName(
                                    receiptDetail[0]?.workFlowStatus
                                  )
                                )}
                              </Button>
                              <Button
                                color="danger"
                                className={classes.registerButton}
                                onClick={() => setReviewModal(false)}
                                round
                              >
                                CLOSE
                              </Button>
                            </span>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </DialogContent>
                </Dialog>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
          {openTrackingModal ? (
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"md"}
              open={openTrackingModal}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setOpenTrackingModal(false)}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitle}>Tracking Status</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow className={classes.TableRow}>
                            <TableCell className={classes.TableID}>
                              Review / Approve
                            </TableCell>
                            <TableCell className={classes.TableID}>
                              Assigned To
                            </TableCell>
                            <TableCell className={classes.TableID}>
                              Assigned Date
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Status
                            </TableCell>
                            <TableCell className={classes.TableCell}>
                              Action Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ paddingBottom: 5 }}>
                          {review.map((item, index) => (
                            <TableRow className={classes.TableRow} key={index}>
                              <TableCell className={classes.TableCell}>
                                Review
                                {item?.isForward || item?.isDelegate ? (
                                  <IconButton
                                    onClick={() => showFwdNotes(item)}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </TableCell>

                              <TableCell>
                                {item?.reviewedBy || "-"}
                                <br />
                                {item?.assignTo || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.assignDate
                                  ? formatDateTime(item?.assignDate)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {item?.status?.toUpperCase() || "PENDING"}
                              </TableCell>
                              <TableCell>
                                {item?.status !== "pending" && item?.date
                                  ? formatDateTime(item?.date)
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          ))}

                          {approve.map((item, index) => (
                            <TableRow className={classes.TableRow} key={index}>
                              <TableCell className={classes.TableCell}>
                                Approve
                                {item?.isForward || item?.isDelegate ? (
                                  <IconButton
                                    onClick={() => showFwdNotes(item)}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </TableCell>

                              <TableCell>
                                {item?.approvedBy || "-"}
                                <br />
                                {item?.assignTo || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.assignDate
                                  ? formatDateTime(item?.assignDate)
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {item?.status?.toUpperCase() || "PENDING"}
                              </TableCell>
                              <TableCell>
                                {item?.status !== "pending" && item?.date
                                  ? formatDateTime(item?.date)
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow className={classes.TableRow}>
                            <TableCell className={classes.TableCell}>
                              Approve and Export
                            </TableCell>
                            <TableCell>
                              {approveExported?.exportedBy || "-"}
                              <br />
                              {approveExported?.assignTo || "-"}
                            </TableCell>
                            <TableCell>
                              {approveExported?.assignDate
                                ? formatDateTime(approveExported?.assignDate)
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {approveExported?.status?.toUpperCase() ||
                                "PENDING"}
                            </TableCell>
                            <TableCell>
                              {approveExported?.status !== "pending" &&
                              approveExported?.date
                                ? formatDateTime(approveExported?.date)
                                : "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardBody>
                  </Card>
                  <Button
                    color="danger"
                    className={classes.registerButton}
                    onClick={() => closeTrackingModal()}
                    round
                    style={{ float: "right", marginLeft: "auto" }}
                  >
                    CLOSE
                  </Button>
                </GridContainer>
              </DialogContent>
            </Dialog>
          ) : (
            ""
          )}
          {showFwdDetails ? (
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
              }}
              fullWidth={true}
              maxWidth={"md"}
              open={showFwdDetails}
              // TransitionComponent={Transition}
              keepMounted
              onClose={() => setShowFwdDetails(false)}
              aria-labelledby="tag-modal-slide-title"
              aria-describedby="tag-modal-slide-description"
            >
              <DialogContent
                id="tag-modal-slide-description"
                className={classes.modalBody}
              >
                <GridContainer>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitle}>
                          Forward/Deligate Details
                        </h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Foward/Deligate User</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ paddingBottom: 5 }}>
                          {showFwdData &&
                            showFwdData?.forward?.map((fwd, index) => (
                              <>
                                <TableRow
                                  className={classes.TableRow}
                                  key={index}
                                >
                                  <TableCell className={classes.TableCell}>
                                    {formatDateTime(fwd?.date)}
                                  </TableCell>
                                  <TableCell>{fwd?.noteMsg}</TableCell>
                                </TableRow>
                                {fwd?.showReason && (
                                  <TableRow
                                    className={classes.TableRow}
                                    key={index}
                                  >
                                    <TableCell colSpan={3}>
                                      <b>Reason:</b> {fwd?.reason}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))}
                        </TableBody>
                      </Table>
                    </CardBody>
                  </Card>
                  <Button
                    color="danger"
                    className={classes.registerButton}
                    onClick={() => setShowFwdDetails(false)}
                    round
                    style={{ float: "right", marginLeft: "auto" }}
                  >
                    CLOSE
                  </Button>
                </GridContainer>
              </DialogContent>
            </Dialog>
          ) : (
            ""
          )}
        </GridContainer>
      </Animated>
    </>
  );
};
export default ReceiveReceipt;
