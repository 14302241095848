import React, { useState, useEffect } from "react";
import { DataGrid, useGridApiRef } from "@material-ui/data-grid";
import Button from "components/CustomButtons/Button.js";
import {
  CircularProgress,
  LinearProgress,
  TextField,
  makeStyles,
  DialogContent,
  Dialog,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CardFooter from "components/Card/CardFooter";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import axios from "axios";
import { errorAlert } from "Functions";
import { Route, useParams, useHistory } from "react-router-dom";
import { formatDateTime } from "views/LDocs/Functions/Functions";
import { successAlert } from "Functions";
import { msgAlert } from "Functions";
import { formatDate } from "views/LDocs/Functions/Functions";
import moment from "moment";
import jwt from "jsonwebtoken";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AttachmentRounded from "@material-ui/icons/AttachmentRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Animated } from "react-animated-css";
import Iframe from "react-iframe";
import { successHtmlAlert } from "views/LDocs/Functions/Functions";
import { htmlAlert } from "views/LDocs/Functions/Functions";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";
import { Info } from "@material-ui/icons";
import RecDetailModal from "./RecDetailModal";

const useStyles = makeStyles(styles);

export default function ReceiptTransactionActions() {
  const classes = useStyles();
  const history = useHistory();
  const { id, action, lineNo } = useParams();
  const [selection, setSelection] = useState([]);
  const [rows, setRows] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [transactionLoading, setTrasactionLoading] = useState(false);
  const [transactionDate, setTransactionDate] = useState("");
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [recDetailModal, setRecDetailModal] = useState(false);
  const [file, setFile] = useState(null);
  const [viewFile, setViewFile] = useState(false);
  const [pdf, setPdf] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [istransactionDate, setIsTransactionDate] = useState(true);
  const [formStateA, setFormStateA] = useState({
    attachments: [],
    values: {
      fileTitle: "",
      fileDescription: "",
    },
  });
  const localTime = new Date();
  const maxDate = moment(localTime).format("YYYY-MM-DDTHH:mm");
  let fileInput = React.createRef();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "lineNumber",
      sortable: false,
      headerName: "Line Number",
      width: 90,
    },
    {
      field: "info",
      sortable: false,
      headerName: "Info",
      width: 90,
      renderCell: (params) => {
        const { row } = params;
        const { id } = params;
        return (
          <div style={{ paddingTop: "15px" }}>
            {selection.includes(row.id) ? (
              <Tooltip title="View Receipt Details" aria-label="profile">
                <Info
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => handleRecDetailModal(row, id)}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "ReceiptNumber",
      sortable: false,
      headerName: "Receipt Number",
      width: 90,
    },

    {
      field: "description",
      headerName: "Description",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "supplier",
      headerName: "Supplier Name",
      width: 100,
      sortable: false,
    },
    {
      field: "purchase_order",
      headerName: "Purchase Order",
      width: 100,
      sortable: false,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
      sortable: false,
    },
    {
      field: "attachments",
      width: 100,
      headerName: "Attachments",
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        const { id } = params;
        return (
          <div style={{ paddingTop: "15px" }}>
            {selection.includes(row.id) ? (
              <AddCircleOutlineIcon
                fontSize="small"
                onClick={() => handleAttachmentModal(row, id)}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "quantity",
      width: 100,
      headerName: `Quantity *`,
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div style={{ paddingTop: "15px" }}>
            <TextField
              fullWidth={true}
              id="name"
              required
              variant="outlined"
              type="text"
              style={
                selection.includes(row.id)
                  ? { background: "#fff", height: "100%", width: "100%" }
                  : { height: "100%", width: "100%" }
              }
              disabled={!selection.includes(row.id)}
              onChange={(event) =>
                handleCellValueChange("quantity", event.target.value, row.id)
              }
              value={selection.includes(row.id) ? row?.quantity : row?.quantity}

              //   value={
              //     selection.includes(row.id)
              //       ? transactionDate
              //       : formatDateTime(row?.transaction_date)
              //   }
            />
          </div>
        );
      },
    },

    {
      field: "transaction_date",
      width: 100,
      flex: 1,
      headerName: "Transaction Date *",
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        // console.log(moment(row?.transaction_date).format('YYYY-MM-DDTHH:MM'))
        const options = {
          maxDate: new Date(),
          altInputClass: "hide",
          dateFormat: "M d Y h:i K",
          minDate: new Date("01-01-2018"),
          enableTime: true,
        };
        return (
          <div style={{ paddingTop: "15px" }}>
            <Flatpickr
              data-enable-time
              options={options}
              name="transaction_date"
              disabled={!selection.includes(row.id)}
              // onChange={e => _handleCellValueChange(e, id, row)}
              onChange={(selectedDates, dateStr) => {
                handleTransactionDate(selectedDates[0], row.id, row);
              }}
              value={row?.showTransaction_date || ""}
              style={{
                height: "36px",
                margin: "0px 0 23px 0",
              }}
            />
            {/* <TextField
              id='datetime-local'
              type='datetime-local'
              variant='outlined'
              inputProps={{
                max: `${maxDate}`
              }}
              disabled={!selection.includes(row.id)}
              style={
                selection.includes(row.id)
                  ? { background: '#fff', height: '100%', width: '100%' }
                  : { height: '100%', width: '100%' }
              }
              onChange={event =>
                handleCellValueChange(
                  'transaction_date',
                  event.target.value,
                  row.id
                )
              }
              value={row?.transaction_date}
            /> */}
          </div>
        );
      },
    },
  ];

  const handleAttachmentModal = (row, id) => {
    setSelectedRow(row);
    setAttachmentModal(true);
  };

  const handleRecDetailModal = (row, id) => {
    setSelectedRow(row);
    setRecDetailModal(true);
  };

  const handleCloseRecDetailModal = (row, id) => {
    setSelectedRow("");
    setRecDetailModal(false);
  };

  const handleTransactionDate = (selectedDate, id, row) => {
    let date = moment(selectedDate).format("YYYY-MM-DDTHH:mm");
    setTransactionDate(date);
    _validateDate(moment(date).format("YYYY-MM-DD"));
    // let date = new Date(e.target.value)
    // let newDate = FormatDateTime(new Date());
    // let finalDate = date.setSeconds(newDate);
    let index = rows.findIndex((row) => row.id === id);
    let rows_new = [...rows];
    let object = { ...rows[index], ["transaction_date"]: date };
    rows_new[index] = object;
    setRows(rows_new);
  };

  const handleCellValueChange = (name, value, id) => {
    if (name == "transaction_date") {
      setTransactionDate(value);
      _validateDate(value);
      let index = rows.findIndex((row) => row.id === id);
      let rows_new = [...rows];
      let object = { ...rows[index], [name]: value };
      rows_new[index] = object;
      setRows(rows_new);
    }
    if (name == "quantity") {
      let returnCorrection = action == "Return" || action == "Correction";
      if (returnCorrection) {
        let selectedPOLine = rows.find((row) => row.id === id);
        let poDetail = selectedPurchaseOrder?.poLineDetails.find((x) => x.POLineNumber === selectedPOLine?.POLineNumber);
        let checkAmounts =
          Number(value) > poDetail?.Received - poDetail?.Invoiced;
        if (checkAmounts) {
          msgAlert(
            "You have entered a value that exceeds the allowable quantity or amount based on the received and invoiced quantity or amount for this item. Please make sure to enter a valid value."
          );
          return;
        }
      }
      let index = rows.findIndex((row) => row.id === id);
      let rows_new = [...rows];
      let object = { ...rows[index], [name]: value };
      rows_new[index] = object;
      setRows(rows_new);
    }
  };

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");

  const _handleCellValueChange = (e) => {
    let index = rows.findIndex((row) => row.id === e.id);
    let rows_new = [...rows];
    let object = { ...rows[index], [e.field]: e.value };
    rows_new[index] = object;
    setRows(rows_new);
  };

  const FormatDate = (date) => {
    let Token = localStorage.getItem("cooljwt");
    let user = jwt.decode(Token);
    var offset;
    if (user?.tenantConfigs) {
      let tenantConfig = user.tenantConfigs;
      let timeStamp = tenantConfig.timeZone;
      offset = timeStamp.offset * 60;
    } else {
      offset = moment().utcOffset();
    }
    var now = new Date(date);
    const someday = moment(now)
      .utcOffset(offset)
      .format("YYYY-MM-DD");
    return someday;
  };

  const _validateDate = async (date) => {
    let formateDate = FormatDate(date);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/accountingPeriodStatusLOV`,
      data: {
        date: formateDate,
      },
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        if (res?.data?.items.length > 0) {
          setIsTransactionDate(true);
        } else {
          setIsTransactionDate(false);
          msgAlert(
            "GL period is not opened. Please contact finance department."
          );
        }
      })
      .catch((error) => {
        setCreateLoading(false);
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const _createReceipt = async () => {
    try {
      let line_items = rows.map((row) => {
        if (selection.includes(row.id)) {
          return row;
        }
      });
      let selectedItems = [];
      for (let index = 0; index < selection.length; index++) {
        const element = selection[index];
        let findOne = rows.find((x) => x.id === element);
        selectedItems.push(findOne);
      }
      debugger;

      let validate_items = selectedItems.map((row) => {
        const isQuantityInvalid =
          row.quantity === null ||
          row.quantity === "" ||
          row.quantity.toString().trim() === "";
        const isTransactionDateEmpty = row.transaction_date === "";

        if (isQuantityInvalid && isTransactionDateEmpty) {
          return false; // Quantity is invalid and transaction_date is empty
        } else if (isQuantityInvalid) {
          return false;
        } else if (isTransactionDateEmpty) {
          return false;
        } else {
          return true; // Both fields are valid
        }
      });

      if (validate_items.includes(false)) {
        msgAlert("Quantity or Transaction Date is missing");
      } else {
        setCreateLoading(true);
        await axios({
          method: action === "Edit" ? "put" : "post", //you can set what request you want to be
          url:
            action === "Edit"
              ? `${process.env.REACT_APP_LDOCS_API_URL}/receipt/updateRecById`
              : `${process.env.REACT_APP_LDOCS_API_URL}/receipt/receiptAction`,
          data: {
            selectedPurchaseOrder,
            row: line_items,
            action: action,
          },
          headers: {
            cooljwt: Token,
          },
        }).then((res) => {
          setCreateLoading(false);
          if (res?.data?.Success && res?.data?.Success?.length > 0) {
            let successMsg = res?.data?.Success || [];
            let secerror = res?.data?.Error || [];

            let successString =
              '<h5> Successful:</h5> <ul style="text-align:left">';

            {
              successMsg.map((success) => {
                successString += ` <li>${success}</li>`;
              });
            }
            successString += "</ul>";

            let secerrorString =
              '<h5> Error:</h5> <ul style="text-align:left">';

            {
              secerror.map((err) => {
                secerrorString += `<li>${err}</li>`;
              });
            }
            secerrorString += "</ul>";

            successHtmlAlert({
              html: `<div> ${successMsg.length > 0 ? successString : ""} ${
                secerror.length > 0 ? secerror : ""
              } </div>`,
            });
          }
          if (action == "Edit") {
            successAlert(`Edit Successfully`);
          }
        });
      }
    } catch (error) {
      setCreateLoading(false);
      if (error.response) {
        error.response.status == 401 && dispatch(setIsTokenExpired(true));
      }
      if (
        error?.response?.data.Error &&
        error?.response?.data?.Error?.length > 0
      ) {
        let errorMsg = error?.response?.data?.Error || [];
        let secerror = error?.response?.data?.Success || [];

        let errorString = '<h5> Error:</h5> <ul style="text-align:left">';

        {
          errorMsg.map((err) => {
            errorString += `<li>${err}</li>`;
          });
        }
        errorString += "</ul>";

        let secerrorString =
          '<h5>Successful:</h5>  <ul style="text-align:left">';

        {
          secerror.map((success) => {
            secerrorString += ` <li>${success}</li>`;
          });
        }
        secerrorString += "</ul>";
        htmlAlert({
          html: `<div> ${errorMsg.length > 0 ? errorString : ""}  ${
            secerror.length > 0 ? secerrorString : ""
          } </div>`,
        });
      } else {
        errorAlert(error?.response?.data);
      }
    }
  };
  const FormatDateTime = (date) => {
    let Token = localStorage.getItem("cooljwt");
    let user = jwt.decode(Token);
    var offset;
    if (user?.tenantConfigs) {
      let tenantConfig = user.tenantConfigs;
      let timeStamp = tenantConfig.timeZone;
      offset = timeStamp.offset * 60;
    } else {
      offset = moment().utcOffset();
    }
    var now = new Date(date);
    const someday = moment(now)
      .utcOffset(offset)
      .format("YYYY-MM-DDTHH:mm");
    return someday;
  };

  const _getTransactionsById = async () => {
    setTrasactionLoading(true);
    try {
      let { data } = await axios({
        method: "get", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/receipt/getRecFromId/${id}`,
        headers: {
          cooljwt: Token,
        },
      });

      if (data?.length > 0) {
        _getPurchaseOrderDetails(data[0]?.POHeaderId);
      }
      let data_rows =
        data?.map((r) => {
          let quantity = r?.Amount || r.Quantity;
          let isQuantity = r?.Amount ? false : true;
          let date = new Date();
          setTransactionDate(moment(date).format("YYYY-MM-DDTHH:mm"));

          return {
            info: r,
            id: r._id,
            POLineNumber: r?.POLineNumber || "",
            lineNumber: r?.LineNumber || "",
            ReceiptNumber: r?.ReceiptNumber || "",
            currency: r?.CurrencyCode || "",
            isQuantity: isQuantity,
            TransactionId: r?.TransactionId || "",
            ParentTransactionId: r?.ParentTransactionId,
            description: r?.ItemDescription || "",
            supplier: r?.VendorName || "",
            attachments: r?.attachments || [],
            quantity: quantity,
            // transaction_date:
            //   moment(r?.SysytemTransactionDate).format('YYYY-MM-DDTHH:MM') || '',
            transaction_date:
              action == "Edit" ? FormatDateTime(r?.TransactionDate) : "",
            showTransaction_date:
              action == "Edit" ? new Date(r?.TransactionDate) : "",

            alldata: r,
          };
        }) || [];
      setRows(data_rows);
      setTrasactionLoading(false);
    } catch (error) {
      setTrasactionLoading(false);
      if (error.response) {
        error.response.status == 401 && dispatch(setIsTokenExpired(true));
      }
      errorAlert(error.response.data || "Error In Creating Receipt");
    }
  };

  const _getTransactions = async () => {
    setTrasactionLoading(true);
    try {
      let { data } = await axios({
        method: "post", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getRecieptByRecNum`,
        data: {
          id: parseInt(id),
          lineNumber: lineNo,
        },
        headers: {
          cooljwt: Token,
        },
      });

      if (data.length > 0) {
        _getPurchaseOrderDetails(data[0].POHeaderId);
      }
      let data_rows =
        data?.map((r) => {
          let quantity = r?.Amount || r.Quantity;
          let isQuantity = r?.Amount ? false : true;
          let date = new Date();
          setTransactionDate(moment(date).format("YYYY-MM-DDTHH:mm"));

          return {
            info: r,
            id: r._id,
            POLineNumber: r?.POLineNumber || "",
            lineNumber: r?.LineNumber || "",
            ReceiptNumber: r?.ReceiptNumber || "",
            currency: r?.CurrencyCode || "",
            isQuantity: isQuantity,
            TransactionId: r?.TransactionId || "",
            ParentTransactionId: r?.ParentTransactionId,
            description: r?.ItemDescription || "",
            supplier: r?.VendorName || "",
            attachments: r?.attachments || [],
            quantity: 0,
            // transaction_date:
            //   moment(r?.SysytemTransactionDate).format('YYYY-MM-DDTHH:MM') || '',
            transaction_date: "",
            purchase_order: r?.PONumber || "",
            TransactionId: r?.TransactionId || "",
            alldata: r,
          };
        }) || [];
      setRows(data_rows);
      setTrasactionLoading(false);
    } catch (error) {
      setTrasactionLoading(false);
      if (error.response) {
        error.response.status == 401 && dispatch(setIsTokenExpired(true));
      }
      errorAlert(error.response.data || "Error In Creating Receipt");
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleChange = (event) => {
    event.persist();
    if (event.target.name == "filename") {
      setSelectedFileName(event.target.value);
    }
  };

  useEffect(() => {
    if (action == "Edit") {
      _getTransactionsById();
    } else {
      _getTransactions();
    }
  }, []);

  const _getPurchaseOrderDetails = async (id) => {
    let url = `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPODetails/${id}`;
    await axios({
      method: "get",
      url: url,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if (typeof response.data == "object") {
          setSelectedPurchaseOrder(response.data);
        } else {
          setSelectedPurchaseOrder(null);
        }
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setSelectedPurchaseOrder(null);
      });
  };
  const handleAttachmentChange = (e) => {
    e.preventDefault();

    if (e.target.files[0].size > 6291456) {
      msgAlert("Maximum File Size 6 MB");
      fileInput.current.value = "";
    }
    // if (
    //   e.target.files[0].type === 'image/png' ||
    //   e.target.files[0].type === 'image/jpeg' ||
    //   e.target.files[0].type === 'application/pdf'
    // ) {
    let files = e.target.files;
    let file = e.target.files[0].name;
    setSelectedFileName(file);
    setSelectedFile(files);
    // } else {
    //   msgAlert('File Type Not Supported')
    //   fileInput.current.value = ''
    // }
  };
  const viewFileHandler = (file) => {
    setFile(file);
    setAttachmentModal(false);
    if (
      file?.eAttachmentURL?.split(".").pop() === "pdf" ||
      file.type === "application/pdf"
    ) {
      setPdf(true);
    } else {
      setPdf(false);
    }
    setViewFile(true);
  };

  //Remove Attachment
  const removeAttachment = (fileIndex) => {
    let attachments = formStateA.attachments.filter(
      (a, index) => index !== fileIndex
    );
    let index = rows.findIndex((row) => row.id === selectedRow?.id);
    let rows_new = [...rows];
    let object = { ...rows[index], ["attachments"]: attachments };
    rows_new[index] = object;
    setRows(rows_new);
  };

  const closeViewFile = () => {
    setViewFile(false);
    setAttachmentModal(true);
    setFile(null);
  };

  const getFileDetails = () => {
    // let fileTitle
    // const Check = require('is-null-empty-or-undefined').Check
    // var error = false

    // if (!Check(selectedFileName)) {
    //   fileTitle = 'success'
    // } else {
    //   fileTitle = 'error'
    //   error = true
    // }
    // setFormStateA(formState => ({
    //   ...formState,
    //   errors: {
    //     ...formState.errors,
    //     fileTitle: fileTitle
    //   }
    // }))
    // if (error) {
    //   return false
    // } else {
    Object.entries(selectedFile).map(async ([key, value]) => {
      // if (value.type == 'image/png' || 'image/jpeg' || 'application/pdf') {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFormStateA((formStateA) => ({
          ...formStateA,
          attachments: [
            ...formStateA.attachments,
            {
              name: value.name,
              // filename:selectedFileName,
              base64: reader.result,
              type: value.type,
              attachmentTitle: selectedFileName,
              attachmentPath: "",
              file: value,
              title: formStateA.values.fileTitle,
              description: formStateA.values.fileDescription,
            },
          ],
        }));
      };
      reader.readAsDataURL(value);
      setTimeout(() => {
        setFormStateA((formStateA) => ({
          ...formStateA,
          values: {
            ...formStateA.values,
            fileTitle: "",
            fileDescription: "",
          },
        }));
      }, 500);
      // }
    });
    setSelectedFileName("");
    fileInput.current.value = "";
    // }
  };

  const saveFile = (file) => {
    saveAs(file);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column  " }}>
      <h3>
        {action == "Return"
          ? "Return Receipt"
          : action == "Correction"
          ? "Receipt Correction"
          : "Edit Receipt"}
      </h3>
      {transactionLoading ? <LinearProgress /> : ""}
      <p>Select Rows to Perform Action</p>
      <div style={{ height: 800, width: "100%" }}>
        <DataGrid
          disableColumnMenu
          disableColumnFilter
          rows={rows}
          columns={columns}
          hideFooter={true}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(newSelection) => {
            setSelection(newSelection);
          }}
          onCellEditCommit={(params) => _handleCellValueChange(params)}
          isCellEditable={(params) => selection.includes(params.row.id)}
        />
      </div>
      <GridItem lg={2}>
        <Button
          color="danger"
          round
          style={{ float: "left", marginTop: 10 }}
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </GridItem>
      {istransactionDate && selection.length > 0 && (
        <div>
          <Button
            color="danger"
            round
            style={{ float: "right", marginTop: 10 }}
            onClick={_createReceipt}
          >
            {createLoading ? (
              <CircularProgress color="#fffff" />
            ) : (
              <>
                {" "}
                {capitalizeFirstLetter(action)} Receipt{" "}
                <small>({selection.length})</small>
              </>
            )}
          </Button>
        </div>
      )}
      {viewFile ? (
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Animated
            animationIn="bounceInRight"
            animationOut="bounceOutLeft"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={viewFile}
          >
            <Card xs={12} sm={12} md={12} lg={12}>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>
                    {file.attachmentTitle}
                  </h4>
                </CardIcon>
                <Button
                  color="danger"
                  round
                  style={{ float: "right" }}
                  className={classes.marginRight}
                  onClick={() => closeViewFile()}
                >
                  Go Back
                </Button>
              </CardHeader>
              <CardBody>
                {pdf ? (
                  <Iframe
                    url={
                      file?.base64 ||
                      `${process.env.REACT_APP_LDOCS_API_URL}/${file?.eAttachmentURL}`
                    }
                    width="100%"
                    id="myId"
                    className="myClassname"
                    height={window.screen.height}
                  />
                ) : (
                  <img
                    width="100%"
                    src={
                      file?.base64 ||
                      `${process.env.REACT_APP_LDOCS_API_URL}/${file?.eAttachmentURL}`
                    }
                    alt="image"
                  />
                )}
              </CardBody>
            </Card>
          </Animated>
        </GridItem>
      ) : (
        ""
      )}

      {attachmentModal ? (
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          fullWidth={true}
          maxWidth={"md"}
          open={attachmentModal}
          keepMounted
          onClose={() => setAttachmentModal(false)}
          aria-labelledby="selected-modal-slide-title"
          aria-describedby="selected-modal-slide-description"
        >
          <DialogContent id="selected-Select" className={classes.modalBody}>
            {/* <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Attachment Details</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <TextField
                      className={classes.textField}
                      InputLabelProps={{
                        style: { color: 'black' }
                      }}
                      fullWidth={true}
                      label='File Name'
                      name='filename'
                      onChange={event => {
                        handleChange(event)
                      }}
                      value={selectedFileName}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <TextField
                      className={classes.textField}
                      InputLabelProps={{
                        style: { color: 'black' }
                      }}
                      fullWidth={true}
                      label='File Description'
                      name='fileDescription'
                      // onChange={event => {
                      //   handleChange(event)
                      // }}
                      // value={formState.values.fileDescription || ''}
                    />
                  </GridItem>
                  <GridItem
                    style={{
                      display: 'flex',
                      alignItems: 'right',
                      justifyContent: 'flex-end',
                      marginTop: '20px'
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      color='danger'
                      size='small'
                      onClick={() => setAttachmentModal(false)}
                      round
                    >
                      Close
                    </Button>
                    <Button
                      color='info'
                      size='small'
                      onClick={getFileDetails}
                      round
                    >
                      Add File
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card> */}

            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <h4 className={classes.cardTitleText}>Attachment Details</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {/* <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Card
                      onClick={() => {
                        fileInput.current.click()
                      }}
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        background: '#f5f5f5',
                        height: '220px'
                        // margin: '5px'
                      }}
                    >
                      <AddCircleOutlineIcon
                        style={{
                          alignSelf: 'center',
                          width: 50,
                          height: 125,
                          marginBottom: 10
                        }}
                        fontSize='large'
                      />
                      <Typography variant='body1' component='h6'>
                        Add Attachment
                      </Typography>
                    </Card>
                    <input
                      type='file'
                      name='attachments'
                      style={{ display: 'none' }}
                      accept='image/png, image/jpeg'
                      ref={fileInput}
                      onChange={event => {
                        handleAttachmentChange(event)
                      }}
                    />
                  </GridItem> */}
                  {rows
                    ?.find((list) => list.id === selectedRow?.id)
                    .attachments?.map((res, index) => (
                      <>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Card>
                            <CardBody
                              style={{
                                padding: "10px",
                                textAlign: "center",
                                cursor: "pointer",
                                height: "158px",
                              }}
                            >
                              {/* <AttachmentRounded
                                onClick={() => viewFileHandler(res)}
                                style={{
                                  alignSelf: 'center',
                                  width: 50,
                                  height: 70,
                                  marginBottom: 10
                                }}
                                fontSize='large'
                              /> */}
                              <Typography variant="body1" component="h6">
                                {res?.attachmentTitle}
                              </Typography>
                              <sub>{res?.type}</sub>
                            </CardBody>
                            <CardFooter>
                              {/* <IconButton
                                onClick={() => viewFileHandler(res)}
                                style={{
                                  float: 'right',
                                  color: 'orange'
                                }}
                                fontSize='small'
                              >
                                <VisibilityIcon fontSize='small' />
                              </IconButton> */}
                              <IconButton
                                onClick={() =>
                                  saveFile(
                                    res?.base64 ||
                                      `${process.env.REACT_APP_LDOCS_API_URL}/${res?.eAttachmentPath}`
                                  )
                                }
                                style={{
                                  float: "right",
                                  color: "orange",
                                }}
                                fontSize="small"
                              >
                                <GetAppIcon />
                              </IconButton>

                              {/* <IconButton
                                onClick={() => removeAttachment(index)}
                                style={{
                                  float: 'right',
                                  color: 'red'
                                }}
                                fontSize='small'
                              >
                                <HighlightOffIcon fontSize='small' />
                              </IconButton> */}
                            </CardFooter>
                          </Card>
                        </GridItem>
                      </>
                    ))}
                  <GridItem
                    style={{
                      display: "flex",
                      alignItems: "right",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Button
                      color="danger"
                      size="small"
                      onClick={() => setAttachmentModal(false)}
                      round
                    >
                      Close
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      {recDetailModal ? (
        <RecDetailModal
          recDetailModal={recDetailModal}
          handleRecDetailModal={handleRecDetailModal}
          handleCloseRecDetailModal={handleCloseRecDetailModal}
          selectedRecord={{...selectedRow, ...selectedPurchaseOrder}}
        />
      ) : null}
    </div>
  );
}
