import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  TextField,
  MenuItem,
  makeStyles,
  Slide,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  LinearProgress,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Select,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Animated } from "react-animated-css";
import jwt from "jsonwebtoken";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useDispatch, useSelector } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Iframe from "react-iframe";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScanningDocumentAnimation from "components/ScanningDocumentAnimation/ScanningDocumentAnimation";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import Attachments from "./Attachments";
import Items from "./Items";
import { useHistory } from "react-router-dom";
import Refresh from "@material-ui/icons/Refresh";
import {
  addZeroes,
  conversionRate,
  successAlert,
  errorAlert,
  msgAlert,
  formatDateTime,
} from "views/LDocs/Functions/Functions";
import {
  Add,
  Person,
  SignalCellularNull,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { defaultCurrency } from "./GlobalValues";
import FileReceived from "../RecieveInvoice/FileReceived";
import { Autocomplete, TimelineItem } from "@material-ui/lab";
import { setIsTokenExpired } from "actions";
import { getOCRData } from "views/LDocs/Functions/Functions";
import GeneralInfo from "views/Components/SupplierView/Childs/GeneralInfo";
import PurchaseOrderView from "views/Components/PurchaseOrderView/PurchaseOrderView";

const sweetAlertStyle = makeStyles(styles2);

const styles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
  table: {
    minWidth: "100%",
  },
  itemName: {
    width: 400,
  },
  itemNumber: {
    width: "55%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  asterisk: {
    color: "red",
  },
}));

let pdfInput = React.createRef();
let fileInput = React.createRef();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getDateFormet = (date) => {
  var today = new Date(date);
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export default function CreateInvoiceOption(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("cooljwt");
  const history = useHistory();
  const isAr =
    history.location.pathname.substring(
      history.location.pathname.lastIndexOf("/") + 1
    ) == "ar"
      ? true
      : false;
  const dispatch = useDispatch();
  const { edit, fileData, closeModal, dratInvoice } = props;
  const [pdfModal, setPdfModal] = useState(false);
  const [isMarked, setIsMarked] = useState(false);
  const [vendorModal, setVendorModal] = useState(false);
  const [viewFile, setViewFile] = useState(false);
  const [items, setItems] = useState([]);
  const [addingItem, setAddingItem] = useState(false);
  const [file, setFile] = useState(null);
  const [isCreateInvoice, setIsCreateInvoice] = useState(edit ? false : true);
  const [isLoading, setIsLoading] = useState(false);
  const [PoLoading, setPoLoading] = useState(false);
  const sweetClass = sweetAlertStyle();
  const [editIndex, setEditIndex] = useState(null);
  const [selectedFileModel, setSelectedFileModel] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [fileInputRef, setFileInputRef] = useState("");
  const [isSavingInvoice, setIsSavingInvoice] = useState(false);
  const [isDraftInvoice, setIsDraftInvoice] = useState(false);
  const [discountModel, setIsDiscountModel] = useState(false);
  const [advanceTaxModel, setIsAdvanceTaxModel] = useState(false);
  const [praTaxModel, setIsPraTaxModel] = useState(false);
  const [taxModal, setIsTaxModal] = useState(false);
  const [category, setCategory] = useState(1);
  const [currencyLookups, setCurrencyLookups] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [receipts, setReceipts] = useState(null);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [purchaseOrderModal, setPurchaseOrderModal] = useState(false);
  const [receiptModal, setReceiptModal] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [markAsReceivedModel, setMarkAsReceivedModel] = useState(false);
  const [supplierId, setSupplierId] = useState(false);
  const [expenseValidation, setExpenseValidation] = useState(false);
  const [validCoaLoading, setValidCoaLoading] = useState(false);
  const [orcConversionRate, setOrcConversionRate] = useState(null);
  const [oracleConRate, setOracleConRate] = useState(false);
  const [isItemLoading, setIsItemLoading] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [currenciesLoading, setCurrenciesLoading] = useState(
    edit ? true : false
  );
  const [isInvoiceAmount, setIsInvoiceAmount] = useState();
  let duedate = new Date();
  let today = new Date();
  let plusDays = 0;
  duedate = duedate.setDate(today.getDate() + plusDays);
  const userDetails = jwt.decode(Token);
  const isVendor = userDetails.isVendor;
  let initialState = {
    conversionRate: 1,
    vendors: [],
    organizations: [],
    attachments: [],
    taxClassifications: [],
    isPo: true,
    isReceipt: false,
    isPeetyCash: false,
    isPrePayment: true,
    isExpense: false,
    selectedOrg: null,
    selectedVendor: null,
    selectedSite: null,
    loadingPurchaseOrder: false,
    autoCompleteOpen: true,
    supplierInputValue: "",
    poInputValue: "",
    selectedPurchaseOrder: null,
    changedPoInputValue: false,
    changedsupplierInputValue: false,
    values: {
      invoiceDate: getDateFormet(today),
      glDate: getDateFormet(today),
      InvoiceNumber: "",
      invoiceamount: "",
      supplierInvId: "INV-",
      dueDate: getDateFormet(duedate),
      poNumber: "",
      paymentTerms: "NET-",
      currency: "",
      paymentcurrency: "",
      itemName: "",
      inlineExpenseType: "",
      unitCost: 0,
      quantity: 0,
      discount: 0,
      amount: 0,
      additionalDetails: "",
      overallDiscount: 0,
      overallTax: 0,
      notes: "",
      selectedVendor: "",
      subtotal: 0,
      total: 0,
      fileTitle: "",
      fileDescription: "",
      discountType: 1,
      taxType: 1,
      poInline: "",
      expenseType: "",
      receiptNumber: "",
      selectSite: "",
      organizationId: "",
      advanceTax: 0,
      advanceTaxType: 1,
      pra: 0,
      praType: 1,
      vat: 0,
      liabilityType: "",
      taxClassification: "",
      requesterId: "",
    },
    errors: {
      invoiceDate: "",
      InvoiceNumber: "",
      invoiceamount: "",
      dueDate: "",
      glDate: "",
      paymentTerms: "",
      currency: "",
      paymentcurrency: "",
      poNumber: "",
      itemName: "",
      inlineExpenseType: "",
      unitCost: "",
      quantity: "",
      discount: "",
      amount: "",
      selectSite: "",
      additionalDetails: "",
      notes: "",
      selectedVendor: "",
      vendor: "",
      items: "",
      fileTitle: "",
      fileDescription: "",
      poInline: "",
      expenseType: "",
      receiptNumber: "",
      organizationId: "",
      advanceTax: "",
      advanceTaxType: "",
      pra: "",
      praType: "",
      vat: "",
      liabilityType: "",
      taxClassification: "",
    },
    coa: {
      Company: [],
      Department_CostCenter: [],
      Account1: [],
      Subsidiary: [],
      Spare1: [],
      Spare2: [],
    },
    defaultCoa: {
      values: {
        expPetty: "",
        libPetty: "",
        expReceipt: "",
        libReceipt: "",
        expPre: "",
        libaPre: "",
      },
    },
  };
  const [formState, setFormState] = useState(initialState);
  const [baseCurrency, setBaseCurrency] = useState(
    !isVendor
      ? userDetails?.currency?.Currency_Base || ""
      : formState.selectedOrg
      ? formState.selectedOrg.currency
      : ""
  );

  useEffect(() => {
    if (edit) {
      getPurchaseOrders();
    }
  }, [currenciesLoading]);

  const _getPaymentTerms = async () => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/payablesPaymentTerms`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((response) => {
        if (response?.data?.items?.length > 0) {
          let list = response?.data?.items;
          let filter = list.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.name === value.name)
          );
          setPaymentTerms(filter);
        }
      })
      .catch((error) => {
        msgAlert("Error in getting Payment Terms from Oracle");
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const _getOrganzationCurrencies = async () => {
    if (!oracleConRate) {
      setCurrenciesLoading(true);
      //Organization Id
      const organizationId = edit
        ? fileData.organizationId
        : isVendor
        ? formState.selectedOrg?.organizationId
        : userDetails.orgDetail.organizationId;

      //Get Currencies
      if (organizationId) {
        await axios({
          method: "get", //you can set what request you want to be
          url: `${process.env.REACT_APP_LDOCS_API_URL}/lookup/GetOrgCurrencies/${organizationId}`,
          headers: {
            cooljwt: Token,
          },
        })
          .then((res) => {
            if (typeof res.data == "object") {
              setCurrencyLookups(res.data.filter((c) => c.isEnabled == true));
              setCurrenciesLoading(false);
            }
          })
          .catch((error) => {
            setCurrenciesLoading(false);
            if (error.response) {
              error.response.status == 401 && dispatch(setIsTokenExpired(true));
            }
            // errorAlert("Unable to get Currencies");
          });
      }
    }
  };

  const _getPurchaseOrders = async (supId) => {
    setPurchaseOrders([]);
    setPoLoading(true);
    let poValue = null;
    // let newsupplierId = null;
    // let supplierId = supId || formState.selectedVendor?.level1?.SupplierId;
    // if (supplierId && edit) {
    //   newsupplierId = supplierId
    // } else if(isVendor){
    //   newsupplierId = supId
    //   poValue = null
    // }
    // else{
    //   newsupplierId = null
    // }
    if (isVendor) {
      setSupplierId(userDetails.SupplierId);
    }
    if (!supId) {
      supId = null;
    }
    if ((isVendor && supId == null) || undefined) {
      supId = supplierId;
      poValue = null;
    }
    if (!isVendor) {
      poValue = formState.poInputValue;
    }

    await axios({
      method: "get", //you can set what request you want to be
      url: `${
        process.env.REACT_APP_LDOCS_API_URL
      }/invoice/getPurchaseOrdersBySupplierId/${supId}/${poValue ||
        fileData?.po}/${edit ? true : false}/${edit ? true : null}`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        setReceipts(null);
        setPoLoading(false);
        setPurchaseOrders(res.data || []);
        if (edit && fileData.po && !formState.changedPoInputValue) {
          let selectedPo = res.data.find((po) => po.OrderNumber == fileData.po);
          setFormState((formState) => ({
            ...formState,
            selectedPurchaseOrder: selectedPo,
          }));
          _getPurchaseOrderDetails(selectedPo?.POHeaderId, false);
          getReceipts(selectedPo?.POHeaderId, false);
          if (!isVendor && fileData.isReceipt) {
            getReceipts(selectedPo?.POHeaderId);
          }
        }
      })
      .catch((error) => {
        setPoLoading(false);
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        if (!isVendor && error.response.status == 401) {
          errorAlert("Unable to Fetch Purchase Orders");
        }
        setReceipts(null);
        setPurchaseOrders([]);
      });
  };

  const getPurchaseOrders = async (supId) => {
    setPurchaseOrders([]);
    let newsupplierId = null;
    let supplierId = supId || formState.selectedVendor?.level1?.SupplierId;
    if (supplierId) {
      newsupplierId = supplierId;
    } else {
      newsupplierId = null;
    }
    const userDetails = jwt.decode(Token);
    await axios({
      method: "get", //you can set what request you want to be
      url: `${
        process.env.REACT_APP_LDOCS_API_URL
      }/invoice/getPurchaseOrdersBySupplierId/${newsupplierId}/${fileData.po}/${
        edit ? true : false
      }/${edit ? true : null}`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        setReceipts(null);
        setPurchaseOrders(res.data || []);
        // _getPurchaseOrderDetails(selectedPo?.POHeaderId, false);
        // getReceipts(selectedPo?.POHeaderId);
        if (edit && fileData.po) {
          let selectedPo = res.data.find((po) => po.OrderNumber == fileData.po);
          _getPurchaseOrderDetails(selectedPo?.POHeaderId, false);
          getReceipts(selectedPo?.POHeaderId);
          if (!isVendor && fileData.isReceipt) {
            getReceipts(selectedPo?.POHeaderId);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        if (!isVendor && error.response.status == 401) {
          errorAlert("Unable to Fetch Purchase Orders");
        }
        setReceipts(null);
        setPurchaseOrders([]);
      });
  };

  const getReceipts = (PHId) => {
    //PHId = Purchase Order Header Number
    const userDetails = jwt.decode(Token);
    setIsItemLoading(true);
    axios({
      method: "get", //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getRecieptByPoHeaderId/${PHId}`,
      headers: {
        cooljwt: Token,
      },
    })
      .then((res) => {
        let list = res.data;
        let hashMapArray = new Map();
        // New Hashed Map
        list.map((r) => {
          if (hashMapArray.has(r.ReceiptNumber)) {
            hashMapArray.set(r.ReceiptNumber, [
              ...hashMapArray.get(r.ReceiptNumber),
              r,
            ]);
          } else {
            hashMapArray.set(r.ReceiptNumber, [r]);
          }
        });

        let receipts_filtered = {};
        for (const [key, value] of hashMapArray.entries()) {
          receipts_filtered[key] = value;
        }

        setReceipts(receipts_filtered);
        setIsItemLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        if (!isVendor && error.response.status == 401) {
          errorAlert("Unable to Retrive Receipts ");
        }
        setReceipts([]);
      });
  };
  const classes = styles();

  const getTaxInNumber = (tax) => {
    let number = "";
    for (var i = 0; i < tax.length; i++) {
      if (parseInt(tax.charAt(i)) || parseInt(tax.charAt(i)) === 0) {
        number += tax.charAt(i);
      }
      if (i + 1 === tax.length) {
        if (number === "") {
          return 0;
        }
        return number;
      }
    }
  };

  const handleChange = (event) => {
    event.persist();
    if (event.target.name == "invoiceType") {
      if (event.target.value == "isReceipt") {
        setFormState((formState) => ({
          ...formState,
          isReceipt: true,
          isPrePayment: false,
          isPo: true,
          isPeetyCash: false,
        }));
      }
      if (event.target.value == "isPeetyCash") {
        setFormState((formState) => ({
          ...formState,
          isReceipt: false,
          isPrePayment: false,
          isPeetyCash: true,
          isPo: false,
          isExpense: false,
        }));
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            expenseType: "100-0-0-000-000000-000000",
            paymentTerms: "Net 30",
          },
        }));
      }
      if (event.target.value == "isPrePayment") {
        setFormState((formState) => ({
          ...formState,
          isReceipt: false,
          isPo: true,
          isPrePayment: true,
          isPeetyCash: false,
        }));
      }
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          poNumber: null,
        },
      }));
    }
    if (event.target.name == "invoiceDate") {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          glDate: event.target.value,
        },
      }));
    }
    if (event.target.name == "taxClassification") {
      let vat = getTaxInNumber(event.target.value);

      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          vat: vat,
        },
      }));
    }
    if (event.target.name == "isPo") {
      setFormState((formState) => ({
        ...formState,
        isPo: event.target.value == "isPo" && !formState.isPo,
        isExpense: event.target.value == "isExpense" && !formState.isExpense,
      }));
    }
    if (event.target.name == "invoiceDate" && formState.values.currency) {
      _getConversionRates(event);
    }
    if (event.target.name == "poNumber") {
      let selectedPo = purchaseOrders.find(
        (po) => po.OrderNumber === event.target.value
      );

      if (selectedPo) {
        _getPurchaseOrderDetails(selectedPo.POHeaderId, true);
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            paymentTerms: formState.isPrePayment
              ? "Advance Payment"
              : selectedPo.PaymentTerms || "",
          },
        }));
        if (!isVendor) {
          getReceipts(selectedPo.POHeaderId);
        }
      }
    }
    if (event.target.name == "receiptNumber") {
      let selectedReceipt = event.target.value;
      _addItemsFromReceipts(selectedReceipt);
    }

    if (event.target.name == "currency") {
      let selectedCurrency = currencyLookups.find(
        (l) => l._id == event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          paymentcurrency: selectedCurrency?.Code,
        },
      }));
    }
    // if (formState?.isPrePayment && formState?.isPo) {
    //   setFormState(formState => ({
    //     ...formState,
    //     values: {
    //       ...formState.values,
    //       currency: '607e8efd93fef52ec4a83c38'
    //     }
    //   }))
    //   updateCurrency()
    // }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const _getConversionRates = async (event) => {
    let currencyValue = currency?.Code;
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/currencyValue`,
      data: {
        date: event.target.value,
        currencyValue: currencyValue,
      },
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if (response?.data?.items?.length == 0) {
          setOracleConRate(true);
          msgAlert(
            "Conversion Rate not found for selected currency against invoice date"
          );
        } else {
          setOracleConRate(false);
          let filterData = response?.data?.items?.map((con) => {
            return con?.ConversionRate;
          });
          setFormState((formState) => ({
            ...formState,
            conversionRate: filterData[0] || 0,
          }));
          setOrcConversionRate(filterData[0] || 0);
        }
      })
      .catch((error) => {
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const _getCOADefaultValues = async () => {
    if (!formState.isPeetyCash) {
      let tenantId = formState.selectedOrg?.tenantId || null;
      let url;
      if (!isVendor) {
        url = `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getCombinationCOA`;
      } else {
        url = `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getCombinationCOA?tenantId=${tenantId}`;
      }
      axios({
        method: "get",
        url: url,
        headers: { cooljwt: Token },
      })
        .then((response) => {
          let defaultValues = response.data;
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              liabilityType: defaultValues?.prePayment?.liabilityAcc,
              expenseType: defaultValues?.prePayment?.expenseAcc,
            },
            defaultCoa: {
              ...formState.defaultCoa,
              expPetty: defaultValues?.pettyCash?.expenseAcc,
              libPetty: defaultValues?.pettyCash?.liabilityAcc,
              expReceipt: defaultValues?.withReceipt?.expenseAcc,
              libReceipt: defaultValues?.withReceipt?.liabilityAcc,
              expPre: defaultValues?.prePayment?.expenseAcc,
              libaPre: defaultValues?.prePayment?.liabilityAcc,
            },
          }));
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          console.log(
            typeof error.response != "undefined"
              ? error.response.data
              : error.message
          );
        });
    }
  };

  const _addItemsFromPurchaseOrder = (usedPurchasesOrder) => {
    let schedules = usedPurchasesOrder?.schedules;
    let itemsFromPurchaseOrder =
      usedPurchasesOrder?.lines?.map((line_item) => {
        let scheduleLineNumber = schedules.find(
          (s) => s.LineNumber == line_item.LineNumber
        );
        console.log("quantity check",line_item?.Quantity)
        console.log("amount check",scheduleLineNumber.Price , line_item?.Ordered)
        let quantity = line_item?.Quantity || 1;
        let unitPrice = scheduleLineNumber.Price || line_item?.Ordered || 0;
        //Quantity form line_item
        //Unit Price from schedule
        //Tax from schedule
        let calculatedTaxPerUnit = line_item?.TotalTax / quantity;
        let perUnitTaxPercentage =
          unitPrice !== 0 ? (calculatedTaxPerUnit / unitPrice) * 100 : 0;
        //Amount Calculation after Tax Add
        let totalAmount =
          quantity * unitPrice + calculatedTaxPerUnit * quantity;
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            taxClassification:
              `INPUT ${Math.round(perUnitTaxPercentage)}%` || `INPUT 0%` || "",
          },
        }));
        return {
          itemName: line_item?.Description || "",
          unitCost: unitPrice,
          quantity: quantity,
          discount: 0,
          poInline: null,
          expenseType: formState.defaultCoa.expPre,
          category: null,
          receiptNumber: "",
          isAmountMatching: line_item?.Quantity ? false : true,
          vat: parseFloat(perUnitTaxPercentage)?.toFixed(2) || 0,
          ReceiptLineNumber: line_item.LineNumber,
          PurchaseOrderNumber: line_item.OrderNumber,
          PurchaseOrderLineNumber: line_item.LineNumber,
          PurchaseOrderScheduleLineNumber:
            scheduleLineNumber?.ScheduleNumber || "",
          liabilityType: formState.defaultCoa.libaPre,
          taxClassification:
            `INPUT ${Math.round(perUnitTaxPercentage)}%` || `INPUT 0%`,
          unitCost_bc: conversionRate(
            formState.values.currency,
            baseCurrency,
            currencyLookups,
            parseFloat(scheduleLineNumber?.Price),
            false,
            formState.isPo,
            formState.conversionRate
          ),
          amount_bc: conversionRate(
            formState.values.currency,
            baseCurrency,
            currencyLookups,
            parseFloat(totalAmount),
            false,
            formState.isPo,
            formState.conversionRate
          ),
          amount: totalAmount,
          additionalDetails: "",
        };
      }) || [];
    setAddingItem(false);
    setItems([...itemsFromPurchaseOrder]);
  };

  const _getTaxClassifications = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getTaxClassification`,
      headers: { cooljwt: Token },
    })
      .then(async (response) => {
        setFormState((formState) => ({
          ...formState,
          taxClassifications: response?.data || [],
        }));
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        if (!isVendor && error.response?.status == 401) {
          errorAlert("Issue in Retrival of Tax Classifications ");
        }
      });
  };

  //Add Items from Selected Receipts
  const _addItemsFromReceipts = (receiptNum) => {
    if (formState.isReceipt) {
      let existingItems = items.filter(
        (item) => item.receiptNumber !== receiptNum || item.receiptNumber != ""
      );
      let schedules = selectedPurchaseOrder?.schedules;
      let itemsFromReceipts =
        receipts[receiptNum].map((line_item) => {
          //Quantity form line_item
          //Unit Price from schedule
          //Tax from schedule
          let scheduleLineNumber = schedules.find(
            (s) => s.LineNumber == line_item.POLineNumber
          );
          let poQuantity = scheduleLineNumber?.Quantity || 1;
          let unitPrice =
            scheduleLineNumber?.Price || scheduleLineNumber?.Ordered || "";
          let totalTax = scheduleLineNumber.TotalTax / poQuantity || 0;
          let taxPercentage = (totalTax / unitPrice) * 100;

          let unitTax = null;
          let perUnitTaxPercentage = null;
          let totalAmount = null;
          let quantity = null;

          let isAmountMatching = false;

          if (line_item.Quantity) {
            let receiptQuauntity =
              line_item?.Quantity != null
                ? line_item?.Quantity - line_item?.QuantityBilled || 0
                : null;
            unitTax = (unitPrice * receiptQuauntity * taxPercentage) / 100;
            perUnitTaxPercentage = taxPercentage;
            totalAmount = receiptQuauntity * unitPrice + unitTax;
            quantity = receiptQuauntity;
          } else {
            let receiptAmount =
              line_item?.Amount != null
                ? line_item?.Amount - line_item?.AmountBilled || 0
                : null;
            unitPrice = receiptAmount;
            unitTax = (unitPrice * taxPercentage) / 100;
            perUnitTaxPercentage = taxPercentage;
            totalAmount = unitPrice + unitTax;
            quantity = 1;
            isAmountMatching = true;
          }

          // let poQuantity = scheduleLineNumber?.Quantity || 1;
          // let unitTax = totalTax / poQuantity ;
          // let perUnitTaxPercentage = unitTax / unitPrice * 100 || 0;

          // let quantity = line_item.Quantity || 1;
          // let totalTax = scheduleLineNumber.TotalTax || 0;

          // let calculatedTaxPerUnit = totalTax / quantity  || 0;
          // let perUnitTaxPercentage = calculatedTaxPerUnit / unitPrice * 100 || 0;
          //Amount Calculation after Tax Add
          // let totalAmount =line_item.Quantity == null  ?  receiptQuauntity      : (receiptQuauntity * unitPrice) + (unitTax * line_item.Quantity) || 0;

          let selectedPOAccrualAccount = selectedPurchaseOrder?.distributions
            ? selectedPurchaseOrder.distributions[0]?.POAccrualAccount
            : formState.defaultCoa.expReceipt;
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              taxClassification: line_item?.TaxClassificationCode || "",
            },
          }));
          return {
            itemName: line_item?.ItemDescription || "",
            unitCost: unitPrice || 0,
            quantity: quantity || 1,
            discount: 0,
            poInline: null,
            isAmountMatching,
            // expenseType: formState.defaultCoa.expReceipt,
            expenseType: selectedPOAccrualAccount,
            category: null,
            receiptNumber: receiptNum,
            vat: parseFloat(perUnitTaxPercentage)?.toFixed(2) || 0,
            taxClassification: line_item?.TaxClassificationCode || "",
            ReceiptLineNumber: line_item.LineNumber,
            PurchaseOrderNumber: line_item.PONumber,
            PurchaseOrderLineNumber: line_item.POLineNumber,
            PurchaseOrderScheduleLineNumber:
              scheduleLineNumber?.ScheduleNumber || "",
            liabilityType: formState.defaultCoa.libReceipt,
            unitCost_bc: conversionRate(
              formState.values.currency,
              baseCurrency,
              currencyLookups,
              parseFloat(scheduleLineNumber?.Price),
              false,
              formState.isPo,
              formState.conversionRate
            ),
            amount_bc: conversionRate(
              formState.values.currency,
              baseCurrency,
              currencyLookups,
              parseFloat(totalAmount),
              false,
              formState.isPo,
              formState.conversionRate
            ),
            amount: parseFloat(totalAmount)?.toFixed(2),
            additionalDetails: "",
          };
        }) || [];
      setAddingItem(false);
      setEditIndex(null);
      setItems([...existingItems, ...itemsFromReceipts]);
    }
  };

  //Close Inital Review Modal
  const closeMarkAsReceivedModel = async () => {
    setIsSavingInvoice(false);
    const userData = jwt.decode(Token);
    await setMarkAsReceivedModel(false);
    if (props.loadFiles) {
      await props.loadFiles(userData, false);
    }
  };

  //Remove Attachment
  const removeAttachment = (fileIndex) => {
    let attachments = formState.attachments.filter(
      (a, index) => index !== fileIndex
    );
    setFormState((formState) => ({
      ...formState,
      attachments: attachments,
    }));
  };

  //Remove Item
  const removeItem = (itemIndex) => {
    let item = items.filter((a, index) => index !== itemIndex);
    setItems(item);
    if (formState.isPeetyCash) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          expenseType: "",
        },
      }));
    }
  };

  const removeItems = () => {
    setAddingItem(false);
    if (formState.isPeetyCash) {
      setFormState((formState) => ({
        ...formState,
        errors: {
          ...formState.errors,
          inlineExpenseType: "success",
        },
      }));
    }
  };

  //Handle Item Edit
  const handleEditItem = (item, index) => {
    setEditIndex(index);
    setIsInvoiceAmount(true);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        itemName: item?.itemName,
        unitCost: item?.unitCost,
        quantity: item?.quantity,
        discount: item?.discount,
        poInline: item?.poInline,
        expenseType: item?.expenseType,
        receiptNumber: item?.receiptNumber,
        additionalDetails: item?.additionalDetails,
        taxClassification: item?.taxClassification,
        vat: item?.vat || 0,
        liabilityType: item?.liabilityType,
        isAmountMatching: item?.isAmountMatching,
        category: item?.category,
        ReceiptLineNumber: item?.ReceiptLineNumber,
        PurchaseOrderNumber: item?.PurchaseOrderNumber,
        PurchaseOrderLineNumber: item?.PurchaseOrderLineNumber,
        PurchaseOrderScheduleLineNumber: item?.PurchaseOrderScheduleLineNumber,
        unitCost_bc: item?.unitCost_bc,
        amount_bc: item?.amount_bc,
        amount: item?.amount,
      },
    }));
    setCategory(item.category || 1);
  };

  //Get and save File Details
  const getFileDetails = () => {
    let fileTitle;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;

    if (!Check(selectedFileName)) {
      fileTitle = "success";
    } else {
      fileTitle = "error";
      error = true;
    }
    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        fileTitle: fileTitle,
      },
    }));
    if (error) {
      return false;
    } else {
      Object.entries(selectedFile).map(async ([key, value]) => {
        if (value.type == "image/png" || "image/jpeg" || "application/pdf") {
          let reader = new FileReader();
          reader.onloadend = () => {
            setFormState((formState) => ({
              ...formState,
              attachments: [
                ...formState.attachments,
                {
                  name: value.name,
                  // filename:selectedFileName,
                  base64: reader.result,
                  type: value.type,
                  attachmentTitle: selectedFileName,
                  attachmentPath: "",
                  file: value,
                  new: true,
                  title: formState.values.fileTitle,
                  description: formState.values.fileDescription,
                },
              ],
            }));
            setSelectedFileModel(false);
          };
          reader.readAsDataURL(value);
          setTimeout(() => {
            setFormState((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                fileTitle: "",
                fileDescription: "",
              },
            }));
          }, 500);
        }
      });
      fileInput.current.value = "";
    }
  };

  function hasDoubleExtension(fileName) {
    const parts = fileName.split(".");
    return parts.length > 2;
  }

  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  //Attachment Change
  const handleAttachmentChange = (e) => {
    e.preventDefault();
    let files = e.target.files;
    let file = e.target.files[0];
    if (!allowedTypes.includes(file.type)) {
      msgAlert(
        "Files with extension (.pdf, .jpeg, .png, .docx .xlsx, .pptx) are allowed."
      );
      fileInput.current.value = "";
    } else if (hasDoubleExtension(file.name)) {
      msgAlert("File name is not valid.");
      fileInput.current.value = "";
    } else {
      setSelectedFileName(file?.name);
      setSelectedFile(files);
      setSelectedFileModel(true);
    }
  };
  //Close Attachment Model
  const closeAttachmentModel = () => {
    setSelectedFileModel(false);
    fileInput.current.value = "";
  };

  //OCR
  const handlePdfChange = (e) => {
    e.preventDefault();
    setPdfModal(true);
    let file = e.target.files[0];
    getOCRData(file)
      .then((payload) => {
        //Data from ORC File
        const {
          inv_items,
          discount_type,
          tax_type,
          tax,
          discount,
          invoice_number,
          due_date,
          invoice_date,
        } = payload;

        //Destructuring Line Items
        let destructuredItems = inv_items.map((item) => {
          return {
            itemName: item?.product_item || "",
            unitCost: item?.unit_price || 0,
            quantity: item?.quantity || 0,
            discount: item?.discount || 0,
            poInline: "",
            expenseType: "",
            expenseTypeId: "",
            category: category || "",
            receiptNumber: "",
            vat: item?.vat || "",
            liabilityType: "",
            liabilityTypeId: "",
            unitCost_bc: conversionRate(
              formState.values.currency,
              baseCurrency,
              currencyLookups,
              parseFloat(item?.unit_price || 0),
              true,
              formState.isPo,
              formState.conversionRate
            ),
            amount_bc: conversionRate(
              formState.values.currency,
              baseCurrency,
              currencyLookups,
              parseFloat(item?.total_amount || 0),
              true,
              formState.isPo,
              formState.conversionRate
            ),
            amount: item?.total_amount || 0,
            additionalDetails: item?.product_description || "",
          };
        });
        // let allItems = items.concat(destructuredItems);
        let allItems = destructuredItems;
        //Setting Line Items
        setItems(allItems);
        //Setting Top level Information
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            InvoiceNumber: invoice_number,
            invoiceDate: getDateFormet(invoice_date),
            dueDate: getDateFormet(due_date),
            taxType: tax_type,
            discountType: discount_type,
            overallTax: addZeroes(tax),
            overallDiscount: addZeroes(discount),
          },
        }));
        let reader = new FileReader();
        reader.onloadend = () => {
          setFormState((formState) => ({
            ...formState,
            attachments: [
              ...formState.attachments,
              {
                name: file.name,
                base64: reader.result,
                type: file.type,
                attachmentTitle: "ORIGNAL INVOICE",
                attachmentPath: "",
                file: file,
                title: "ORIGNAL INVOICE",
                description: "OCR FILE USED FOR DATA EXTRACTING..",
              },
            ],
          }));
        };
        reader.readAsDataURL(file);
        setPdfModal(false);
        successAlert("Extracted Data Successfully.");
      })
      .catch((err) => {
        setPdfModal(false);
        errorAlert(JSON.stringify(err));
      });
  };

  const validateVendorSite = () => {
    let selectedVendor;
    let selectSite;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;
    if (formState.selectedVendor) {
      if (!Check(formState.selectedVendor)) {
        selectedVendor = "success";
      } else {
        selectedVendor = "error";
        error = true;
      }
      if (!Check(formState.values.selectSite)) {
        selectSite = "success";
      } else {
        selectSite = "error";
        error = true;
      }
      setFormState((formState) => ({
        ...formState,
        errors: {
          ...formState.errors,
          selectedVendor: selectedVendor,
          selectSite: selectSite,
          autoCompleteOpen: false,
        },
      }));
      if (error) {
        return;
      } else {
        setVendorModal(false);
      }
    } else {
      setVendorModal(false);
    }
  };

  const selectVendor = () => {
    let selectedVendor;
    let selectSite;
    let organizationId;
    setFormState((formState) => ({
      ...formState,
      selectedPurchaseOrder: null,
    }));
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;
    if (!isVendor) {
      if (!Check(formState.selectedVendor)) {
        selectedVendor = "success";
      } else {
        selectedVendor = "error";
        error = true;
      }
      if (!Check(formState.values.selectSite)) {
        selectSite = "success";
      } else {
        selectSite = "error";
        error = true;
      }
      setFormState((formState) => ({
        ...formState,
        errors: {
          ...formState.errors,
          selectedVendor: selectedVendor,
          selectSite: selectSite,
          autoCompleteOpen: false,
        },
      }));
    } else {
      if (!Check(formState.values.organizationId)) {
        organizationId = "success";
      } else {
        organizationId = "error";
        error = true;
      }
    }
    if (error) {
      return false;
    } else {
      let selectedSite = formState.selectedVendor?.level1.supplierSites.find(
        (site) => site.supplierSiteId == formState.values.selectedSite
      );
      setFormState((formState) => ({
        ...formState,
        selectedSite: selectedSite,
        // values: {
        //   ...formState.values,
        //   supplierInvId: `INV-${formState.selectedVendor?.invoiceCount}`
        // }
      }));
      if (!oracleConRate) {
        _getOrganzationCurrencies();
      }
      // getPurchaseOrders();
      if (!edit && !oracleConRate) {
        setBaseCurrency(
          !isVendor
            ? userDetails?.currency?.Currency_Base || ""
            : formState.selectedOrg.currency
        );
      }
      setVendorModal(false);
      if (isVendor) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            currency: formState.selectedOrg.currency,
          },
        }));
      }
    }
  };

  //Update Total base on Diffrent Conditions
  const updateTotal = () => {
    let grossAmt = items.reduce(function(sum, current) {
      return sum + parseFloat(current.unitCost * current.quantity);
    }, 0);

    //For Discount and Tax

    let totalAmt = parseFloat(
      items.reduce(function(sum, current) {
        return sum + parseFloat(current.amount);
      }, 0)
    );
    //  +
    // parseFloat(
    //   formState.values.taxType === 1
    //     ? formState.values.overallTax
    //     : (grossAmt * formState.values.overallTax) / 100
    // ) -
    // parseFloat(
    //   formState.values.discountType === 1
    //     ? formState.values.overallDiscount
    //     : (grossAmt * formState.values.overallDiscount) / 100
    // );

    //For Advance Tax and Pra

    // let pra = parseFloat(
    //   formState.values.praType == 1
    //     ? formState.values.pra
    //     : (grossAmt * formState.values.pra) / 100
    // );

    // let afterPra = grossAmt + pra;

    // let advanceTax = parseFloat(
    //   formState.values.advanceTaxType == 1
    //     ? formState.values.advanceTax
    //     : (afterPra * formState.values.advanceTax) / 100
    // );

    // let totalAmt = afterPra - advanceTax;

    let overallTax =
      totalAmt - grossAmt > 0 ? addZeroes(totalAmt - grossAmt) : 0 || 0;
    if (edit && !isInvoiceAmount) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          invoiceamount: addZeroes(totalAmt),
        },
      }));
    }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        total: parseFloat(totalAmt)?.toFixed(2),
        subtotal: parseFloat(grossAmt)?.toFixed(2),
        overallTax: overallTax,
      },
    }));
  };
  const _getOraConversionsRates = async (res) => {
    let currency = res?.Code;
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/currencyValue`,
      data: {
        date: formState.values.invoiceDate,
        currencyValue: currency,
      },
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if (typeof response?.data == "object") {
          setOracleConRate(true);
          if (response?.data?.items?.length == 0) {
            msgAlert(
              "Conversion Rate not found for selected currency against invoice date"
            );
          } else {
            setOracleConRate(false);
            let filterData = response?.data?.items?.map((con) => {
              return con?.ConversionRate;
            });
            setFormState((formState) => ({
              ...formState,
              conversionRate: filterData[0] || 1,
            }));
            setOrcConversionRate(filterData[0] || 1);
          }
        } else {
          setOracleConRate(true);
          errorAlert(
            "There is an issue in getting Conversion Rate from fusion"
          );
        }
      })
      .catch((error) => {
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  //Update Currency
  const updateCurrency = () => {
    const choosedCurrency =
      currencyLookups.find((l) => l._id == formState.values.currency) ||
      defaultCurrency;
    setCurrency(choosedCurrency);
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        paymentcurrency: choosedCurrency?.Code,
      },
    }));
    _getOraConversionsRates(choosedCurrency);
    if (!edit) {
      const UItems = items.map((i) => {
        let j = {
          ...i,
          amount_bc: conversionRate(
            formState.values.currency,
            baseCurrency,
            currencyLookups,
            parseFloat(i.amount),
            false,
            formState.isPo,
            formState.conversionRate
          ),
          unitCost_bc: conversionRate(
            formState.values.currency,
            baseCurrency,
            currencyLookups,
            parseFloat(i.unitCost),
            false,
            formState.isPo,
            formState.conversionRate
          ),
          discountAmt_bc: conversionRate(
            formState.values.currency,
            baseCurrency,
            currencyLookups,
            parseFloat(i.discount),
            false,
            formState.isPo,
            formState.conversionRate
          ),
        };
        return j;
      });
      setItems(UItems);
    }
  };

  //Chart of Account Handler
  const COAHandler = () => {
    //Use case #1 (if  Prepayment)
    if (formState.isPrePayment) {
      //Inner Case #1 (Against PO)
      if (formState.isPo) {
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            expenseType: formState.defaultCoa.expPre,
            liabilityType: formState.defaultCoa.libaPre,
          },
        }));
      }
      //Inner Case #2 (Against Expense)
      if (formState.isExpense) {
        // Liability Line Item will be a default account
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            expenseType: formState.defaultCoa.expPre,
            liabilityType: formState.defaultCoa.libaPre,
          },
        }));
      }
    }

    //Use case #3 (if  With Receipt)
    if (formState.isReceipt) {
      if (!edit) {
        setItems([]);
      }
      let selectedPOAccrualAccount = selectedPurchaseOrder?.distributions
        ? selectedPurchaseOrder.distributions[0]?.POAccrualAccount
        : formState.defaultCoa.expReceipt;

      //Inner Case #1(Against Expense)
      if (formState.isPo) {
        // Liability Line Item will be a default account
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            // expenseType: selectedPOAccrualAccount,
            expenseType: "",
            liabilityType: formState.defaultCoa.libReceipt,
          },
        }));
      }
    }

    //Use case #3 (if  Petty Cash)
    if (formState.isPeetyCash) {
      //Inner Case #1(Against Expense)
      if (formState.isExpense) {
        // Liability Line Item will be a default account
        setFormState((formState) => ({
          ...formState,
          values: {
            ...formState.values,
            expenseType: formState.defaultCoa.expPetty,
            liabilityType: formState.defaultCoa.libPetty,
          },
        }));
      }
    }
  };

  const _getSuppliersOROrganizations = async (is_Ar) => {
    const userDetails = jwt.decode(Token);
    if (!isVendor) {
      if (formState.supplierInputValue !== "") {
        //If User from Org
        // const userCurrency = userDetails?.currency?.Currency_Base || "";
        await axios({
          method: "get",
          url: is_Ar
            ? `${process.env.REACT_APP_LDOCS_API_URL}/AR/clientByOrganization/${userDetails?.orgDetail?.organizationId}`
            : `${
                process.env.REACT_APP_LDOCS_API_URL
              }/vendor/searchVendors?params=${
                formState.supplierInputValue
              }&forInvoice=${true}`,
          headers: { cooljwt: Token },
        })
          .then(async ({ data }) => {
            if (edit) {
              setInvoice(null, data?.result || []);
            }
            if (!formState.changedsupplierInputValue) {
              let receivedVendors = data?.result;
              let selectedVendor = receivedVendors.find(
                (vendor) =>
                  vendor.level1.Supplier ==
                  formState.selectedPurchaseOrder.Supplier
              );
              let selectedSite = selectedVendor.level1.supplierSites?.find(
                (site) =>
                  site.SupplierSiteId ==
                  formState.selectedPurchaseOrder.SupplierSiteId
              );
              setFormState((formState) => ({
                ...formState,
                selectedVendor: selectedVendor,
                selectedSite: selectedSite,
                vendors: data?.result || [],
                values: {
                  ...formState.values,
                  site: "",
                  // currency: userCurrency,
                },
              }));
            } else {
              setFormState((formState) => ({
                ...formState,
                vendors: data?.result || [],
                values: {
                  ...formState.values,
                  site: "",
                  // currency: userCurrency,
                },
              }));
            }
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              error.response.status == 401 && dispatch(setIsTokenExpired(true));
            }
            // if (!edit) {
            //   errorAlert("Issue in Retrival of Suppliers / Customers ");
            // }
          });
      }
    } else {
      //If Vendor Creating Invoice by Own
      await axios({
        method: "get", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/organizationByVender`,
        headers: {
          cooljwt: Token,
        },
      })
        .then((response) => {
          let orgs = response.data.organizations;
          let defaultOrg = orgs.find(
            (org) => org.organizationName == "SALIC BU"
          );
          setFormState((formState) => ({
            ...formState,
            organizations: orgs,
            selectedOrg: defaultOrg,
            values: {
              ...formState.values,
              organizationId: defaultOrg?._id,
            },
          }));
          setBaseCurrency(defaultOrg.currency);
          setInvoice(orgs, null);
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
            localStorage.clear();
            history.push("/supplier/login");
          }
          if (!isVendor && error.response.status == 401) {
            errorAlert("Unable to get Organizations");
          }
        });
    }
  };

  const _getInvoiceCount = async () => {
    if (!edit) {
      let organizationId = isVendor
        ? formState.selectedOrg?.organizationId
        : userDetails.orgDetail.organizationId;
      // let organizationId = isVendor ? formState.organizations.find(org=>org.organizationName == "SALIC BU")?._id : userDetails.orgDetail.organizationId;
      await axios({
        method: "get", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/vendor/getInvoiceCount/${organizationId}`,
        headers: {
          cooljwt: Token,
        },
      })
        .then((response) => {
          //Settign Invoice Count
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              supplierInvId: `INV-${response?.data?.result?.invoiceCount}`,
            },
          }));
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          // errorAlert("Unable to get Invoice Count of Supplier");
        });
    }
  };

  const _getCOA = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getAccounts/null`,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setFormState((formState) => ({
          ...formState,
          coa: {
            ...formState.coa,
            Company:
              response.data.find((c) => c._id == "Company")?.records || [],
            Department_CostCenter:
              response.data.find((d) => d._id == "Department_CostCenter")
                ?.records || [],
            Account1:
              response.data.find((a) => a._id == "Account1")?.records || [],
            Subsidiary:
              response.data.find((s) => s._id == "Subsidiary")?.records || [],
            Spare1: response.data.find((s) => s._id == "Spare1")?.records || [],
            Spare2: response.data.find((s) => s._id == "Spare2")?.records || [],
          },
        }));
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const changePaymentterms = () => {
    if (formState?.isPrePayment && !edit) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          paymentTerms: "Advance Payment",
        },
      }));
    } else if (formState?.isReceipt) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          paymentTerms: purchaseOrders[0]?.PaymentTerms || "",
        },
      }));
    }
  };

  React.useEffect(() => {
    if (
      !edit &&
      !isVendor &&
      formState?.isPrePayment &&
      formState?.isPo &&
      selectedPurchaseOrder == null
    ) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          currency: userDetails?.currency?.Currency_Base,
          paymentcurrency: "SAR",
        },
      }));
      updateCurrency();
    }
    if (
      !edit &&
      formState?.isPrePayment &&
      selectedPurchaseOrder == null &&
      isVendor
    ) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          currency: formState?.selectedOrg?.currency,
          paymentcurrency: "SAR",
        },
      }));
      updateCurrency();
    }
    updateCurrency();
  }, [
    formState.values.currency,
    currencyLookups,
    formState.conversionRate,
    formState.selectedOrg,
  ]);

  React.useEffect(() => {
    updateTotal();
  }, [
    items,
    formState.values.overallDiscount,
    formState.values.overallTax,
    formState.values.taxType,
    formState.values.discountType,
    formState.values.advanceTax,
    formState.values.advanceTaxType,
    formState.values.pra,
    formState.values.praType,
  ]);

  React.useEffect(() => {
    COAHandler();
    changePaymentterms();
  }, [
    formState.isPo,
    formState.isExpense,
    formState.isPrePayment,
    formState.isPeetyCash,
    formState.values.isReceipt,
    selectedPurchaseOrder,
  ]);

  useEffect(() => {
    _getInvoiceCount();
    _getOrganzationCurrencies();
    _getCOADefaultValues();
  }, [formState.selectedVendor, formState.selectedOrg]);

  //On Load Component
  useEffect(() => {
    if (isVendor) {
      _getPurchaseOrders(userDetails.SupplierId);
    }
    //Load Initial Data
    setIsLoading(true);
    if (edit) {
      setFormState((formState) => ({
        ...formState,
        supplierInputValue: fileData?.vendorName?.substring(0, 3) || "",
        poInputValue: fileData?.po,
      }));
    }
    window.scrollTo(0, 0);
    _getCOA();
    _getCOADefaultValues();
    _getTaxClassifications();
    _getPaymentTerms();
  }, [isAr]);

  //Handling AutoComplete
  useEffect(() => {
    if (formState.autoCompleteOpen) {
      _getSuppliersOROrganizations(isAr);
    }
  }, [formState.supplierInputValue]);
  useEffect(() => {
    if (formState.poInputValue) {
      _getPurchaseOrders();
    }
  }, [formState.poInputValue]);

  //Check it
  const setInvoice = async (orgs, vendors) => {
    if (edit) {
      //Selected Purchase Order
      const selectedPO =
        purchaseOrders.find((po) => po.OrderNumber == fileData.po) || null;
      // _getPurchaseOrders(null);
      orgs = orgs ? orgs : formState.organizations;
      vendors = vendors ? vendors : formState.vendors;
      let org = orgs.find((o) => o.organizationId == fileData.organizationId);
      let client = isVendor
        ? null
        : vendors.find((v) => v._id == fileData.clientId);
      let vendor = isVendor
        ? null
        : vendors.find((v) => v._id == fileData.vendorId);
      // if (!vendor) {
      //   errorAlert("No Vendor Found.");
      //   closeModal();

      // }

      let selectedSite = vendor
        ? vendor?.level1?.supplierSites?.find(
            (s) => s.SupplierSite == fileData?.vendorSite
          )
        : null;
      // await _getOrganzationCurrencies();
      // setIsInvoiceAmount(false)
      setFormState((formState) => ({
        ...formState,
        selectedVendor: isAr ? client : vendor,
        selectedSite: selectedSite,
        isPo: fileData.isPo,
        isReceipt: fileData.isReceipt,
        isPeetyCash: fileData.isPettyCash,
        isPrePayment: fileData.isPrePayment,
        isExpense: !fileData.isPo,
        values: {
          ...formState.values,
          InvoiceNumber: fileData.originalInvoiceId,
          // invoiceamount:addZeroes(formState?.values.total),
          supplierInvId: fileData.invoiceId,
          site: isAr ? fileData.clientSite : fileData.vendorSite,
          invoiceDate: getDateFormet(fileData.invoiceDate),
          dueDate: getDateFormet(fileData.dueDate),
          glDate: getDateFormet(fileData.glDate),
          notes: fileData.description,
          taxType: 1,
          overallTax: fileData.taxAmt,
          isExpense: fileData.isExpense,
          discountType: 1,
          overallDiscount: fileData.discountAmt,
          organizationId: fileData.organizationId,
          paymentTerms: fileData.paymentTerms,
          poNumber: fileData.po,
          selectedVendor: isAr ? client : vendor,
          expenseType: fileData.expenseTypeId,
          currency: fileData.FC_currency._id || "",
          paymentcurrency: fileData.paymentcurrency || "",
          selectSite: fileData.vendorSite,
          pra: fileData.praTax,
          advanceTax: fileData.advanceTax,
          requesterId: fileData.requesterId,
        },
        selectedOrg: isVendor ? org || null : null,
        conversionRate: fileData.conversionRate || 0,
      }));

      var invoice_items = fileData.items.map((item) => {
        const i = {
          additionalDetails: item.additionalDetails,
          amount: item.amount,
          amount_bc: item.amount_bc,
          discount: item.discount,
          discount_bc: item.discount_bc,
          vat: item.vat,
          vat_bc: item.vat_bc,
          itemName: item.itemName,
          quantity: item.quantity,
          unitCost: item.unitCost,
          unitCost_bc: item.unitCost_bc,
          receiptNumber: item.receiptNumber,
          poInline: item.poInline,
          taxClassification: item.taxClassification,
          expenseType: item.expenseType,
          liabilityType: item.liabilityType,
          receiptNumber: item?.receiptNumber,
          additionalDetails: item?.additionalDetails,
          vat: item?.vat || 0,
          isAmountMatching: item?.isAmountMatching,
          category: item?.category,
          ReceiptLineNumber: item?.ReceiptLineNumber,
          PurchaseOrderNumber: item?.PurchaseOrderNumber,
          PurchaseOrderLineNumber: item?.PurchaseOrderLineNumber,
          PurchaseOrderScheduleLineNumber:
            item?.PurchaseOrderScheduleLineNumber,
        };
        return i;
      });
      setItems(invoice_items);
      var invoice_attachments = fileData.attachments.map((att) => {
        let backendPath = process.env.REACT_APP_LDOCS_API_URL;
        let filterAttachmentPath = att?.attachmentPath.includes(backendPath)
          ? att?.attachmentPath.split(`${backendPath}/`).pop()
          : att?.attachmentPath;
        const a = {
          name: att.name,
          base64: `${filterAttachmentPath}`,
          type: att.attachmentPath.split(".").pop(),
          attachmentTitle: att.attachmentTitle,
          attachmentPath: `${filterAttachmentPath}`,
          new: false,
          file: null,
          title: att.fileTitle,
          description: att.fileDescription,
          vat: att.vat,
        };
        return a;
      });

      setFormState((formState) => ({
        ...formState,
        attachments: invoice_attachments,
      }));
      setBaseCurrency(fileData.LC_currency._id);
      // setCurrency(currencyLookups.find(c=>c._id == fileData.FC_currency._id ));
      // getPurchaseOrders(vendor.level1?.SupplierId);
      // _getPurchaseOrders(null);

      setIsCreateInvoice(true);
    }
  };

  const _getConversionsRates = async (res) => {
    let currency = res?.Po?.CurrencyCode;
    setFormState((formState) => ({
      ...formState,
      loadingPurchaseOrder: true,
    }));
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/currencyValue`,
      data: {
        date: formState.values.invoiceDate,
        currencyValue: currency,
      },
      headers: { cooljwt: Token },
    })
      .then((response) => {
        setOracleConRate(true);
        if (response?.data?.items?.length == 0 && !edit) {
          msgAlert("Conversion Rate not found");
        } else {
          setOracleConRate(false);
          let filterData = response?.data?.items?.map((con) => {
            return con?.ConversionRate;
          });
          setFormState((formState) => ({
            ...formState,
            conversionRate: filterData[0] || 0,
          }));
          setOrcConversionRate(filterData[0] || 0);
        }
      })
      .catch((error) => {
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
      });
  };

  const _getPurchaseOrderDetails = async (id, load) => {
    //id == PO header ID
    let url = `${process.env.REACT_APP_LDOCS_API_URL}/invoice/getPODetails/${id}`;
    setFormState((formState) => ({
      ...formState,
      loadingPurchaseOrder: true,
    }));
    await axios({
      method: "get",
      url: url,
      headers: { cooljwt: Token },
    })
      .then((response) => {
        if (typeof response.data == "object") {
          setOracleConRate(false);
          _getConversionsRates(response.data);
          setSelectedPurchaseOrder(response.data);
          if (formState.isPo && load && !formState.isReceipt) {
            _addItemsFromPurchaseOrder(response.data);
          }
          // let distributions = response.data?.distributions
          // let conversionRate =
          //   distributions.length > 0 ? distributions[0].ConversionRate || 1 : 1
          let selectedCurrency = currencyLookups.find(
            (c) =>
              c.Code.toUpperCase() ===
              response.data.Po.CurrencyCode.toUpperCase()
          );
          if (load) {
            setFormState((formState) => ({
              ...formState,
              loadingPurchaseOrder: false,
              values: {
                ...formState.values,
                currency: selectedCurrency?._id || "",
                paymentTerms: formState.isPrePayment
                  ? response.data.Po.PaymentTerms
                  : "Advance Payment" || "",
              },
            }));
          } else {
            setFormState((formState) => ({
              ...formState,
              loadingPurchaseOrder: false,
              currency: selectedCurrency?._id || "",
              // conversionRate: orcConversionRate[0]
            }));
          }
        } else {
          setSelectedPurchaseOrder(null);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true));
        }
        console.log(
          typeof error.response != "undefined"
            ? error.response.data
            : error.message
        );
        setSelectedPurchaseOrder(null);
        setFormState((formState) => ({
          ...formState,
          loadingPurchaseOrder: false,
        }));
      });
  };

  const viewPurchaseOrder = async () => {
    let selectedPo = purchaseOrders.find(
      (po) => po.OrderNumber === formState.values.poNumber
    );
    // await _getPurchaseOrderDetails(selectedPo?.POHeaderId);
    selectedPurchaseOrder && setPurchaseOrderModal(true);
  };

  const openVendorModal = () => {
    //Clearing State
    // setFormState((formState) => ({
    //   ...formState,
    //   selectedVendor: "",
    //   selectedOrg: "",
    //   values: {
    //     ...formState.values,
    //     selectedVendor: "",
    //     site: "",
    //     organizationId: "",
    //   },
    // }));
    setVendorModal(true);
  };
  const closePdfModal = () => {
    setPdfModal(false);
  };
  const handleVendorChange = (event) => {
    event.persist();
    if (isVendor) {
      let selectedOrg = formState.organizations.find(
        (o) => o.organizationId == event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        selectedOrg: selectedOrg,
        values: {
          ...formState.values,
          organizationId: event.target.value,
          currency: selectedOrg.currency,
        },
      }));
    } else {
      let selectedVendor = formState.vendors.find(
        (v) => v._id == event.target.value
      );
      setFormState((formState) => ({
        ...formState,
        selectedVendor: event.target.value,
        values: {
          ...formState.values,
          selectedVendor: selectedVendor,
        },
      }));
    }
  };
  const viewFileHandler = (file) => {
    setIsCreateInvoice(false);
    setFile(file);
    setViewFile(true);
  };
  const closeViewFile = () => {
    setViewFile(false);
    setFile(null);
    setIsCreateInvoice(true);
  };
  const addInvoiceItem = () => {
    setEditIndex(null);
    setIsInvoiceAmount(true);

    //Clearing State for petty Cash
    if (formState.isPeetyCash) {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          taxClassification: "",
          vat: 0,
          itemName: "",
          unitCost: 0,
          quantity: 0,
          receiptNumber: "",
          poInline: "",
          discount: 0,
          amount: 0,
          expenseType: "100-0-0-000-000000-000000",
          additionalDetails: "",
        },
        errors: {
          ...formState.errors,
          itemName: "",
          unitCost: "",
          quantity: "",
          discount: "",
          amount: "",
          receiptNumber: "",
          poInline: "",
          additionalDetails: "",
        },
      }));
    }
    //Clearing State
    else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          taxClassification: "",
          vat: 0,
          itemName: "",
          unitCost: 0,
          quantity: 0,
          receiptNumber: "",
          poInline: "",
          discount: 0,
          amount: 0,
          additionalDetails: "",
        },
        errors: {
          ...formState.errors,
          itemName: "",
          unitCost: "",
          quantity: "",
          discount: "",
          amount: "",
          receiptNumber: "",
          poInline: "",
          additionalDetails: "",
        },
      }));
    }
    //Open Inline Model ...
    setAddingItem(true);
  };
  const addItem = async () => {
    setIsInvoiceAmount(true);
    //Adding Item to Invoice
    let itemName;
    let unitCost;
    let quantity;
    let discount;
    let taxClassification;
    let liabilityType;
    let vat;
    let additionalDetails;
    let expenseType;
    let receiptNumber;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;

    if (!Check(formState.values.itemName)) {
      itemName = "success";
    } else {
      itemName = "error";
      error = true;
    }
    if (!Check(formState.values.taxClassification)) {
      taxClassification = "success";
    } else {
      taxClassification = "error";
      error = true;
    }
    if (!Check(formState.values.unitCost)) {
      unitCost = "success";
    } else {
      unitCost = "error";
      error = true;
    }
    if (!Check(formState.values.quantity)) {
      quantity = "success";
    } else {
      quantity = "error";
      error = true;
    }
    if (!Check(formState.values.expenseType)) {
      expenseType = "success";
    } else {
      expenseType = "error";
      error = true;
    }

    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        taxClassification: taxClassification,
        itemName: itemName,
        unitCost: unitCost,
        quantity: quantity,
        discount: discount,
        inlineExpenseType: expenseType,
      },
    }));
    if (error) {
      return false;
    }
    if (!error) {
      setValidCoaLoading(true);
      await axios({
        method: "post", //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/validateCoa`,
        data: { accountString: formState.values.expenseType },
        headers: {
          cooljwt: Token,
        },
      })
        .then((res) => {
          setValidCoaLoading(false);
          const amount =
            parseFloat(formState.values.unitCost) *
              parseFloat(formState.values.quantity) +
            parseFloat(
              (formState.values.unitCost * formState.values.vat) / 100
            ) *
              formState.values.quantity;
          const item = {
            itemName: formState.values.itemName,
            unitCost: formState.values.unitCost,
            quantity: formState.values.quantity,
            discount: formState.values.discount,
            expenseType: formState.values.expenseType,
            receiptNumber: formState.values.receiptNumber,
            vat: parseFloat(formState.values.vat)?.toFixed(2),
            liabilityType: formState.values.liabilityType,
            taxClassification: formState.values.taxClassification,
            unitCost_bc: conversionRate(
              formState.values.currency,
              baseCurrency,
              currencyLookups,
              parseFloat(formState.values.unitCost),
              true,
              formState.isPo,
              formState.conversionRate
            ),
            amount_bc: conversionRate(
              formState.values.currency,
              baseCurrency,
              currencyLookups,
              parseFloat(amount),
              true,
              formState.isPo,
              formState.conversionRate
            ),
            amount: parseFloat(amount)?.toFixed(2),
            additionalDetails: formState.values.additionalDetails,
          };
          //Editing
          if (editIndex !== null) {
            let newItems = items;
            newItems[editIndex] = { ...items[editIndex], ...item };
            setItems(newItems);
            setEditIndex(null);
            updateTotal();
          } else {
            setItems([...items, item]);
            setAddingItem(false);
          }
        })
        .catch((error) => {
          setValidCoaLoading(false);
          setExpenseValidation(true);
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          errorAlert(error.response.data || "GL Account is invalid");
        });
    }
  };
  const closeItembox = () => {
    setEditIndex(null);
    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        itemName: "",
        unitCost: "",
        quantity: "",
        discount: "",
        inlineExpenseType: "success",
      },
    }));
  };
  const openPopup = (check) => {
    let invoiceDate;
    let InvoiceNumber;
    let Invoiceamount;
    let dueDate;
    let gldate;
    let vendor;
    let item;
    let taxClassification;
    let attachments;
    let currency;
    let poNumber;
    let receiptNumber;
    let paymentTerms;
    let notes;
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;

    if (!Check(formState.values.invoiceDate)) {
      invoiceDate = "success";
    } else {
      invoiceDate = "error";
      error = true;
    }
    if (!Check(formState.values.InvoiceNumber)) {
      InvoiceNumber = "success";
    } else {
      InvoiceNumber = "error";
      error = true;
    }

    if (
      isVendor
        ? (Invoiceamount = "success")
        : !Check(formState.values.invoiceamount)
    ) {
      Invoiceamount = "success";
    } else {
      Invoiceamount = "error";
      error = true;
    }
    if (
      !isVendor &&
      !edit &&
      formState.values.invoiceamount !== addZeroes(formState.values.total)
    ) {
      Invoiceamount = "error";
      error = true;
    } else {
      Invoiceamount = "success";
    }
    if (
      !isVendor &&
      edit &&
      formState.values.invoiceamount !== addZeroes(formState.values.total)
    ) {
      Invoiceamount = "error";
      error = true;
    } else {
      Invoiceamount = "success";
    }
    if (!Check(formState.values.dueDate)) {
      dueDate = "success";
    } else {
      dueDate = "error";
      error = true;
    }
    if (isVendor ? (gldate = "success") : !Check(formState.values.glDate)) {
      gldate = "success";
    } else {
      gldate = "error";
      error = true;
    }
    if (!Check(formState.values.notes)) {
      notes = "success";
    } else {
      notes = "error";
      error = true;
    }
    if (!isVendor && formState.isPo && formState.isReceipt) {
      if (items.filter((i) => i.receiptNumber.length < 1).length > 0) {
        item = "error";
        error = true;
      } else {
        item = "success";
      }
    }
    if (
      edit
        ? (taxClassification = "success")
        : !Check(formState.values.taxClassification)
    ) {
      taxClassification = "success";
    } else {
      taxClassification = "error";
      error = true;
    }
    // if (taxClassification == 'success' && items.filter(i => i.vat > 0 && i.taxClassification == '').length > 0) {
    //   taxClassification = 'error'
    //   error = true
    // } else {
    //   taxClassification = 'success'
    // }

    if (formState.attachments.length == 0) {
      attachments = "error";
      error = true;
    } else {
      attachments = "success";
    }
    if (isVendor) {
      if (!Check(formState.values.organizationId)) {
        vendor = "success";
      } else {
        vendor = "error";
        error = true;
      }
    } else {
      if (!Check(formState.selectedVendor)) {
        vendor = "success";
      } else {
        vendor = "error";
        error = true;
      }
    }
    if (!Check(formState.values.currency)) {
      currency = "success";
    } else {
      currency = "error";
      error = true;
    }

    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        invoiceDate: invoiceDate,
        InvoiceNumber: InvoiceNumber,
        invoiceamount: Invoiceamount,
        dueDate: dueDate,
        glDate: gldate,
        vendor: vendor,
        items: item,
        notes: notes,
        taxClassification: taxClassification,
        // receiptNumber: receiptNumber,
        poNumber: poNumber,
        currency: currency,
        paymentTerms: paymentTerms,
      },
    }));
    if (error) {
      if (item == "error") {
        errorAlert("Something is missing in line items");
      }
      if (
        !isVendor &&
        formState.values.invoiceamount !== addZeroes(formState.values.total)
      ) {
        msgAlert("Invoice amount not matched with Total amount.");
      }
      // else if (!isVendor && taxClassification == 'error') {
      //   errorAlert('Tax Classification is missing in line items.')
      else if (taxClassification == "error") {
        errorAlert("Tax Classification is missing in line items.");
      } else if (attachments == "error") {
        errorAlert("Attachments are mendatory.");
      } else {
        msgAlert("Missing Required Fields");
      }
      setIsSavingInvoice(false);
      return false;
    }
    if (oracleConRate == true) {
      errorAlert(
        "Conversion Rate not found for selected currency against invoice date"
      );
    } else if (isVendor && selectedPurchaseOrder === null) {
      msgAlert("Please Select Purchase Order");
    } else {
      if (
        edit &&
        !isVendor &&
        !isAr &&
        fileData?.createdByVendor &&
        fileData.trackingStatus?.current_status == "initialReview"
      ) {
        setMarkAsReceivedModel(true);
      }
      if (check == "draft") {
        createDraftInvoice();
      } else {
        createInvoice();
      }
    }
  };

  const createInvoice = () => {
    return new Promise((res, rej) => {
      if (formState.isPo && formState.selectedPurchaseOrder === null) {
        errorAlert("Please select a purchase order before creating invoice");
        return;
      }
      //Creating Invoice
      if (isDraft) {
        setIsDraftInvoice(true);
      } else {
        setIsSavingInvoice(true);
      }
      const isEdit = props.editHandler == 1 ? true : false;
      const userData = jwt.decode(Token);
      let userCurrency;
      if (isVendor && formState.selectedOrg) {
        userCurrency = currencyLookups.find(
          (l) => l._id == formState.selectedOrg.currency
        );
      } else if (userData.currency && !isVendor) {
        userCurrency = currencyLookups.find(
          (l) => l._id == userData?.currency?.Currency_Base
        );
      } else {
        userCurrency = defaultCurrency;
      }
      let pra = parseFloat(
        formState.values.praType == 1
          ? formState.values.pra
          : (formState.values.subtotal * formState.values.pra) / 100
      );

      let afterPra = formState.values.subtotal + pra;

      let advanceTax = parseFloat(
        formState.values.advanceTaxType == 1
          ? formState.values.advanceTax
          : (afterPra * formState.values.advanceTax) / 100
      );

      let taxPercent =
        formState.values.taxType === 1
          ? (formState.values.overallTax * 100) / formState.values.subtotal
          : formState.values.overallTax;
      let discountPercent =
        formState.values.discountType === 1
          ? (formState.values.overallDiscount * 100) / formState.values.subtotal
          : formState.values.overallDiscount;
      let taxAmt =
        formState.values.taxType === 2
          ? (formState.values.subtotal * formState.values.overallTax) / 100
          : formState.values.overallTax;
      let discountAmt =
        formState.values.discountType === 2
          ? (formState.values.subtotal * formState.values.overallDiscount) / 100
          : formState.values.overallDiscount;
      let formData = {
        mongoID: edit ? fileData?._id : "",
        invoiceId: formState.values.supplierInvId,
        originalInvoiceId: formState.values.InvoiceNumber.toUpperCase(),
        invoiceDate: formState.values.invoiceDate,
        dueDate: formState.values.dueDate,
        glDate: isVendor
          ? formState.values.invoiceDate
          : formState.values.glDate,
        paymentcurrency: formState.values.paymentcurrency,
        grossAmt: formState.values.subtotal,
        discountPercent: discountPercent,
        discountAmt: discountAmt,
        taxAmt: taxAmt,
        taxPercent: taxPercent,
        invoice_details: formState.values.notes,
        netAmt: formState.values.total,
        ref: formState.values.poNumber,
        tenantId: isVendor ? formState.selectedOrg.tenantId : userData.tenantId,
        organizationId: isVendor
          ? formState.selectedOrg.organizationId
          : userData.orgDetail.organizationId,
        organizationName: isVendor
          ? formState.selectedOrg.organizationName
          : userData.orgDetail.organization,
        contactPerson: isVendor ? null : "",
        createdBy:
          edit && isVendor
            ? userData.email
            : edit
            ? fileData.createdBy
            : userData.email,
        balanceDue: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(formState.values.total),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        items: items,
        attachments: formState.attachments,
        vendorName: isAr
          ? null
          : isVendor
          ? userData.name
          : formState.selectedVendor?.level1?.Supplier || "",
        vendorId: isAr
          ? null
          : isVendor
          ? userData.id
          : formState.selectedVendor._id,
        vendorSite: !isVendor
          ? formState.selectedSite?.SupplierSite
          : selectedPurchaseOrder?.Po.SupplierSite || "",
        clientName: isAr
          ? formState.values.selectedVendor?.level1.clientName
          : null,
        clientId: isAr ? formState.values.selectedVendor._id : null,
        clientSite: formState.values.site,
        version: fileData ? fileData.version : "",
        isAR: isAr,
        invoicePath: fileData ? fileData.invoicePath : "",
        FC_currency: currencyLookups.find(
          (l) => l._id == formState.values.currency
        ),

        // PO_CurrencyCode: selectedPurchaseOrder?.distributions[0]?.CurrencyCode,
        PO_CurrencyCode: currencyLookups.find(
          (l) => l._id == formState.values.currency
        ).Code,
        LC_currency: userCurrency,
        // conversionRate: currencyLookups.find(
        //   l => l._id == formState.values.currency
        // ).conversionRate,
        conversionRate: orcConversionRate,
        PO_ConversionRate: currencyLookups.find(
          (l) => l._id == formState.values.currency
        )?.conversionRate,
        ConversionRateType: selectedPurchaseOrder?.Po?.ConversionRateType,
        ConversionDate: formState.values.invoiceDate,
        description: formState.values.notes,
        createdByVendor: edit
          ? fileData.createdByVendor
          : isVendor
          ? true
          : false,
        po: formState?.selectedPurchaseOrder?.OrderNumber || "",
        // receiptNumber: formState.values.receiptNumber,
        paymentTerms: formState.values.paymentTerms,
        isPo: formState.isPo,
        isReceipt: formState.isReceipt,
        requesterId: userData.email,
        isPettyCash: formState.isPeetyCash,
        isPrePayment: formState.isPrePayment,
        createdDate: new Date(),
        grossAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(formState.values.subtotal),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        discountAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(discountAmt),
          true,
          edit,
          formState.conversionRate
        ),
        taxAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(taxAmt),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        netAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(formState.values.total),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        advanceTax: advanceTax,
        advanceTaxType: formState.values.advanceTaxType,
        praTax: pra,
        praTaxType: formState.values.praType,
        advanceTax_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(advanceTax),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        praTax_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(pra),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        Requester: selectedPurchaseOrder?.distributions[0]?.Requester,
        PO_ConversionRate:
          selectedPurchaseOrder?.distributions[0]?.ConversionRate,
        PO_BusinessUnit:
          selectedPurchaseOrder?.Po?.ProcurementBU ||
          userData?.orgDetail?.organization,
      };

      //Axios Call
      axios({
        method: "post",
        url:
          isEdit && !dratInvoice
            ? `${process.env.REACT_APP_LDOCS_API_URL}/invoice/updateInvoice`
            : `${process.env.REACT_APP_LDOCS_API_URL}/invoice/submitInvoice`,
        data: formData,
        headers: {
          //"Content-Type": "multipart/form-data",
          cooljwt: Token,
        },
      })
        .then((response) => {
          if (
            edit &&
            !isVendor &&
            !isAr &&
            fileData?.createdByVendor &&
            fileData.trackingStatus?.current_status == "initialReview"
          ) {
            setIsSavingInvoice(true);
          } else {
            setIsSavingInvoice(false);
            setIsDraftInvoice(false);
            successAlert("Invoice Submitted Successfully.");
          }
          if (isVendor && !edit) {
            history.push("/vendor/invoices");
          }
          if (isVendor && edit) {
            closeModal();
          }
          if (edit && dratInvoice && !isVendor) {
            setItems([]);
            setFormState(initialState);
            closeModal();
          }
          if (!edit && !isVendor) {
            setItems([]);
            setFormState(initialState);
            history.push("/default/invoices/ap");
          } else {
            if (props.loadFiles) {
              props.loadFiles(userData, false);
            }
          }
          res("success");
        })
        .catch(async (error) => {
          rej("error");
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          await closeMarkAsReceivedModel();
          setIsSavingInvoice(false);
          setIsDraftInvoice(false);
          errorAlert(
            error.response.data && error.response.data !== ""
              ? error.response.data
              : error.message || "Error in Creating Invoice"
          );
        });
    });
  };

  const createDraftInvoice = () => {
    return new Promise((res, rej) => {

      if (formState.isPo && formState.selectedPurchaseOrder === null) {
        errorAlert("Please select a purchase order before creating invoice");
        return;
      }
      //Creating Draft Invoice
      setIsDraftInvoice(true);

      const isEdit = props.editHandler == 1 ? true : false;
      const userData = jwt.decode(Token);
      let userCurrency;
      if (isVendor && formState.selectedOrg) {
        userCurrency = currencyLookups.find(
          (l) => l._id == formState.selectedOrg.currency
        );
      } else if (userData.currency && !isVendor) {
        userCurrency = currencyLookups.find(
          (l) => l._id == userData?.currency?.Currency_Base
        );
      } else {
        userCurrency = defaultCurrency;
      }
      let pra = parseFloat(
        formState.values.praType == 1
          ? formState.values.pra
          : (formState.values.subtotal * formState.values.pra) / 100
      );

      let afterPra = formState.values.subtotal + pra;

      let advanceTax = parseFloat(
        formState.values.advanceTaxType == 1
          ? formState.values.advanceTax
          : (afterPra * formState.values.advanceTax) / 100
      );

      let taxPercent =
        formState.values.taxType === 1
          ? (formState.values.overallTax * 100) / formState.values.subtotal
          : formState.values.overallTax;
      let discountPercent =
        formState.values.discountType === 1
          ? (formState.values.overallDiscount * 100) / formState.values.subtotal
          : formState.values.overallDiscount;
      let taxAmt =
        formState.values.taxType === 2
          ? (formState.values.subtotal * formState.values.overallTax) / 100
          : formState.values.overallTax;
      let discountAmt =
        formState.values.discountType === 2
          ? (formState.values.subtotal * formState.values.overallDiscount) / 100
          : formState.values.overallDiscount;
      let formData = {
        mongoID: edit ? fileData?._id : "",
        invoiceId: formState.values.supplierInvId,
        originalInvoiceId: formState.values.InvoiceNumber.toUpperCase(),
        invoiceDate: formState.values.invoiceDate,
        dueDate: formState.values.dueDate,
        glDate: isVendor
          ? formState.values.invoiceDate
          : formState.values.glDate,
        paymentcurrency: formState.values.paymentcurrency,
        grossAmt: formState.values.subtotal,
        discountPercent: discountPercent,
        discountAmt: discountAmt,
        taxAmt: taxAmt,
        taxPercent: taxPercent,
        invoice_details: formState.values.notes,
        netAmt: formState.values.total,
        ref: formState.values.poNumber,
        tenantId: isVendor ? formState.selectedOrg.tenantId : userData.tenantId,
        organizationId: isVendor
          ? formState.selectedOrg.organizationId
          : userData.orgDetail.organizationId,
        organizationName: isVendor
          ? formState.selectedOrg.organizationName
          : userData.orgDetail.organization,
        contactPerson: isVendor ? null : "",
        createdBy:
          edit && isVendor
            ? userData.email
            : edit
            ? fileData.createdBy
            : userData.email,
        balanceDue: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(formState.values.total),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        items: items,
        attachments: formState.attachments,
        vendorName: isAr
          ? null
          : isVendor
          ? userData.name
          : formState.selectedVendor?.level1?.Supplier || "",
        vendorId: isAr
          ? null
          : isVendor
          ? userData.id
          : formState.selectedVendor._id,
        vendorSite: !isVendor
          ? formState.selectedSite?.SupplierSite
          : selectedPurchaseOrder?.Po.SupplierSite || "",
        clientName: isAr
          ? formState.values.selectedVendor?.level1.clientName
          : null,
        clientId: isAr ? formState.values.selectedVendor._id : null,
        clientSite: formState.values.site,
        version: fileData ? fileData.version : "",
        isAR: isAr,
        invoicePath: fileData ? fileData.invoicePath : "",
        FC_currency: currencyLookups.find(
          (l) => l._id == formState.values.currency
        ),

        // PO_CurrencyCode: selectedPurchaseOrder?.distributions[0]?.CurrencyCode,
        PO_CurrencyCode: currencyLookups.find(
          (l) => l._id == formState.values.currency
        ).Code,
        LC_currency: userCurrency,
        // conversionRate: currencyLookups.find(
        //   l => l._id == formState.values.currency
        // ).conversionRate,
        conversionRate: orcConversionRate,
        PO_ConversionRate: currencyLookups.find(
          (l) => l._id == formState.values.currency
        )?.conversionRate,
        ConversionRateType: selectedPurchaseOrder?.Po?.ConversionRateType,
        ConversionDate: formState.values.invoiceDate,
        description: formState.values.notes,
        createdByVendor: edit
          ? fileData.createdByVendor
          : isVendor
          ? true
          : false,
        po: formState?.selectedPurchaseOrder?.OrderNumber || "",
        // receiptNumber: formState.values.receiptNumber,
        paymentTerms: formState.values.paymentTerms,
        isPo: formState.isPo,
        isReceipt: formState.isReceipt,
        requesterId: userData.email,
        isPettyCash: formState.isPeetyCash,
        isPrePayment: formState.isPrePayment,
        createdDate: new Date(),
        grossAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(formState.values.subtotal),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        discountAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(discountAmt),
          true,
          edit,
          formState.conversionRate
        ),
        taxAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(taxAmt),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        netAmt_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(formState.values.total),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        advanceTax: advanceTax,
        advanceTaxType: formState.values.advanceTaxType,
        praTax: pra,
        praTaxType: formState.values.praType,
        advanceTax_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(advanceTax),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        praTax_bc: conversionRate(
          formState.values.currency,
          baseCurrency,
          currencyLookups,
          parseFloat(pra),
          true,
          formState.isPo,
          formState.conversionRate
        ),
        Requester: selectedPurchaseOrder?.distributions[0]?.Requester,
        PO_ConversionRate:
          selectedPurchaseOrder?.distributions[0]?.ConversionRate,
        PO_BusinessUnit:
          selectedPurchaseOrder?.Po?.ProcurementBU ||
          userData?.orgDetail?.organization,
      };

      //Axios Call
      axios({
        method: "post",
        url: `${process.env.REACT_APP_LDOCS_API_URL}/invoice/draft`,
        data: formData,
        headers: {
          //"Content-Type": "multipart/form-data",
          cooljwt: Token,
        },
      })
        .then((response) => {
          setIsDraftInvoice(false);
          successAlert("Invoice Saved As Draft");

          if (isVendor) {
            history.push("/vendor/invoices");
          }
          if (!edit && !isVendor) {
            setItems([]);
            setFormState(initialState);
            history.push("/default/invoices/ap");
          } else {
            if (props.loadFiles) {
              props.loadFiles(userData, false);
            }
          }
          res("success");
        })
        .catch(async (error) => {
          rej("error");
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          await closeMarkAsReceivedModel();
          setIsDraftInvoice(false);
          errorAlert(
            error.response.data && error.response.data !== ""
              ? error.response.data
              : error.message || "Error in Creating Invoice"
          );
        });
    });
  };

  const focusHandler = (event) => {
    console.log("focus");
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const blurHandler = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        invoiceamount: addZeroes(formState.values.invoiceamount),
      },
    }));
  };

  const refresh = () => {
    setFormState(initialState);
    _addItemsFromPurchaseOrder("");
    COAHandler();
    changePaymentterms();
    _getInvoiceCount();
    _getOrganzationCurrencies();
    _getCOADefaultValues();
    _getTaxClassifications();
  };

  return (
    <div>
      {/* {isMarked ? <Redirect exact to="/invoice/received" /> : ''} */}
      {isCreateInvoice ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={isCreateInvoice}
        >
          <GridContainer>
            {pdfModal ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"sm"}
                open={pdfModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={closePdfModal}
                aria-labelledby="pdf-modal-slide-title"
                aria-describedby="pdf-modal-slide-description"
              >
                <DialogContent id="pdfupload" className={classes.modalBody}>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>
                          Extracting Data from File
                        </h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <ScanningDocumentAnimation />
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {selectedFileModel ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"md"}
                open={selectedFileModel}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSelectedFileModel(false)}
                aria-labelledby="selected-modal-slide-title"
                aria-describedby="selected-modal-slide-description"
              >
                <DialogContent
                  id="selected-Select"
                  className={classes.modalBody}
                >
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>File Details</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        {/* <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                          <TextField
                            className={classes.textField}
                            fullWidth={true}
                            label='File Name'
                            disabled
                            value={selectedFileName}
                          />
                        </GridItem> */}

                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            error={formState.errors.fileTitle === "error"}
                            required
                            InputLabelProps={{
                              classes: {
                                asterisk: classes.asterisk,
                              },
                            }}
                            helperText={
                              formState.errors.fileTitle === "error"
                                ? "Valid File Title is required"
                                : null
                            }
                            className={classes.textField}
                            fullWidth={true}
                            label="File Name"
                            name="fileTitle"
                            onChange={(event) => {
                              setSelectedFileName(event.target.value);
                            }}
                            value={selectedFileName || ""}
                          />
                        </GridItem>
                        {/* <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            error={formState.errors.fileDescription === "error"}
                            helperText={
                              formState.errors.fileDescription === "error"
                                ? "Valid File Description is required"
                                : null
                            }
                            className={classes.textField}
                            fullWidth={true}
                            label="File Description"
                            name="fileDescription"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.fileDescription || ""}
                          />
                        </GridItem> */}
                        <GridItem
                          style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <Button
                            color="danger"
                            size="small"
                            onClick={() => closeAttachmentModel()}
                            round
                          >
                            Close
                          </Button>
                          <Button
                            color="info"
                            size="small"
                            onClick={() => getFileDetails()}
                            round
                          >
                            Add File
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {
              //Advance tax && Pra
            }
            {advanceTaxModel ? (
              <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                disableBackdropClick
                disableEscapeKeyDown
                open={advanceTaxModel}
                onClose={() => setIsAdvanceTaxModel(false)}
              >
                <DialogContent>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>Advance Tax</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            error={formState.errors.advanceTax === "error"}
                            helperText={
                              formState.errors.advanceTax === "error"
                                ? "Valid Advance Tax is required"
                                : null
                            }
                            className={classes.textField}
                            fullWidth={true}
                            label="Advance Tax"
                            type="number"
                            name="advanceTax"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.advanceTax || ""}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            label="Advance Tax Type"
                            name="advanceTaxType"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.advanceTaxType || ""}
                            fullWidth={true}
                            select
                          >
                            <MenuItem value={1}>
                              Amount ({currency.Code || "$"})
                            </MenuItem>
                            <MenuItem value={2}>Percentage (%)</MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <Button
                            color="danger"
                            size="small"
                            onClick={() => setIsAdvanceTaxModel(false)}
                            round
                          >
                            Close
                          </Button>
                          <Button
                            color="info"
                            size="small"
                            onClick={() => setIsAdvanceTaxModel(false)}
                            round
                          >
                            Save
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {praTaxModel ? (
              <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                disableBackdropClick
                disableEscapeKeyDown
                open={praTaxModel}
                onClose={() => setIsPraTaxModel(false)}
              >
                <DialogContent>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>PRA</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            error={formState.errors.pra === "error"}
                            helperText={
                              formState.errors.pra === "error"
                                ? "Valid PRA  is required"
                                : null
                            }
                            className={classes.textField}
                            fullWidth={true}
                            label="PRA "
                            type="number"
                            name="pra"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.pra || ""}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            label="PRA Type"
                            name="praType"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.praType || ""}
                            fullWidth={true}
                            select
                          >
                            <MenuItem value={1}>
                              Amount ({currency.Code || "$"})
                            </MenuItem>
                            <MenuItem value={2}>Percentage (%)</MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <Button
                            color="danger"
                            size="small"
                            onClick={() => setIsPraTaxModel(false)}
                            round
                          >
                            Close
                          </Button>
                          <Button
                            color="info"
                            size="small"
                            onClick={() => setIsPraTaxModel(false)}
                            round
                          >
                            Save
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}

            {discountModel ? (
              <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                disableBackdropClick
                disableEscapeKeyDown
                open={discountModel}
                onClose={() => setIsDiscountModel(false)}
              >
                <DialogContent>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>Discount</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            error={formState.errors.overallDiscount === "error"}
                            helperText={
                              formState.errors.overallDiscount === "error"
                                ? "Valid Discount is required"
                                : null
                            }
                            className={classes.textField}
                            fullWidth={true}
                            label="Discount"
                            type="number"
                            name="overallDiscount"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.overallDiscount || ""}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            label="Discount Type"
                            name="discountType"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.discountType || ""}
                            fullWidth={true}
                            select
                          >
                            <MenuItem value={1}>
                              Amount ({currency.Code || "$"})
                            </MenuItem>
                            <MenuItem value={2}>Percentage (%)</MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <Button
                            color="danger"
                            size="small"
                            onClick={() => setIsDiscountModel(false)}
                            round
                          >
                            Close
                          </Button>
                          <Button
                            color="info"
                            size="small"
                            onClick={() => setIsDiscountModel(false)}
                            round
                          >
                            Save
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {taxModal ? (
              <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                disableBackdropClick
                disableEscapeKeyDown
                open={taxModal}
                onClose={() => setIsTaxModal(false)}
              >
                <DialogContent>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>Tax/VAT</h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            error={formState.errors.overallTax === "error"}
                            helperText={
                              formState.errors.overallTax === "error"
                                ? "Valid Tax is required"
                                : null
                            }
                            className={classes.textField}
                            fullWidth={true}
                            label="Tax / VAT"
                            type="number"
                            disabled={true}
                            name="overallTax"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.overallTax || 0}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <TextField
                            labelId="demo-dialog-select-label"
                            id="demo-dialog-select"
                            label="Tax Type"
                            name="taxType"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            value={formState.values.taxType || ""}
                            fullWidth={true}
                            select
                          >
                            <MenuItem value={1}>
                              Amount ({currency.Code || "$"})
                            </MenuItem>
                            <MenuItem value={2}>Percentage (%)</MenuItem>
                          </TextField>
                        </GridItem>
                        <GridItem
                          style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <Button
                            color="danger"
                            size="small"
                            onClick={() => setIsTaxModal(false)}
                            round
                          >
                            Close
                          </Button>
                          <Button
                            color="info"
                            size="small"
                            onClick={() => setIsTaxModal(false)}
                            round
                          >
                            Save
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}

            {markAsReceivedModel ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"sm"}
                open={markAsReceivedModel}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeMarkAsReceivedModel}
                aria-labelledby="tag-modal-slide-title"
                aria-describedby="tag-modal-slide-description"
              >
                <DialogContent
                  id="tag-modal-slide-description"
                  className={classes.modalBody}
                >
                  <FileReceived
                    closeFileReceivedModal={closeMarkAsReceivedModel}
                    fileData={fileData}
                    setAnimateTable={props.setAnimateTable}
                    setEditInvoiceModel={props.setEditInvoiceModel}
                    createInvoice={createInvoice}
                  />
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {purchaseOrderModal ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"xl"}
                open={purchaseOrderModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setPurchaseOrderModal(false)}
                aria-labelledby="vendor-modal-slide-title"
                aria-describedby="vendor-modal-slide-description"
              >
                <DialogContent id="vendorSelect" className={classes.modalBody}>
                  <PurchaseOrderView
                    PurchaseOrder={selectedPurchaseOrder}
                    onBack={() => setPurchaseOrderModal(false)}
                  />
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {receiptModal ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"sm"}
                open={receiptModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setReceiptModal(false)}
                aria-labelledby="vendor-modal-slide-title"
                aria-describedby="vendor-modal-slide-description"
              >
                <DialogContent id="vendorSelect" className={classes.modalBody}>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>
                          Purchase Order
                        </h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <Table>
                        <TableBody>
                          {receipt.map((val, index) => (
                            <TableRow key={index}>
                              <TableCell>{val.name}</TableCell>
                              <TableCell>{val.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            {vendorModal ? (
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal,
                }}
                fullWidth={true}
                maxWidth={"md"}
                open={vendorModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={(event, reason) => {
                  if (
                    reason !== "backdropClick" &&
                    reason !== "escapeKeyDown"
                  ) {
                    // Set 'open' to false, however you would do that with your particular code.
                    setVendorModal(false);
                  }
                }}
                // onClose={() => setVendorModal(false)}
                aria-labelledby="vendor-modal-slide-title"
                aria-describedby="vendor-modal-slide-description"
              >
                <DialogContent id="vendorSelect" className={classes.modalBody}>
                  <Card>
                    <CardHeader color="info" icon>
                      <CardIcon color="info">
                        <h4 className={classes.cardTitleText}>
                          {isVendor
                            ? "Select Customer"
                            : isAr
                            ? "Select Customer"
                            : "Select Supplier"}
                        </h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        {!isVendor ? (
                          <React.Fragment>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={11}
                              lg={11}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <Autocomplete
                                id="filter-demo"
                                options={formState.vendors || []}
                                variant="outlined"
                                inputValue={formState.supplierInputValue || ""}
                                onInputChange={(event, newInputValue) => {
                                  setFormState((formState) => ({
                                    ...formState,
                                    supplierInputValue: newInputValue,
                                    changedsupplierInputValue: true,
                                    autoCompleteOpen: true,
                                  }));
                                }}
                                value={formState.selectedVendor || ""}
                                getOptionLabel={(option) =>
                                  isAr
                                    ? option?.level1?.clientName || ""
                                    : option?.level1?.Supplier || ""
                                }
                                loading={isLoading}
                                onChange={(event, newValue) => {
                                  setFormState((formState) => ({
                                    ...formState,
                                    selectedVendor: newValue,
                                    selectedSite:
                                      newValue?.level1?.supplierSites[0],
                                  }));
                                  setFormState((formState) => ({
                                    ...formState,
                                    values: {
                                      ...formState.values,
                                      selectSite:
                                        newValue?.level1?.supplierSites[0]
                                          ?.SupplierSiteId,
                                    },
                                  }));
                                }}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={
                                      isAr
                                        ? "Select Customer"
                                        : "Select Supplier"
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoading ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}

                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={1}
                              lg={1}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <IconButton
                                disabled={
                                  formState.values.selectedVendor == null ||
                                  undefined ||
                                  ""
                                    ? true
                                    : false
                                }
                                onClick={() => setShowVendor(!showVendor)}
                              >
                                {!showVendor ? (
                                  <Visibility fontSize="small" />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </GridItem>
                            {formState.selectedVendor ? (
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  overflowX: "hidden",
                                }}
                              >
                                {showVendor ? (
                                  <GeneralInfo
                                    generalInfo={
                                      formState.selectedVendor?.level1
                                    }
                                  />
                                ) : (
                                  <TextField
                                    style={{ marginTop: "10px" }}
                                    error={
                                      formState.errors.selectSite === "error"
                                    }
                                    helperText={
                                      formState.errors.selectSite === "error"
                                        ? isAr
                                          ? "Valid Customer Site is required"
                                          : "Valid Supplier Site is required"
                                        : null
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    className={classes.textField}
                                    fullWidth={true}
                                    label={
                                      isAr
                                        ? "Select Customer Site"
                                        : "Select Supplier Site"
                                    }
                                    name="selectSite"
                                    variant="outlined"
                                    onChange={(event) => {
                                      handleChange(event);
                                    }}
                                    select
                                    value={formState.values.selectSite || ""}
                                  >
                                    <MenuItem
                                      disabled
                                      classes={{
                                        root: classes.selectMenuItem,
                                      }}
                                    >
                                      {isAr
                                        ? "Choose Customer Site"
                                        : "Choose Supplier Site"}
                                    </MenuItem>
                                    {formState.selectedVendor?.level1?.supplierSites.map(
                                      (site, index) => {
                                        return (
                                          <MenuItem
                                            key={site.SupplierSiteId}
                                            value={site.SupplierSiteId}
                                          >
                                            {site.SupplierSite}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </TextField>
                                )}
                              </GridItem>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={11}
                              lg={11}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <TextField
                                error={
                                  formState.errors.selectedVendor === "error"
                                }
                                helperText={
                                  formState.errors.selectedVendor === "error"
                                    ? "Valid Customer Name is required"
                                    : null
                                }
                                className={classes.textField}
                                fullWidth={true}
                                label="Select Customer"
                                name="organizationId"
                                onChange={(event) => {
                                  handleVendorChange(event);
                                }}
                                select
                                value={formState.values.organizationId || ""}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                >
                                  Choose Customer
                                </MenuItem>
                                {formState.organizations
                                  ? formState.organizations.map(
                                      (org, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={org.organizationId}
                                          >
                                            {org.organizationName}
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : ""}
                              </TextField>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={1}
                              lg={1}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <IconButton
                                disabled={
                                  formState.values.organization == null ||
                                  undefined ||
                                  ""
                                    ? true
                                    : false
                                }
                                //onClick={() => setShowVendor(!showVendor)}
                              >
                                {!showVendor ? (
                                  <Visibility fontSize="small" />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </GridItem>
                            {formState.values.organizationId ? (
                              <GridItem
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                {showVendor
                                  ? // <Step1
                                    //   vendorData={formState.values.selectedVendor}
                                    // />
                                    ""
                                  : ""}
                              </GridItem>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        )}
                        <GridItem
                          style={{
                            display: "flex",
                            alignItems: "right",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <Button
                            color="danger"
                            size="small"
                            onClick={() =>
                              !isVendor
                                ? validateVendorSite()
                                : setVendorModal(false)
                            }
                            round
                          >
                            Close
                          </Button>
                          <Button
                            color="info"
                            size="small"
                            onClick={() => selectVendor()}
                            round
                          >
                            {isVendor
                              ? "Select Customer"
                              : isAr
                              ? "Select Customer"
                              : "Select Supplier"}
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </DialogContent>
              </Dialog>
            ) : (
              ""
            )}
            <GridItem xs={12}>
              {/* {isLoading ? <LinearProgress /> : ""} */}
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      {edit ? "Resubmit Invoice" : "Create Invoice"}
                    </h4>
                  </CardIcon>
                  {/* <Button
                    color='danger'
                    round
                    style={{ float: 'right' }}
                    className={classes.marginRight}
                    onClick={() =>
                      edit ? closeModal() : pdfInput.current.click()
                    }
                  >
                    {edit ? 'Close' : 'Upload PDF / Image'}
                  </Button> */}
                  {edit ? (
                    ""
                  ) : (
                    <Tooltip
                      id="tooltip-top"
                      title="Refresh"
                      style={{ float: "right" }}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="danger"
                        round
                        style={{ float: "right" }}
                        className={classes.marginRight}
                        onClick={refresh}
                      >
                        Refresh
                      </Button>
                    </Tooltip>
                  )}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ marginTop: "-10px", marginBottom: "10px" }}
                    >
                      <Card
                        style={{
                          height: "15vh",
                          maxWidth: "70%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: edit ? "" : "pointer",
                          border:
                            formState.errors.vendor === "error"
                              ? "2px red solid"
                              : "none",
                          background: "#f5f5f5",
                        }}
                        onClick={
                          isVendor || edit
                            ? () => console.log("Can't Do..")
                            : openVendorModal
                        }
                      >
                        {isVendor ? (
                          <GridContainer>
                            <GridItem
                              xs="3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <Person
                                fontSize="large"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </GridItem>
                            <GridItem
                              xs="9"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 50,
                                // justifyContent: "left",
                              }}
                            >
                              {formState.selectedOrg ? (
                                <div>
                                  <Typography variant="body1" component="h2">
                                    {formState.selectedOrg.organizationName ||
                                      ""}
                                  </Typography>
                                  <Typography variant="body2" component="h2">
                                    {formState.selectedOrg.address || ""}
                                  </Typography>
                                </div>
                              ) : (
                                <div>
                                  <Typography variant="body1" component="h2">
                                    Customer Name
                                  </Typography>
                                  <Typography variant="body2" component="h2">
                                    Customer Address
                                  </Typography>
                                </div>
                              )}
                            </GridItem>
                          </GridContainer>
                        ) : (
                          <GridContainer>
                            <GridItem
                              xs="3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                              }}
                            >
                              <Person
                                fontSize="large"
                                style={{ width: "100%", height: "100%" }}
                              />
                              {/* {formState.values.selectedVendor &&
                                formState.values.selectedVendor.level1
                                  ?.logoUrl ? (
                                <img
                                  src={
                                    formState.values.selectedVendor.level1
                                      .logoUrl
                                  }
                                  style={{ width: "100px", marginLeft: 10 }}
                                />
                              ) : (
                                <Person
                                  fontSize="large"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              )
                              } */}
                            </GridItem>
                            <GridItem
                              xs="9"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 50,
                                // justifyContent: "left",
                              }}
                            >
                              {formState.selectedVendor ? (
                                <div>
                                  <Typography
                                    variant="body1"
                                    component="h2"
                                    style={edit ? {} : { color: "black" }}
                                  >
                                    {formState?.selectedVendor?.level1
                                      ?.Supplier ||
                                      formState?.selectedVendor?.level1
                                        ?.clientName}
                                  </Typography>
                                  <Divider />
                                  <Typography
                                    variant="body2"
                                    component="h2"
                                    style={edit ? {} : { color: "black" }}
                                  >
                                    {formState?.selectedSite?.SupplierSite ||
                                      ""}
                                  </Typography>
                                </div>
                              ) : (
                                <div>
                                  <Typography
                                    variant="body1"
                                    component="h2"
                                    style={edit ? {} : { color: "black" }}
                                  >
                                    {isAr ? "Customer Name" : "Supplier Name"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    component="h2"
                                    style={{ color: "black" }}
                                  >
                                    {isAr ? "Customer Site" : "Supplier Site"}
                                  </Typography>
                                </div>
                              )}
                            </GridItem>
                          </GridContainer>
                        )}
                      </Card>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <FormGroup row>
                          <React.Fragment>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.isPrePayment}
                                  onChange={handleChange}
                                  // disabled={edit}
                                  value="isPrePayment"
                                  color="black"
                                  name="invoiceType"
                                />
                              }
                              label={
                                <Typography style={{ color: "black" }}>
                                  Pre-Payment
                                </Typography>
                              }
                            />
                            {!isVendor ? (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={formState.isReceipt}
                                    onChange={handleChange}
                                    // disabled={edit}
                                    name="invoiceType"
                                    value="isReceipt"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography style={{ color: "black" }}>
                                    With Receipt
                                  </Typography>
                                }
                              />
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                          {!isVendor ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formState.isPeetyCash}
                                  onChange={handleChange}
                                  disabled={edit}
                                  value="isPeetyCash"
                                  name="invoiceType"
                                  color="primary"
                                />
                              }
                              // label='Petty Cash'
                              label={
                                <Typography style={{ color: "black" }}>
                                  Petty Cash
                                </Typography>
                              }
                            />
                          ) : (
                            ""
                          )}
                        </FormGroup>
                      </GridItem>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <GridContainer>
                        {/* <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                          <TextField
                            fullWidth={true}
                            required
                            label='Invoice ID'
                            disabled={true}
                            value={formState.values.supplierInvId}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                              style: { color: 'black' },
                              classes: {
                                asterisk: classes.asterisk
                              }
                            }}
                          />
                        </GridItem> */}
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={12}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            required
                            error={formState.errors.invoiceDate === "error"}
                            helperText={
                              formState.errors.invoiceDate === "error"
                                ? "Valid Invoice Date is required"
                                : null
                            }
                            label="Invoice Date"
                            id="invoiceDate"
                            name="invoiceDate"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            type="date"
                            // variant="outlined"
                            value={formState.values.invoiceDate || ""}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                              style: { color: "black" },
                              classes: {
                                asterisk: classes.asterisk,
                              },
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={12}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            required
                            InputLabelProps={{
                              style: { color: "black" },
                              classes: {
                                asterisk: classes.asterisk,
                              },
                            }}
                            error={formState.errors.InvoiceNumber === "error"}
                            helperText={
                              formState.errors.InvoiceNumber === "error"
                                ? "Valid Invoice Number is required"
                                : null
                            }
                            label="Invoice Number"
                            id="InvoiceNumber"
                            name="InvoiceNumber"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            type="text"
                            // disabled={edit}
                            // variant="outlined"
                            value={formState.values.InvoiceNumber}
                            className={classes.textField}
                          />
                        </GridItem>
                        {/* <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                          <TextField
                            fullWidth={true}
                            required
                            error={formState.errors.dueDate === 'error'}
                            helperText={
                              formState.errors.dueDate === 'error'
                                ? 'Valid Due Date is required'
                                : null
                            }
                            label='Due Date'
                            id='duedate'
                            name='dueDate'
                            onChange={event => {
                              handleChange(event)
                            }}
                            type='date'
                            // variant="outlined"
                            value={formState.values.dueDate || ''}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                              style: { color: 'black' },
                              classes: {
                                asterisk: classes.asterisk
                              }
                            }}
                          />
                        </GridItem> */}
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            error={formState.errors.currency === "error"}
                            helperText={
                              formState.errors.currency === "error"
                                ? "Valid Invoice Currency is required"
                                : null
                            }
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            disabled={formState.isPo}
                            label="Currency"
                            id="currency"
                            name="currency"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            type="text"
                            // variant="outlined"
                            value={formState.values.currency}
                            select
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              Choose Currency Base
                            </MenuItem>
                            {currencyLookups.map((cu) => (
                              <MenuItem key={cu._id} value={cu._id}>
                                {`${cu.Currency.toUpperCase()} (${cu.Code})`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            required
                            error={formState.errors.paymentcurrency === "error"}
                            helperText={
                              formState.errors.paymentcurrency === "error"
                                ? "Valid Payment Currency is required"
                                : null
                            }
                            InputLabelProps={{
                              style: { color: "black" },
                              classes: {
                                asterisk: classes.asterisk,
                              },
                            }}
                            label="Payment Currency"
                            id="paymentcurrency"
                            name="paymentcurrency"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            type="text"
                            // variant="outlined"
                            value={formState.values.paymentcurrency}
                            select
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem,
                              }}
                            >
                              Choose Payment Currency
                            </MenuItem>
                            {currencyLookups.map((cu) => (
                              <MenuItem key={cu._id} value={cu.Code}>
                                {`${cu.Currency.toUpperCase()} (${cu.Code})`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </GridItem>
                        {!formState.isPeetyCash ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      // disabled={}
                                      checked={formState.isPo}
                                      disabled={
                                        edit ||
                                        formState.isPeetyCash ||
                                        formState.isReceipt ||
                                        isVendor
                                      }
                                      onChange={handleChange}
                                      value="isPo"
                                      name="isPo"
                                      color="primary"
                                    />
                                  }
                                  // label='Purchase Order'
                                  label={
                                    <Typography style={{ color: "black" }}>
                                      Purchase Order
                                    </Typography>
                                  }
                                />
                                {!isVendor && !formState.isReceipt ? (
                                  <React.Fragment>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={formState.isExpense}
                                          onChange={handleChange}
                                          disabled={
                                            edit || formState.isPeetyCash
                                          }
                                          name="isPo"
                                          // disabled={formState.isPeetyCash}
                                          color="primary"
                                          value="isExpense"
                                        />
                                      }
                                      // label={isAr ? 'Revenue' : 'Expense'}
                                      label={
                                        isAr ? (
                                          <Typography
                                            style={{ color: "black" }}
                                          >
                                            Revenue
                                          </Typography>
                                        ) : (
                                          <Typography
                                            style={{ color: "black" }}
                                          >
                                            Expense
                                          </Typography>
                                        )
                                      }
                                    />
                                    {/* <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formState.isPeetyCash}
                                      onChange={handleChange}
                                      value="isPeetyCash"
                                      name="invoiceType"
                                      color="primary"
                                    />
                                  }
                                  label="Peety Cash"
                                /> */}
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </FormGroup>
                            </GridItem>
                          </>
                        ) : (
                          ""
                        )}
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            label="Conversion Rate"
                            id="conversionRate"
                            name="conversionrate"
                            disabled={true}
                            type="text"
                            // variant="outlined"
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            value={
                              oracleConRate
                                ? ""
                                : currency
                                ? `${currency.Code ? currency.Code : ""} 1 ≈ ${
                                    currencyLookups.find(
                                      (c) => c._id == baseCurrency
                                    )
                                      ? currencyLookups.find(
                                          (c) => c._id == baseCurrency
                                        ).Code
                                      : ""
                                  } ${
                                    formState.isPo
                                      ? formState.conversionRate
                                      : currency.conversionRate
                                      ? parseFloat(
                                          !edit
                                            ? currency.conversionRate
                                            : formState.conversionRate
                                        )?.toFixed(4)
                                      : ""
                                  }`
                                : "" || ""
                            }
                            className={classes.textField}
                          />
                        </GridItem>
                        {!formState.isPeetyCash &&
                        !formState.isExpense &&
                        formState.isPo ? (
                          !isAr ? (
                            <React.Fragment>
                              <GridItem
                                xs={10}
                                sm={10}
                                md={5}
                                lg={5}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Autocomplete
                                  id="purchase_order_auto_complete"
                                  options={purchaseOrders || []}
                                  variant="standard"
                                  disabled={edit}
                                  inputValue={formState.poInputValue || ""}
                                  onInputChange={(event, newInputValue) => {
                                    setFormState((formState) => ({
                                      ...formState,
                                      poInputValue: newInputValue,
                                      changedsupplierInputValue: false,
                                      changedPoInputValue: true,
                                    }));
                                  }}
                                  value={formState.selectedPurchaseOrder || ""}
                                  getOptionLabel={(option) =>
                                    option?.OrderNumber?.toString() || ""
                                  }
                                  // loading={isLoading}
                                  onChange={(event, newValue) => {
                                    setFormState((formState) => ({
                                      ...formState,
                                      selectedPurchaseOrder: newValue,
                                      supplierInputValue: newValue?.Supplier?.substring(
                                        0,
                                        3
                                      ),
                                      // values: {
                                      //   ...formState.values,
                                      //   paymentTerms: formState?.isPrePayment ? "Advance Payment" : newValue?.PaymentTerms || "",
                                      // }
                                    }));
                                    if (newValue?.POHeaderId) {
                                      _getPurchaseOrderDetails(
                                        newValue?.POHeaderId,
                                        true
                                      );
                                      getReceipts(newValue?.POHeaderId);
                                    }
                                  }}
                                  sx={{ width: 300 }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      name="poNumber"
                                      disabled={edit}
                                      InputLabelProps={{
                                        style: { color: "black" },
                                      }}
                                      label="Purchase Order Number"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {PoLoading ? (
                                              <CircularProgress
                                                color="inherit"
                                                size={20}
                                              />
                                            ) : null}

                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                                {/* <TextField
                                  fullWidth={true}
                                  // error={formState.errors.poNumber}
                                  // helperText={
                                  //   formState.errors.poNumber == "error"
                                  //     ? "Valid PO Number is required"
                                  //     : null
                                  // }
                                  label={`PO Number`}
                                  id="poNumber"
                                  name="poNumber"
                                  disabled={formState.isPeetyCash}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  value={formState.values.poNumber || ""}
                                  select
                                >
                                  <MenuItem key={""} disabled={true}>
                                    PO NUMBER
                                  </MenuItem>
                                  {purchaseOrders.map((po, index) => (
                                    <MenuItem
                                      key={index}
                                      value={po.OrderNumber}
                                    >
                                      {po.OrderNumber}
                                    </MenuItem>
                                  ))}
                                </TextField> */}
                              </GridItem>
                              <GridItem
                                xs={2}
                                sm={2}
                                md={1}
                                lg={1}
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "10px",
                                }}
                              >
                                {/* {formState.values.poNumber ? */}
                                {!formState.loadingPurchaseOrder ? (
                                  <Tooltip title="View PO">
                                    <IconButton
                                      style={{ background: "lightgrey" }}
                                      onClick={() => viewPurchaseOrder(true)}
                                    >
                                      {purchaseOrderModal ? (
                                        <VisibilityOff fontSize="small" />
                                      ) : (
                                        <Visibility fontSize="small" />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <CircularProgress />
                                )}
                              </GridItem>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <TextField
                                  fullWidth={true}
                                  // error={formState.errors.poNumber}
                                  // helperText={
                                  //   formState.errors.poNumber == "error"
                                  //     ? "Valid PO Number is required"
                                  //     : null
                                  // }
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  label={`PO Number`}
                                  id="poNumber"
                                  name="poNumber"
                                  disabled={formState.isPeetyCash}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  value={formState.values.poNumber || ""}
                                />
                              </GridItem>
                            </React.Fragment>
                          )
                        ) : (
                          ""
                        )}
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <TextField
                            fullWidth={true}
                            error={formState.errors.paymentTerms === "error"}
                            helperText={
                              formState.errors.paymentTerms === "error"
                                ? "Valid Payment Terms required"
                                : null
                            }
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            label="Payment Terms"
                            id="paymentTerms"
                            disabled={
                              formState.isPrePayment || formState.isReceipt
                            }
                            name="paymentTerms"
                            // disabled={formState.isPeetyCash}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            type="text"
                            select
                            value={formState.values.paymentTerms || ""}
                            className={classes.textField}
                          >
                            <MenuItem disabled value="">
                              Select Payment Terms
                            </MenuItem>
                            {paymentTerms?.map((list) => (
                              <MenuItem value={list.name}>{list.name}</MenuItem>
                            ))}
                          </TextField>
                        </GridItem>
                        {!isVendor ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <TextField
                                fullWidth={true}
                                type="string"
                                required
                                error={
                                  formState.errors.invoiceamount === "error"
                                }
                                helperText={
                                  formState.errors.invoiceamount === "error"
                                    ? "Correct Invoice Amount is Required"
                                    : null
                                }
                                onChange={handleChange}
                                onBlur={blurHandler}
                                label="Invoice Amount"
                                name="invoiceamount"
                                value={formState.values.invoiceamount || ""}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: "black" },
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                }}
                              />
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <TextField
                                fullWidth={true}
                                required
                                error={formState.errors.glDate === "error"}
                                helperText={
                                  formState.errors.glDate === "error"
                                    ? "Valid GL Date is required"
                                    : null
                                }
                                label="GL Date"
                                id="glDate"
                                name="glDate"
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                type="date"
                                // variant="outlined"
                                value={formState.values.glDate || ""}
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: "black" },
                                  classes: {
                                    asterisk: classes.asterisk,
                                  },
                                }}
                              />
                            </GridItem>
                          </>
                        ) : (
                          ""
                        )}
                        {/* <GridItem
                          xs={10}
                          sm={10}
                          md={3}
                          lg={3}
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            options={receipts.map((option) => option.receiptNumber                              )}
                            renderInput={(params) => (
                              <TextField
                                fullWidth={true}
                                error={
                                  formState.errors.receiptNumber === "error"
                                }
                                helperText={
                                  formState.errors.receiptNumber === "error"
                                    ? "Valid Receipt Number required"
                                    : null
                                }
                                label="Receipt Number"
                                id="receiptNumber"
                                name="receiptNumber"
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                type="text"
                                // variant="outlined"
                                value={formState.values.receiptNumber || ""}
                                className={classes.textField}
                                {...params}
                              />
                            )}
                          />
                        </GridItem> 
                        <GridItem
                          xs={2}
                          sm={2}
                          md={1}
                          lg={1}
                          style={{ marginTop: "15px", marginBottom: "10px" }}
                        >
                          <Tooltip title="View Receipt">
                            <IconButton
                              style={{ background: "lightgrey" }}
                              onClick={() => viewReceipt()}
                            >
                              {receiptModal ? (
                                <VisibilityOff fontSize="small" />
                              ) : (
                                <div>
                                {receipts.length  < 1 ? 
                                  <Add  fontSize="small" /> :
                                  <Visibility fontSize="small" />
                                }
                                </div>
                              )}
                            </IconButton>
                          </Tooltip>
                        </GridItem>
                        */}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem
                      style={{
                        marginBottom: "40px",
                        marginTop: "10px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Accordion expanded>
                        {/* {formState.values.notes == "error" ? <div style={{border: "2px solid red"}}>  */}
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-label="Expand"
                          aria-controls="additional-actions3-content"
                          id="additional-actions3-header"
                          style={
                            formState.errors.notes == "error"
                              ? {
                                  border: "2px solid red",
                                  background: "#f5f5f5",
                                }
                              : { background: "#f5f5f5" }
                          }
                        >
                          <Typography
                            color="textSecondary"
                            component="h2"
                            variant="body2"
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              lineHeight: "1.5rem",
                            }}
                          >
                            Invoice Description
                          </Typography>
                          <Typography
                            color="textSecondary"
                            component="h2"
                            variant="body2"
                            style={{
                              color: "red",
                              fontWeight: 500,
                              marginLeft: "4px",
                              lineHeight: "1.5rem",
                            }}
                          >
                            *
                          </Typography>
                        </AccordionSummary>
                        {/* </div> : ""} */}
                        <AccordionDetails>
                          <TextField
                            fullWidth={true}
                            error={formState.errors.notes === "error"}
                            helperText={
                              formState.errors.notes === "error"
                                ? "Description is required"
                                : null
                            }
                            label="Description"
                            id="notes"
                            name="notes"
                            onChange={(event) => {
                              handleChange(event);
                            }}
                            // variant="outlined"
                            multiline
                            rows={4}
                            type="text"
                            value={formState.values.notes || ""}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </GridItem>
                    {isItemLoading || formState.loadingPurchaseOrder ? (
                      <CircularProgress />
                    ) : (
                      <Items
                        isItemLoading={isItemLoading}
                        formState={formState}
                        setFormState={setFormState}
                        items={items}
                        baseCurrency={
                          fileData ? fileData.LC_currency._id : null
                        }
                        handleChange={handleChange}
                        addZeroes={addZeroes}
                        handleEditItem={handleEditItem}
                        removeItem={removeItem}
                        editIndex={editIndex}
                        setEditIndex={setEditIndex}
                        addingItem={addingItem}
                        addInvoiceItem={addInvoiceItem}
                        addItem={addItem}
                        validCoaLoading={validCoaLoading}
                        removeItems={removeItems}
                        closeItembox={closeItembox}
                        setAddingItem={setAddingItem}
                        editItem={addItem}
                        setCategory={setCategory}
                        category={category}
                        currency={currency || {}}
                        viewPurchaseOrder={viewPurchaseOrder}
                        receipts={receipts}
                        pos={purchaseOrders}
                        isVendor={isVendor}
                        currencyLookups={currencyLookups}
                        userData={userDetails}
                        edit={edit}
                        isAr={isAr}
                      />
                    )}
                    <GridItem
                      style={{
                        marginTop: "40px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Accordion expanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-label="Expand"
                          aria-controls="additional-actions3-content"
                          id="additional-actions3-header"
                          style={{ background: "#f5f5f5" }}
                        >
                          <Typography
                            color="textSecondary"
                            component="h2"
                            variant="body2"
                            style={{
                              color: "#000000",
                              fontWeight: 500,
                              lineHeight: "1.5rem",
                            }}
                          >
                            Attachments
                          </Typography>
                          <Typography
                            color="textSecondary"
                            component="h2"
                            variant="body2"
                            style={{
                              color: "red",
                              marginLeft: "3px",
                              fontWeight: 500,
                              lineHeight: "1.5rem",
                            }}
                          >
                            *
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {/* View Attachments */}
                          <Attachments
                            attachments={formState.attachments}
                            requesterId={userDetails?.email}
                            edit={edit}
                            viewFileHandler={viewFileHandler}
                            removeAttachment={removeAttachment}
                            handleAttachmentChange={handleAttachmentChange}
                            fileInput={fileInput}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </GridItem>
                    {items.length > 0 ? (
                      <GridItem
                        style={{
                          display: "flex",
                          alignItems: "right",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <Table
                          style={{ maxWidth: 400, textAlign: "right" }}
                          size="medium"
                          aria-label="a dense table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                Line Amount
                                <br />
                                {conversionRate(
                                  formState.values.currency,
                                  baseCurrency,
                                  currencyLookups,
                                  parseFloat(formState.values.subtotal),
                                  false,
                                  formState.isPo,
                                  formState.conversionRate
                                )}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label={`Line Amount(${currency.Code || "$"})`}
                                  id="subtotal"
                                  name="subtotal"
                                  disabled={true}
                                  type="string"
                                  variant="outlined"
                                  value={
                                    addZeroes(formState.values.subtotal) || ""
                                  }
                                  className={classes.textField}
                                />
                              </TableCell>
                            </TableRow>
                            {
                              //Discount & Tax
                            }
                            {/* <TableRow>
                              <TableCell
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsDiscountModel(true)}
                              >
                                Discount
                                <br />
                                {conversionRate(
                                  formState.values.currency,
                                  baseCurrency,
                                  currencyLookups,
                                  parseFloat(
                                    formState.values.discountType == 1
                                      ? formState.values.overallDiscount
                                      : (formState.values.subtotal *
                                        formState.values.overallDiscount) /
                                      100
                                  ),
                                  false,
                                  edit,
                                  formState.conversionRate
                                )}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label={`Discount(${currency.Code || "$"})`}
                                  id="discount"
                                  name="overallDiscount"
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  disabled={true}
                                  type="number"
                                  variant="outlined"
                                  value={
                                    addZeroes(
                                      formState.values.discountType == 1
                                        ? formState.values.overallDiscount
                                        : (formState.values.subtotal *
                                          formState.values.overallDiscount) /
                                        100
                                    ) || ""
                                  }
                                  className={classes.textField}
                                />
                              </TableCell>
                            </TableRow> */}
                            <TableRow>
                              <TableCell
                                style={{ cursor: "pointer" }}
                                // onClick={() => setIsTaxModal(true)}
                              >
                                Tax / VAT
                                <br />
                                {conversionRate(
                                  formState.values.currency,
                                  baseCurrency,
                                  currencyLookups,
                                  parseFloat(
                                    formState.values.taxType == 1
                                      ? formState.values.overallTax
                                      : (formState.values.subtotal *
                                          formState.values.overallTax) /
                                          100
                                  ),
                                  false,
                                  formState.isPo,
                                  formState.conversionRate
                                )}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label={`Tax(${currency.Code || "$"})`}
                                  id="tax"
                                  name="overallTax"
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  type="text"
                                  disabled={true}
                                  variant="outlined"
                                  value={
                                    addZeroes(
                                      formState.values.taxType == 1
                                        ? formState.values.overallTax
                                        : (formState.values.subtotal *
                                            formState.values.overallTax) /
                                            100
                                    ) || ""
                                  }
                                  className={classes.textField}
                                />
                              </TableCell>
                            </TableRow>
                            {
                              //Advance tax & PRA
                            }
                            {/* <TableRow>
                              <TableCell
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsPraTaxModel(true)}
                              >
                                PRA 
                                <br />
                                {conversionRate(
                                  formState.values.currency,
                                  baseCurrency,
                                  currencyLookups,
                                  parseFloat(
                                    formState.values.praType == 1
                                      ? formState.values.pra
                                      : (formState.values.subtotal *
                                          formState.values.pra) /
                                          100
                                  ),
                                  false,
                                  edit,
                                  formState.conversionRate
                                )}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label={`PRA (${currency.Code || "$"})`}
                                  id="pra"
                                  name="pra"
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  type="number"
                                  disabled={true}
                                  variant="outlined"
                                  value={
                                    addZeroes(
                                      formState.values.praType == 1
                                        ? formState.values.pra
                                        : (formState.values.subtotal *
                                            formState.values.pra) /
                                            100
                                    ) || ""
                                  }
                                  className={classes.textField}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ cursor: "pointer" }}
                                onClick={() => setIsAdvanceTaxModel(true)}
                              >
                                Advance Tax
                                <br />
                                {conversionRate(
                                  formState.values.currency,
                                  baseCurrency,
                                  currencyLookups,
                                  parseFloat(
                                    formState.values.advanceTaxType == 1
                                      ? formState.values.advanceTax
                                      : (formState.values.subtotal *
                                          formState.values.advanceTax) /
                                          100
                                  ),
                                  false,
                                  edit,
                                  formState.conversionRate
                                )}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label={`Advance Tax(${currency.Code || "$"})`}
                                  id="advanceTax"
                                  name="advanceTax"
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  disabled={true}
                                  type="number"
                                  variant="outlined"
                                  value={
                                    addZeroes(
                                      formState.values.advanceTaxType == 1
                                        ? formState.values.advanceTax
                                        : (formState.values.subtotal *
                                            formState.values.advanceTax) /
                                            100
                                    ) || ""
                                  }
                                  className={classes.textField}
                                />
                              </TableCell>
                            </TableRow>
                           */}

                            {
                              //Total After Calculations
                            }
                            <TableRow>
                              <TableCell component="th" scope="row">
                                Total Amount
                                <br />
                                {conversionRate(
                                  formState.values.currency,
                                  baseCurrency,
                                  currencyLookups,
                                  formState.values.total,
                                  false,
                                  formState.isPo,
                                  formState.conversionRate
                                )}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <TextField
                                  label={`Total Amount(${currency.Code ||
                                    "$"})`}
                                  id="total"
                                  name="total"
                                  disabled={true}
                                  type="text"
                                  variant="outlined"
                                  value={
                                    addZeroes(
                                      parseFloat(formState.values.total)
                                    ) || ""
                                  }
                                  className={classes.textField}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </GridItem>
                    ) : (
                      ""
                    )}
                    <GridItem
                      style={{
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      {edit && !dratInvoice ? (
                        ""
                      ) : (
                        <Button
                          color="info"
                          size="small"
                          disabled={items.length == 0 ? true : false}
                          onClick={() => openPopup("draft")}
                          round
                        >
                          {isDraftInvoice ? (
                            <CircularProgress style={{ color: "white" }} />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      )}
                      <Button
                        color="danger"
                        size="small"
                        disabled={items.length == 0 ? true : false}
                        onClick={() => openPopup("submit")}
                        round
                      >
                        {isSavingInvoice ? (
                          <CircularProgress style={{ color: "white" }} />
                        ) : edit ? (
                          "Save & Submit"
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem
              style={{
                marginTop: "20px",
              }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <div className="fileinput text-center">
                <input
                  type="file"
                  accept="image/png, image/jpeg ,application/pdf "
                  onChange={handlePdfChange}
                  ref={pdfInput}
                />
              </div>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        <Backdrop
          className={classes.backdrop}
          open={!isCreateInvoice && !viewFile && !currenciesLoading}
        >
          <CircularProgress />
        </Backdrop>
      )}
      {viewFile ? (
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutLeft"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={viewFile}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card>
                <CardHeader color="info" icon>
                  <CardIcon color="info">
                    <h4 className={classes.cardTitleText}>
                      {file.attachmentTitle}
                    </h4>
                  </CardIcon>
                  <Button
                    color="danger"
                    round
                    style={{ float: "right" }}
                    className={classes.marginRight}
                    onClick={() => closeViewFile()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  {file.type == "application/pdf" || "pdf" ? (
                    <Iframe
                      url={file.base64}
                      width="100%"
                      id="myId"
                      className="myClassname"
                      height={window.screen.height}
                    />
                  ) : (
                    <img width="100%" src={file.base64} />
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ""
      )}
    </div>
  );
}
